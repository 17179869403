import { createRouter, createWebHistory } from 'vue-router'
import WebsiteLayout from '@/00-website/layouts/WebsiteLayout.vue'
import Home from '@/00-website/components/Home.vue'
import Contact from '@/00-website/components/Contact.vue'
import Imoveis from '@/00-website/components/Imoveis.vue'
import DetalhesImovel from '@/00-website/components/DetalhesImovel.vue'
import CadastroImovel from '@/00-website/components/CadastroImovel.vue'
import Sobre from '@/00-website/components/Sobre.vue'

const routes = [
    {
        path: '/',
        component: WebsiteLayout,
        children: [
            { path: '', name: 'Home', component: Home },
            { path: 'contato', name: 'Contact', component: Contact },
            { path: 'cadastroimovel', name: 'CadastroImovel', component: CadastroImovel },
            { path: 'sobre', name: 'Sobre', component: Sobre },
            { path: 'imoveis', name: 'Imoveis', component: Imoveis },
            { path: 'imoveis/:comercio(Aluguel|Venda)', name: 'ImoveisComercio', component: Imoveis },
            { path: 'imoveis/:categoria(Casa|Apartamento|Kitnet|Terreno|Comercial|Sitio|Chacara|Sitio&Chacara)', name: 'ImoveisCategoria', component: Imoveis },
            { path: 'imoveis/:comercio(Aluguel|Venda)/:categoria(Casa|Apartamento|Kitnet|Terreno|Comercial|Sitio|Chacara|Sitio&Chacara)', name: 'ImoveisComercioCategoria', component: Imoveis },
            { path: 'imoveis/:id_imovel/:comercio?', name: 'DetalhesImovel', component: DetalhesImovel }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    }
});

export default router;
