<template>
  <div class="visitantes-component">
    <section class="visitantes">
      <div class="container">
        <h2>Visitantes</h2>
        <div class="visitantesHeader">
          <div id="totalVisits" class="total-visits">Total de Visitas: {{ totalVisitas }}</div>
        </div>
        <div class="visit-dates" id="visitDates">
          <div v-for="(dia, index) in visitasPorDia" :key="index" class="visit-date">
            <div class="header-container" @click="toggleVisitas(dia.date)">
              <div class="date-header">
                {{ formatarData(dia.date) }} <span class="visit-count">{{ dia.visits.length }} visitas</span>
                <span class="icon" :class="{ rotate: visitasVisiveis[dia.date] }"></span>
              </div>
              <button class="delete-by-date" @click="excluirVisitantesPorData(dia.date)"></button>
            </div>
            <table v-show="visitasVisiveis[dia.date]" class="visits-table">
              <thead>
                <tr>
                  <th>Hora</th>
                  <th>IP</th>
                  <th>Dispositivo</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="visita in dia.visits" :key="visita._id">
                  <td>{{ formatarHora(visita.createdAt) }}</td>
                  <td>{{ visita.ip }}</td>
                  <td>{{ visita.device }}</td>
                  <td>{{ visita.url }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="actions">
          <button id="deleteAllVisitors" class="delete-all" @click="excluirTodosVisitantes">Excluir todos os
            históricos</button>
        </div>
      </div>
    </section>
    <Popup ref="popup" />
  </div>
</template>

<script>
import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';

export default {
  name: 'Visitantes',
  components: {
    Popup
  },
  data() {
    return {
      visitasPorDia: [],
      totalVisitas: 0,
      visitasVisiveis: {},
    };
  },
  methods: {
    async carregarVisitas() {
      try {
        const response = await api.get(`${process.env.VUE_APP_BACKEND_URL}/visits`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const data = await response.data;

        this.visitasPorDia = data;
        this.totalVisitas = data.reduce((acc, dia) => acc + dia.visits.length, 0);
      } catch (error) {
        console.error('Erro ao carregar visitas:', error);
      }
    },
    toggleVisitas(data) {
      this.visitasVisiveis = {
        ...this.visitasVisiveis,
        [data]: !this.visitasVisiveis[data]
      };
    },
    formatarData(data) {
      const [ano, mes, dia] = data.split('-');
      return `${dia}/${mes}/${ano}`;
    },
    formatarHora(data) {
      return new Date(data).toLocaleTimeString('pt-BR', { timeZone: "America/Sao_Paulo" });
    },
    async excluirTodosVisitantes() {
      this.$refs.popup.showConfirm('Tem certeza que deseja excluir todos os históricos de visitas?', async (confirmed) => {
        if (confirmed) {
          try {
            await api.delete(`${process.env.VUE_APP_BACKEND_URL}/visits`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            });

            await this.carregarVisitas();
            await this.$refs.popup.showAlert('Todos os históricos foram excluídos com sucesso');
          } catch (error) {
            console.error('Erro ao excluir todos os históricos:', error);
            await this.$refs.popup.showAlert('Erro ao excluir todos os históricos: ' + error.response.data);
          }
        }
      });
    },
    async excluirVisitantesPorData(data) {
      this.$refs.popup.showConfirm(`Tem certeza que deseja excluir os visitantes da data ${this.formatarData(data)}?`, async (confirmed) => {
        if (confirmed) {
          try {
            await api.delete(`${process.env.VUE_APP_BACKEND_URL}/visits/${data}`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            });

            await this.carregarVisitas();
            await this.$refs.popup.showAlert(`Visitantes da data ${this.formatarData(data)} foram excluídos com sucesso`);
          } catch (error) {
            console.error(`Erro ao excluir visitantes da data ${this.formatarData(data)}:`, error);
            await this.$refs.popup.showAlert(`Erro ao excluir visitantes da data ${this.formatarData(data)}: ` + error.response.data);
          }
        }
      });
    },
  },
  mounted() {
    this.carregarVisitas();
  }
}
</script>

<style scoped>
@import '@/01-admin/assets/css/visitantes.css';
</style>
