<template>
  <section class="detalhes-section">
    <div class="detalhes-content-container" v-if="imovel && imovel.visivel">
      <h3 class="detalhes-imovel-titulo"
        v-if="imovel.comercio.includes('Venda') && imovel.comercio.includes('Aluguel')">
        {{ imovel.categoria }} para comprar ou alugar -
        {{ imovel.bairro ? imovel.bairro + ',' : '' }} {{ imovel.cidade }}-{{ imovel.estado }}
      </h3>
      <h3 class="detalhes-imovel-titulo" v-else-if="imovel.comercio.includes('Venda')">
        {{ imovel.categoria }} para comprar -
        {{ imovel.bairro ? imovel.bairro + ',' : '' }} {{ imovel.cidade }}-{{ imovel.estado }}
      </h3>
      <h3 class="detalhes-imovel-titulo" v-else-if="imovel.comercio.includes('Aluguel')">
        {{ imovel.categoria }} para alugar -
        {{ imovel.bairro ? imovel.bairro + ',' : '' }} {{ imovel.cidade }}-{{ imovel.estado }}
      </h3>

      <div class="detalhes-image-container">
        <img :src="getImageUrl(0)" alt="" class="detalhes-large-image">

        <div class="detalhes-small-image-container">
          <div class="detalhes-small-image-row">
            <img :src="getImageUrl(1)" alt="" class="detalhes-small-image">

            <div class="detalhes-faded-image-container" @click="openModalImagens">
              <img :src="getImageUrl(2)" alt="" class="detalhes-faded-image">
              <button class="detalhes-more-button">Ver mais</button>
            </div>
          </div>
        </div>
      </div>

      <ModalImagens />

      <div class="detalhes-text-and-info-container">
        <div class="detalhes-text-container">
          <div class="detalhes-titulo-sobre-imovel">
            <h3>Características:</h3>
          </div>

          <div class="detalhes-sobre-imovel">
            <div class="detalhes-icone-wrapper" v-if="imovel.areaTotal">
              <span class="detalhes-icon-area">
                <img src="@/00-website/assets/imagens/detalhes/wide 1.png" alt="Ícone-area">
              </span>
              <hr>
              <div class="detalhes-text-content">
                <b>{{ imovel.areaTotal }} m²</b>
                <p class="title">Área total</p>
              </div>
            </div>
            <div class="detalhes-icone-wrapper" v-if="imovel.areaConstruida">
              <span class="detalhes-icon-terreno">
                <img src="@/00-website/assets/imagens/detalhes/terreno.png" alt="Ícone-terreno">
              </span>
              <hr>
              <div class="detalhes-text-content">
                <b>{{ imovel.areaConstruida }} m²</b>
                <p class="title">Área construída</p>
              </div>
            </div>
            <div class="detalhes-icone-wrapper" v-if="imovel.quartos">
              <span class="detalhes-icon-bed">
                <img src="@/00-website/assets/imagens/detalhes/bed 1.png" alt="Ícone-bed">
              </span>
              <hr>
              <div class="detalhes-text-content">
                <b>{{ imovel.quartos }}</b>
                <p class="title">Quartos</p>
              </div>
            </div>
            <div class="detalhes-icone-wrapper" v-if="imovel.banheiros">
              <span class="detalhes-icon-bathroom">
                <img src="@/00-website/assets/imagens/detalhes/shower 1.png" alt="Ícone-bathroom">
              </span>
              <hr>
              <div class="detalhes-text-content">
                <b>{{ imovel.banheiros }}</b>
                <p class="title">Banheiros</p>
              </div>
            </div>
            <div class="detalhes-icone-wrapper" v-if="imovel.garagem">
              <span class="detalhes-icon-garage">
                <img src="@/00-website/assets/imagens/detalhes/garagem 1.png" alt="Ícone-garage">
              </span>
              <hr>
              <div class="detalhes-text-content">
                <b>{{ imovel.garagem }}</b>
                <p class="title">Vagas</p>
              </div>
            </div>
          </div>

          <div>
            <div class="detalhes-descricaoImovel">
              <h3>Descrição do imóvel:</h3>
              <p class="detalhes-about" v-html="formattedDescricaoImovel"></p>

              <ul class="detalhes-detalhesImovel" v-if="imovel.detalhesImovel && imovel.detalhesImovel.length">
                <li v-for="(detalhe, index) in imovel.detalhesImovel" :key="index">✓ {{ detalhe }}</li>
              </ul>
            </div>
            <div class="detalhes-proximidadesImovel" v-if="imovel.proximidades && imovel.proximidades.length">
              <h3>Proximidades:</h3>
              <ul class="detalhes-proximidades">
                <li v-for="(proximidade, index) in imovel.proximidades" :key="index">✓ {{ proximidade }}</li>
              </ul>
            </div>
            <div class="detalhes-conditionsPayment">
              <h3>Condições de Pagamento:</h3>
              <ul class="detalhes-payments">
                <li>
                  <span class="detalhes-labelPayment">À vista:</span>
                  <span class="detalhes-valueMoney">Sim</span>
                </li>
                <li>
                  <span class="detalhes-labelPayment">Parcelamento:</span>
                  <span class="detalhes-valueInstallment">{{ booleanToYesNo(imovel.parcelamento) }}</span>
                </li>
                <li>
                  <span class="detalhes-labelPayment">Financiamento:</span>
                  <span class="detalhes-valueFinancing">{{ booleanToYesNo(imovel.financiamento) }}</span>
                </li>
                <li>
                  <span class="detalhes-labelPayment">{{ textNegociacao }}</span>
                  <span class="detalhes-valueNegotiation">{{ booleanToYesNo(imovel.negociacao) }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="detalhes-bloco-informacoes">
          <div class="detalhes-prices" v-if="imovel.comercio.length > 1">
            <div class="detalhes-form__tab_container">
              <button type="button" id="comprar-select" class="detalhes-form__tab-button"
                :class="{ active: activeView === 'Venda' }" @click="priceView('Venda')">Comprar</button>
              <button type="button" id="alugar-select" class="detalhes-form__tab-button"
                :class="{ active: activeView === 'Aluguel' }" @click="priceView('Aluguel')">Alugar</button>
            </div>
            <div class="detalhes-informacao" v-if="activeView === 'Venda'">
              <label class="detalhes-label">Venda</label>
              <span class="detalhes-valor">{{ imovel.consultarValorVenda ? 'Consulte' :
                formatarValor(imovel.valorVenda) }}</span>
            </div>
            <div class="detalhes-informacao" v-if="activeView === 'Aluguel'">
              <label class="detalhes-label">Locação</label>
              <span class="detalhes-valor">{{ imovel.consultarValorAluguel ? 'Consulte' :
                formatarValor(imovel.valorAluguel, true) }}</span>
            </div>
            <div class="detalhes-informacao"
              v-if="activeView === 'Venda' && imovel.iptu && !imovel.ocultarIPTU && !imovel.consultarValorVenda">
              <label class="detalhes-label">IPTU</label>
              <span class="detalhes-valor">{{ formatarValor(imovel.iptu) }}</span>
            </div>
            <div class="detalhes-informacao"
              v-if="activeView === 'Venda' && imovel.condominio && !imovel.ocultarCondominio && !imovel.consultarValorVenda">
              <label class="detalhes-label">Condomínio</label>
              <span class="detalhes-valor">{{ formatarValor(imovel.condominio) }}</span>
            </div>
            <div class="detalhes-informacao"
              v-if="activeView === 'Aluguel' && imovel.iptu && !imovel.ocultarIPTU && !imovel.consultarValorAluguel">
              <label class="detalhes-label">IPTU</label>
              <span class="detalhes-valor">{{ formatarValor(imovel.iptu) }}</span>
            </div>
            <div class="detalhes-informacao"
              v-if="activeView === 'Aluguel' && imovel.condominio && !imovel.ocultarCondominio && !imovel.consultarValorAluguel">
              <label class="detalhes-label">Condomínio</label>
              <span class="detalhes-valor">{{ formatarValor(imovel.condominio) }}</span>
            </div>
            <hr>
            <div id="div-locacao" class="detalhes-informacao"
              v-if="activeView === 'Aluguel' && !imovel.consultarValorAluguel && (!imovel.ocultarIPTU || !imovel.ocultarCondominio)">
              <label class="detalhes-label">Pacote de locação:</label>
              <span class="detalhes-valor">{{ calcularPacoteLocacao(imovel) }}</span>
            </div>
          </div>

          <div class="detalhes-prices" v-if="imovel.comercio.length === 1 && imovel.comercio[0] === 'Venda'">
            <div class="detalhes-informacao">
              <label class="detalhes-label">Venda</label>
              <span class="detalhes-valor">{{ imovel.consultarValorVenda ? 'Consulte' :
                formatarValor(imovel.valorVenda) }}</span>
            </div>
            <div class="detalhes-informacao" v-if="imovel.iptu && !imovel.ocultarIPTU && !imovel.consultarValorVenda">
              <label class="detalhes-label">IPTU</label>
              <span class="detalhes-valor">{{ formatarValor(imovel.iptu) }}</span>
            </div>
            <div class="detalhes-informacao"
              v-if="imovel.condominio && !imovel.ocultarCondominio && !imovel.consultarValorVenda">
              <label class="detalhes-label">Condomínio</label>
              <span class="detalhes-valor">{{ formatarValor(imovel.condominio) }}</span>
            </div>
            <hr>
          </div>

          <div class="detalhes-prices" v-if="imovel.comercio.length === 1 && imovel.comercio[0] === 'Aluguel'">
            <div class="detalhes-informacao">
              <label class="detalhes-label">Locação</label>
              <span class="detalhes-valor">{{ imovel.consultarValorAluguel ? 'Consulte' :
                formatarValor(imovel.valorAluguel, true) }}</span>
            </div>
            <div class="detalhes-informacao" v-if="imovel.iptu && !imovel.ocultarIPTU && !imovel.consultarValorAluguel">
              <label class="detalhes-label">IPTU</label>
              <span class="detalhes-valor">{{ formatarValor(imovel.iptu) }}</span>
            </div>
            <div class="detalhes-informacao"
              v-if="imovel.condominio && !imovel.ocultarCondominio && !imovel.consultarValorAluguel">
              <label class="detalhes-label">Condomínio</label>
              <span class="detalhes-valor">{{ formatarValor(imovel.condominio) }}</span>
            </div>
            <hr>
            <div id="div-locacao" class="detalhes-informacao"
              v-if="!imovel.consultarValorAluguel && (!imovel.ocultarIPTU || !imovel.ocultarCondominio)">
              <label class="detalhes-label">Pacote de locação:</label>
              <span class="detalhes-valor">{{ calcularPacoteLocacao(imovel) }}</span>
            </div>
          </div>

          <a :href="whatsappLink" class="detalhes-contact-btn" target="_blank">
            <ion-icon name="logo-whatsapp"></ion-icon> Entrar em contato
          </a>
        </div>
      </div>
    </div>

    <div class="detalhes-content-container" v-else>
      <h3 class="detalhes-imovel-titulo"> Imóvel não encontrado</h3>
    </div>

    <div class="detalhes-maps" v-if="imovel && imovel.visivel">
      <div class="detalhes-location-info">
        <div class="detalhes-location-name">Localização</div>
        <div class="detalhes-location-address">{{ imovel.rua }}, {{ imovel.bairro }} - {{ imovel.cidade }}-{{
          imovel.estado }}</div>
      </div>
    </div>
    <iframe class="detalhes-mapa" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="map-iframe"
      :src="mapSrc" v-if="imovel && imovel.visivel"></iframe>
  </section>
</template>

<script>
import api from '@/axios.js';
import script from '@/mixins/script.js';
import ModalImagens from './ModalImagens.vue';
import defaultImage from '@/mixins/imagens/defaultImage.jpeg';

export default {
  name: 'DetalhesImovel',
  props: ['id_imovel'],
  mixins: [script],
  components: {
    ModalImagens
  },
  data() {
    return {
      configuracoes: {
        whatsapp: ''
      },
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      frontendUrl: process.env.VUE_APP_STORAGE_IMAGENS_URL,
      imovel: null,
      imovelId: this.$route.params.id_imovel,
      activeView: this.$route.params.comercio || 'Venda',
      mapSrc: '',
      textNegociacao: 'Negociação (aceita carro/imóvel):'
    };
  },
  async mounted() {
    await this.carregarDetalhesImovel();
    await this.updateMapSrc();
    this.configuracoes = await this.carregarConfiguracoes();
    this.updateTextNegociacao();
    window.addEventListener('resize', this.updateTextNegociacao);
    await this.registerVisit();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateTextNegociacao);
  },
  methods: {
    async carregarDetalhesImovel() {
      try {
        const response = await api.get(`${this.backendUrl}/imoveis/${this.imovelId}`);
        const imovel = await response.data;

        if (imovel.detalhesImovel && imovel.detalhesImovel.length) {
          imovel.detalhesImovel = imovel.detalhesImovel.filter(detalhe => detalhe.trim() !== '');
        }

        if (imovel.proximidades && imovel.proximidades.length) {
          imovel.proximidades = imovel.proximidades.filter(proximidade => proximidade.trim() !== '');
        }

        this.imovel = imovel;
      } catch (error) {
        console.error('Erro ao carregar detalhes do imóvel:', error);
      }
    },

    getImageUrl(index) {
      const imagens = this.imovel && this.imovel.imagens ? this.imovel.imagens : [];
      if (imagens[index] && imagens[index].url) {
        return imagens[index].url;
      } else {
        return defaultImage;
      }
    },

    openModalImagens() {
      document.getElementById('modal-images').classList.add('show');
      document.getElementById('app-website').classList.add('no-scroll');
    },

    booleanToYesNo(value) {
      return value ? 'Sim' : 'Não';
    },
    priceView(view) {
      this.activeView = view;
    },
    calcularPacoteLocacao(imovel) {
      let soma = imovel.valorAluguel || 0;
      if (!imovel.ocultarIPTU) {
        soma += imovel.iptu || 0;
      }
      if (!imovel.ocultarCondominio) {
        soma += imovel.condominio || 0;
      }
      return this.formatarValor(soma, true);
    },
    async updateMapSrc() {
      if (this.imovel && this.imovel.idGeoLocation && this.imovel.idGeoLocation !== '0.000000, 0.000000' && this.imovel.idGeoLocation !== '0, 0') {
        const [latitude, longitude] = this.imovel.idGeoLocation.split(',').map(coord => coord.trim());
        this.mapSrc = `https://maps.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`;
      } else {
        try {
          const apiKey = process.env.VUE_APP_GOOGLE_API_KEY;
          const address = `${this.imovel.rua}, ${this.imovel.numero || ''}, ${this.imovel.bairro}, ${this.imovel.cidade}, ${this.imovel.estado}, ${this.imovel.cep || ''}`;
          const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
          const data = await response.json();

          if (data.results && data.results.length > 0) {
            const location = data.results[0].geometry.location;
            this.mapSrc = `https://maps.google.com/maps?q=${location.lat},${location.lng}&z=15&output=embed`;
          } else {
            console.error('Endereço não encontrado');
            this.mapSrc = '';
          }
        } catch (error) {
          console.error('Erro ao obter coordenadas:', error);
          this.mapSrc = '';
        }
      }
    },
    updateTextNegociacao() {
      if (window.innerWidth <= 1140 && window.innerWidth >= 900) {
        this.textNegociacao = 'Negociação:';
      } else {
        this.textNegociacao = 'Negociação (aceita carro/imóvel):';
      }
    }
  },
  computed: {
    formattedDescricaoImovel() {
      if (this.imovel && this.imovel.descricaoImovel) {
        return this.imovel.descricaoImovel.replace(/\n/g, '<br>');
      }
      return '';
    },
    whatsappLink() {
      return `https://wa.me/${this.configuracoes.whatsapp}?text=Olá!%20Estou%20interessado%20no%20imóvel%20${window.location.href}`;
    }
  }
}
</script>

<style scoped>
@import '@/00-website/assets/css/detalhes.css';
</style>
