<template>
  <section class="imoveis">
    <div class="imoveis-content-container">
      <!-- Cabeçalho e Pesquisa -->
      <div class="imoveis-header-search">
        <div class="imoveis-search-container">
          <input type="text" class="imoveis-search-bar" placeholder="Pesquisar" @input="searchImoveis"
            @keyup.enter="applySearch" @click="searchImoveis">
          <div class="imoveis-clear-button-container" @click="closeSearchResults">
            <button class="imoveis-clear-button">&times;</button>
          </div>
          <button class="imoveis-search-button" @click="applySearch">
            <img src="@/00-website/assets/imagens/lupa.png" alt="Buscar">
          </button>
        </div>
        <div id="searchResults" class="imoveis-search-results">
          <div v-for="(group, groupTitle) in groupedResults" :key="groupTitle">
            <strong>{{ groupTitle }}</strong>
            <div v-for="item in group" :key="item" class="imoveis-selectable-option" @click="selectSuggestion(item)">
              {{ item }}
            </div>
          </div>
        </div>
        <button class="imoveis-filter-button" @click="toggleFilters">Filtros</button>
      </div>

      <!-- Filtros e Ordenação -->
      <div class="imoveis-header-filtros">
        <h1 v-if="imoveisRenderizados.length > 0 && !routeComercio" class="imoveis-titulo">Imóveis disponíveis:</h1>
        <h1 v-else-if="imoveisRenderizados.length > 0 && routeComercio === 'Venda'" class="imoveis-titulo">Imóveis
          disponíveis para comprar:</h1>
        <h1 v-else-if="imoveisRenderizados.length > 0 && routeComercio === 'Aluguel'" class="imoveis-titulo">Imóveis
          disponíveis para alugar:</h1>
        <h1 v-else class="imoveis-titulo">Nenhum imóvel encontrado</h1>
        <div id="imoveis-filters" class="imoveis-orderDropdown">
          <button id="dropbtn" class="imoveis-dropbtn" @click="toggleDropdown('imoveis-dropdown-content')">{{ order
            }}</button>
          <div id="imoveis-dropdown-content" class="imoveis-orderDropdown-content">
            <button id="imoveis-menor-valor" @click="changeOrder('menor-valor')">menor valor</button>
            <button id="imoveis-maior-valor" @click="changeOrder('maior-valor')">maior valor</button>
          </div>
        </div>
      </div>

      <!-- Cards de Imóveis -->
      <div :class="['imoveis-cards', imoveisRenderizados.length === 2 ? 'justify-content-left' : '']"
        id="imoveis-container">
        <article v-for="imovel in imoveisRenderizados" :key="imovel.id_imovel" class="imoveis-card"
          :ref="imovel.id_imovel" @click="navigateToDetails(imovel.id_imovel, routeComercio, query)">
          <div class="imoveis-carrossel" @touchstart="handleSwipeStart($event, imovel.id_imovel)"
            @touchmove="handleSwipeMove($event)" @touchend="handleSwipeEnd(imovel.id_imovel)">
            <div class="images-imovel">
              <img v-for="(image, index) in imovel.imagens" :src="image.url" :alt="`${imovel.id_imovel} - ${index}`"
                :class="{ 'active': index === imovel.currentImageIndex }" :key="index" />
            </div>
            <div class="imoveis-prev-area" @click.stop="showImage(imovel.id_imovel, -1)"></div>
            <button class="imoveis-prev" @click.stop="showImage(imovel.id_imovel, -1)">❮</button>
            <div class="imoveis-next-area" @click.stop="showImage(imovel.id_imovel, 1)"></div>
            <button class="imoveis-next" @click.stop="showImage(imovel.id_imovel, 1)">❯</button>
            <div class="imoveis-carrossel-indicators">
              <span v-for="(image, index) in imovel.imagens" :class="{ 'active': index === imovel.currentImageIndex }"
                :key="index" class="indicator"></span>
            </div>
          </div>
          <div class="imoveis-info">
            <div>
              <span id="idImoveis">{{ imovel.id_imovel }}</span>
            </div>

            <p v-if="imovel.categoria">{{ imovel.categoria }}</p>
            <p v-if="imovel.bairro || imovel.cidade || imovel.estado"><b>{{ imovel.bairro }}
                <br>
                {{ imovel.cidade }} - {{ imovel.estado }}</b></p>

            <section class="dados-container">
              <div class="dados-group" v-if="imovel.areaTotal || imovel.areaConstruida">
                <img src="@/00-website/assets/imagens/detalhes/wide 1.png">
                <p>{{ formatarArea(imovel.areaTotal || imovel.areaConstruida) }}</p>
              </div>
              <div class="dados-group" v-if="imovel.quartos">
                <img src="@/00-website/assets/imagens/detalhes/bed 1.png">
                <p>{{ imovel.quartos }}</p>
              </div>
              <div class="dados-group" v-if="imovel.banheiros">
                <img src="@/00-website/assets/imagens/detalhes/shower 1.png">
                <p>{{ imovel.banheiros }}</p>
              </div>
              <div class="dados-group" v-if="imovel.garagem">
                <img src="@/00-website/assets/imagens/detalhes/garagem 1.png">
                <p>{{ imovel.garagem }}</p>
              </div>
            </section>

            <div v-if="imovel.comercio.includes('Venda') && imovel.comercio.includes('Aluguel')"
              class="valores valores-duplo">
              <div class="valor venda">
                <p>Venda</p>
                <p>{{ imovel.consultarValorVenda ? 'Consulte' : formatarValor(imovel.valorVenda) }}</p>
              </div>
              <div class="valor aluguel">
                <p>Locação</p>
                <p>{{ imovel.consultarValorAluguel ? 'Consulte' : formatarValor(imovel.valorAluguel, true) }}</p>
              </div>
            </div>

            <div v-else-if="imovel.comercio.includes('Venda')" class="valor venda">
              <p>Venda</p>
              <p>{{ imovel.consultarValorVenda ? 'Consulte' : formatarValor(imovel.valorVenda) }}</p>
            </div>

            <div v-else-if="imovel.comercio.includes('Aluguel')" class="valor aluguel">
              <p>Locação</p>
              <p>{{ imovel.consultarValorAluguel ? 'Consulte' : formatarValor(imovel.valorAluguel, true) }}</p>
            </div>
          </div>
        </article>
      </div>

      <!-- Paginação -->
      <div class="imoveis-pagination">
        <div class="imoveis-custom-select">
          <button id="imoveis-btn-page-number" class="imoveis-page-number"
            @click="toggleDropdown('imoveis-pagination-content')">{{ itemsPerPage }}</button>
          <div id="imoveis-pagination-content" class="imoveis-paginationDropdown-content">
            <button @click="changeItemsPerPage(20)">20</button>
            <button @click="changeItemsPerPage(50)">50</button>
            <button @click="changeItemsPerPage(100)">100</button>
          </div>
        </div>
        <div class="imoveis-btn-pagination">
          <button class="imoveis-btn-navigation" @click="previousPage" :disabled="currentPage === 1">&#60;</button>
          <span id="imoveis-currentPageIndicator">{{ currentPage }}</span>
          <button class="imoveis-btn-navigation" @click="nextPage"
            :disabled="imoveisRenderizados.length < itemsPerPage">&#62;</button>
        </div>
      </div>
    </div>

    <!-- Painel de Filtros -->
    <div id="filterPanel" class="imoveis-filter-panel">
      <div class="imoveis-header-filter">
        <h2>Filtrar Imóveis</h2>
        <button class="imoveis-close-button" @click="toggleFilters">
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>
      <div class="imoveis-filters">
        <div class="imoveis-filter-group" v-if="routeComercio === 'Venda' || !routeComercio">
          <h3>Valor para comprar</h3>
          <div class="imoveis-value-range">
            <div>
              <label for="min-venda-value">Mínimo</label>
              <input type="text" id="min-venda-value" placeholder="R$ 0,00" v-model="minVenda"
                @input="minVenda = formatarValor($event.target.value)">
            </div>
            <div>
              <label for="max-venda-value">Máximo</label>
              <input type="text" id="max-venda-value" placeholder="R$ 100.000,00" v-model="maxVenda"
                @input="maxVenda = formatarValor($event.target.value)">
            </div>
          </div>
        </div>
        <div class="imoveis-filter-group" v-if="routeComercio === 'Aluguel' || !routeComercio">
          <h3>Valor para alugar</h3>
          <div class="imoveis-value-range">
            <div>
              <label for="min-aluguel-value">Mínimo</label>
              <input type="text" id="min-aluguel-value" placeholder="R$ 0,00/mês" v-model="minAluguel"
                @input="minAluguel = formatarValor($event.target.value, true, true)"
                @focus="minAluguel = formatarValor($event.target.value, true, true)"
                @blur="minAluguel = formatarValor($event.target.value, true)">
            </div>
            <div>
              <label for="max-aluguel-value">Máximo</label>
              <input type="text" id="max-aluguel-value" placeholder="R$ 5.000,00/mês" v-model="maxAluguel"
                @input="maxAluguel = formatarValor($event.target.value, true, true)"
                @focus="maxAluguel = formatarValor($event.target.value, true, true)"
                @blur="maxAluguel = formatarValor($event.target.value, true)">
            </div>
          </div>
        </div>
        <div class="imoveis-filter-group" v-if="!routeCategoria">
          <h3>Tipos de imóveis</h3>
          <div class="imoveis-filter-options" id="data-tipos">
            <label><input type="checkbox" class="imoveis-checkbox" name="tipo" value="Apartamento"> Apartamentos</label>
            <label><input type="checkbox" class="imoveis-checkbox" name="tipo" value="Casa"> Casas</label>
            <label><input type="checkbox" class="imoveis-checkbox" name="tipo" value="Terreno"> Terrenos</label>
            <label><input type="checkbox" class="imoveis-checkbox" name="tipo" value="Kitnet"> Kitnets</label>
            <label><input type="checkbox" class="imoveis-checkbox" name="tipo" value="Sitio"> Sítios</label>
            <label><input type="checkbox" class="imoveis-checkbox" name="tipo" value="Chacara"> Chácaras</label>
            <label><input type="checkbox" class="imoveis-checkbox" name="tipo" value="Comercial"> Comércios</label>
          </div>
        </div>
        <div class="imoveis-filter-group">
          <h3>Quartos</h3>
          <div class="imoveis-filter-options" id="data-quartos">
            <button type="button" class="imoveis-filter-option" data-quartos="1"
              @click="selectOption($event.target)">1</button>
            <button type="button" class="imoveis-filter-option" data-quartos="2"
              @click="selectOption($event.target)">2</button>
            <button type="button" class="imoveis-filter-option" data-quartos="3"
              @click="selectOption($event.target)">3</button>
            <button type="button" class="imoveis-filter-option" data-quartos="4+"
              @click="selectOption($event.target)">4+</button>
          </div>
        </div>
        <div class="imoveis-filter-group">
          <h3>Vagas</h3>
          <div class="imoveis-filter-options" id="data-vagas">
            <button type="button" class="imoveis-filter-option" data-vagas="1"
              @click="selectOption($event.target)">1</button>
            <button type="button" class="imoveis-filter-option" data-vagas="2+"
              @click="selectOption($event.target)">2+</button>
            <button type="button" class="imoveis-filter-option" data-vagas="Indiferente"
              @click="selectOption($event.target)">Indiferente</button>
          </div>
        </div>
        <div class="imoveis-filter-group">
          <h3>Banheiros</h3>
          <div class="imoveis-filter-options" id="data-banheiros">
            <button type="button" class="imoveis-filter-option" data-banheiros="1"
              @click="selectOption($event.target)">1</button>
            <button type="button" class="imoveis-filter-option" data-banheiros="2"
              @click="selectOption($event.target)">2</button>
            <button type="button" class="imoveis-filter-option" data-banheiros="3"
              @click="selectOption($event.target)">3</button>
            <button type="button" class="imoveis-filter-option" data-banheiros="4+"
              @click="selectOption($event.target)">4+</button>
          </div>
        </div>

        <div class="imoveis-filter-group">
          <h3>Área</h3>
          <div class="imoveis-area-range">
            <div>
              <label for="min-area">Mínima</label>
              <input type="text" id="min-area" placeholder="0 m²" v-model="minArea"
                @input="minArea = formatarArea($event.target.value, true)"
                @focus="minArea = formatarArea($event.target.value, true)"
                @blur="minArea = formatarArea($event.target.value)">
            </div>
            <div>
              <label for="max-area">Máxima</label>
              <input type="text" id="max-area" placeholder="1000 m²" v-model="maxArea"
                @input="maxArea = formatarArea($event.target.value, true)"
                @focus="maxArea = formatarArea($event.target.value, true)"
                @blur="maxArea = formatarArea($event.target.value)">
            </div>
          </div>
        </div>
        <div class="imoveis-filter-actions">
          <button type="reset" class="imoveis-filter-reset" @click="resetOptions">Limpar</button>
          <button type="button" class="imoveis-filter-submit" @click="applyFilters">Ver imóveis</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/axios.js';
import script from '@/mixins/script.js';
import defaultImage from '@/mixins/imagens/defaultImage.jpeg';

export default {
  name: 'Imoveis',
  mixins: [script],
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      frontendUrl: process.env.VUE_APP_STORAGE_IMAGENS_URL,
      imoveisRenderizados: [],
      itemsPerPage: 20,
      currentPage: 1,
      filters: {},
      search: this.$route.query.query || '',
      query: '',
      results: [],
      groupedResults: {},
      routeComercio: this.$route.params.comercio || '',
      routeCategoria: this.$route.params.categoria || '',
      order: 'Ordenar',
      minVenda: '',
      maxVenda: '',
      minAluguel: '',
      maxAluguel: '',
      minArea: '',
      maxArea: '',
      swipeStartX: 0,
      swipeEndX: 0,
      swipeStartY: 0,
      swipeEndY: 0
    };
  },
  async mounted() {
    await this.carregarImoveis();
    this.closeDropDowns();
    this.aplicarFiltrosSalvos();
    await this.registerVisit();
  },

  watch: {
    '$route.params': {
      async handler() {
        this.filters = {};
        this.query = localStorage.getItem('filtrosImoveis') || '';
        this.routeComercio = this.$route.params.comercio || '';
        this.routeCategoria = this.$route.params.categoria || '';
        this.order = 'Ordenar';
        if (!this.query) this.resetOptions();
      },
      immediate: true
    },
  },
  methods: {
    closeDropDowns() {
      document.addEventListener('click', (event) => {
        const filterPanel = document.getElementById('filterPanel');
        const isClickButtonFiltros = event.target.classList.contains('imoveis-filter-button');
        const isClickInsideFilterPanel = filterPanel && filterPanel.contains(event.target);
        const panelActive = filterPanel && filterPanel.classList.contains('active');

        if (!isClickButtonFiltros && !isClickInsideFilterPanel && panelActive) {
          this.toggleFilters();
        }

        const suggestions = document.getElementById('searchResults');
        const isClickInputSearch = event.target.classList.contains('imoveis-search-bar');
        const isClickSuggestions = suggestions && suggestions.contains(event.target);

        if (!isClickInputSearch && suggestions && !isClickSuggestions) {
          suggestions.classList.remove('show');
        }

        const dropOrdenar = document.getElementById('imoveis-dropdown-content');
        const isClickButtonOrdenar = event.target.id === 'dropbtn';
        const isClickDropOrdenar = dropOrdenar && dropOrdenar.contains(event.target);

        if (!isClickButtonOrdenar && dropOrdenar && !isClickDropOrdenar) {
          dropOrdenar.classList.remove('show');
        }

        const dropPagination = document.getElementById('imoveis-pagination-content');
        const isClickButtonPagination = event.target.id === 'imoveis-btn-page-number';
        const isClickDropPagination = dropPagination && dropPagination.contains(event.target);

        if (!isClickButtonPagination && dropPagination && !isClickDropPagination) {
          dropPagination.classList.remove('show');
        }
      });
    },

    async carregarImoveis() {
      try {
        this.buildQuery();
        let params

        if (this.routeComercio && !this.routeCategoria) {
          params = `imoveis/comercio/${this.routeComercio}`;
        }
        else if (this.routeCategoria && !this.routeComercio) {
          params = `imoveis/categoria/${this.routeCategoria}`;
        }
        else if (this.routeComercio && this.routeCategoria) {
          params = `imoveis/comercio/${this.routeComercio}/categoria/${this.routeCategoria}`;
        } else {
          params = 'imoveis'
        }

        const response = await api.get(`${this.backendUrl}/${params}?${this.query}`, {
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.data;

        this.imoveisRenderizados = data.imoveis.map(imovel => ({
          ...imovel,
          currentImageIndex: 0,
          imagens: imovel.imagens.length > 0 ? imovel.imagens.slice(0, 10) : [{ filename: 'defaultImage.jpeg', url: defaultImage }]
        }));
      } catch (error) {
        console.error('Erro ao carregar os imóveis:', error);
      }
      localStorage.removeItem('filtrosImoveis');
    },

    async changeItemsPerPage(items) {
      this.itemsPerPage = items;
      this.currentPage = 1;
      await this.carregarImoveis();
      this.toggleDropdown('imoveis-pagination-content');
    },

    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        await this.carregarImoveis();
      }
    },

    async nextPage() {
      if (this.imoveisRenderizados.length >= this.itemsPerPage) {
        this.currentPage += 1;
        await this.carregarImoveis();
      }
    },

    toggleFilters() {
      const filterPanel = document.getElementById('filterPanel');
      filterPanel.classList.toggle('active');
      if (filterPanel.classList.contains('active') && window.innerWidth <= 800) {
        document.getElementById('app-website').classList.add('no-scroll');
      } else {
        document.getElementById('app-website').classList.remove('no-scroll');
      }
    },

    selectOption(element) {
      const parentId = element.closest('.imoveis-filter-options').id;
      const parent = document.getElementById(parentId);

      parent.querySelectorAll('.imoveis-filter-option').forEach(option => {
        option.classList.remove('selected');
      });

      element.classList.add('selected');
    },

    async resetOptions() {
      document.querySelectorAll('.imoveis-filter-panel input[type="text"]').forEach(input => {
        input.value = '';
      });

      document.querySelectorAll('.imoveis-filter-panel input[type="checkbox"]').forEach(checkbox => {
        checkbox.checked = false;
      });

      document.querySelectorAll('.imoveis-filter-option.selected').forEach(option => {
        option.classList.remove('selected');
      });

      this.filters = this.filters.orderVenda || this.filters.orderAluguel || {};
      this.query = '';
      this.minVenda = '';
      this.maxVenda = '';
      this.minAluguel = '';
      this.maxAluguel = '';
      this.minArea = '';
      this.maxArea = '';

      await this.carregarImoveis();
    },

    async applyFilters() {
      const minVendaElement = document.getElementById('min-venda-value');
      const maxVendaElement = document.getElementById('max-venda-value');
      const minAluguelElement = document.getElementById('min-aluguel-value');
      const maxAluguelElement = document.getElementById('max-aluguel-value');

      const minVendaValue = minVendaElement ? minVendaElement.value.replace(/[^\d,]|\.(?=.*\.)/g, '') : '';
      const maxVendaValue = maxVendaElement ? maxVendaElement.value.replace(/[^\d,]|\.(?=.*\.)/g, '') : '';
      const minAluguelValue = minAluguelElement ? minAluguelElement.value.replace(/[^\d,]|\.(?=.*\.)/g, '').replace('/mês', '') : '';
      const maxAluguelValue = maxAluguelElement ? maxAluguelElement.value.replace(/[^\d,]|\.(?=.*\.)/g, '').replace('/mês', '') : '';

      const minArea = document.getElementById('min-area').value.replace(' m²', '').replace('.', '');
      const maxArea = document.getElementById('max-area').value.replace(' m²', '').replace('.', '');
      const categorias = Array.from(document.querySelectorAll('.imoveis-filter-panel input[name="tipo"]:checked')).map(checkbox => checkbox.value);
      const quartos = document.querySelector('.imoveis-filter-option[data-quartos].selected')?.dataset.quartos || '0';
      const vagas = document.querySelector('.imoveis-filter-option[data-vagas].selected')?.dataset.vagas || 'Indiferente';
      const banheiros = document.querySelector('.imoveis-filter-option[data-banheiros].selected')?.dataset.banheiros || '0';

      this.filters = {};

      if (minVendaValue) {
        this.filters.minVenda = minVendaValue;
      }
      if (maxVendaValue) {
        this.filters.maxVenda = maxVendaValue;
      }
      if (minAluguelValue) {
        this.filters.minAluguel = minAluguelValue;
      }
      if (maxAluguelValue) {
        this.filters.maxAluguel = maxAluguelValue;
      }
      if (minArea) {
        this.filters.minArea = minArea;
      }
      if (maxArea) {
        this.filters.maxArea = maxArea;
      }
      if (categorias.length > 0) {
        this.filters.categorias = categorias.join(',');
      }
      if (quartos !== '0') {
        this.filters.quartos = quartos;
      }
      if (vagas !== 'Indiferente') {
        this.filters.vagas = vagas;
      }
      if (banheiros !== '0') {
        this.filters.banheiros = banheiros;
      }

      this.currentPage = 1
      this.toggleFilters();
      await this.carregarImoveis();
    },

    aplicarFiltrosSalvos() {
      const filtros = this.parseQueryString(this.query);

      if (filtros.minVenda) {
        const minVendaElement = document.getElementById('min-venda-value');
        minVendaElement.value = this.formatarValor(filtros.minVenda);
      }

      if (filtros.maxVenda) {
        const maxVendaElement = document.getElementById('max-venda-value');
        maxVendaElement.value = this.formatarValor(filtros.maxVenda);
      }

      if (filtros.minAluguel) {
        const minAluguelElement = document.getElementById('min-aluguel-value');
        minAluguelElement.value = this.formatarValor(filtros.minAluguel, true);
      }

      if (filtros.maxAluguel) {
        const maxAluguelElement = document.getElementById('max-aluguel-value');
        maxAluguelElement.value = this.formatarValor(filtros.maxAluguel, true);
      }

      if (filtros.minArea) {
        const minAreaElement = document.getElementById('min-area');
        minAreaElement.value = this.formatarArea(filtros.minArea);
      }

      if (filtros.maxArea) {
        const maxAreaElement = document.getElementById('max-area');
        maxAreaElement.value = this.formatarArea(filtros.maxArea);
      }

      if (filtros.categorias) {
        const categorias = filtros.categorias.split(',');
        categorias.forEach(categoria => {
          const categoriaCheckbox = document.querySelector(`.imoveis-filter-panel input[name="tipo"][value="${categoria}"]`);
          categoriaCheckbox.checked = true;
        });
      }

      if (filtros.quartos) {
        const quartosOption = document.querySelector(`.imoveis-filter-option[data-quartos="${filtros.quartos}"]`);
        quartosOption.classList.add('selected');
      }

      if (filtros.vagas) {
        const vagasOption = document.querySelector(`.imoveis-filter-option[data-vagas="${filtros.vagas}"]`);
        vagasOption.classList.add('selected');
      }

      if (filtros.banheiros) {
        const banheirosOption = document.querySelector(`.imoveis-filter-option[data-banheiros="${filtros.banheiros}"]`);
        banheirosOption.classList.add('selected');
      }
    },

    parseQueryString(queryString) {
      return queryString
        .split('&')
        .reduce((acc, part) => {
          const [key, value] = part.split('=');
          acc[decodeURIComponent(key)] = decodeURIComponent(value);
          return acc;
        }, {});
    },

    async changeOrder(order) {
      if (this.routeComercio === 'Aluguel') {
        this.filters.orderAluguel = order;
      } else {
        this.filters.orderVenda = order;
      }

      this.order = order;
      this.currentPage = 1

      await this.carregarImoveis();
      this.toggleDropdown('imoveis-dropdown-content');
    },

    buildQuery() {
      if (!localStorage.getItem('filtrosImoveis')) {
        const params = new URLSearchParams();
        for (const [key, value] of Object.entries(this.filters)) {
          params.append(key, value);
        }

        params.append('page', this.currentPage);
        params.append('limit', this.itemsPerPage);

        if (this.$route.query.cidade) {
          params.append('cidade', this.$route.query.cidade);
        }

        if (this.$route.query.bairro) {
          params.append('bairro', this.$route.query.bairro);
        }

        if (this.search) {
          params.append('query', this.search);
        }

        this.query = params.toString();
      }
    },

    async searchImoveis(event) {
      const input = event.target
      try {
        let params

        if (this.routeComercio && !this.routeCategoria) {
          params = `imoveis/comercio/${this.routeComercio}`;
        }
        else if (this.routeCategoria && !this.routeComercio) {
          params = `imoveis/categoria/${this.routeCategoria}`;
        }
        else if (this.routeComercio && this.routeCategoria) {
          params = `imoveis/comercio/${this.routeComercio}/categoria/${this.routeCategoria}`;
        } else {
          params = 'imoveis'
        }

        const response = await fetch(`${this.backendUrl}/${params}`, {
          headers: { 'Content-Type': 'application/json' }
        });

        const data = await response.json();
        await this.displaySearchResults(data.imoveis, input.value);

      } catch (error) {
        console.error('Erro ao buscar imóveis:', error);
      }
    },

    async displaySearchResults(results, query) {
      document.getElementById('searchResults').classList.add('show');
      document.querySelector('.imoveis-clear-button').classList.add('visible');
      this.results = results;
      this.groupedResults = {
        'Tipos de Imóvel': new Set(),
        'Ruas': new Set(),
        'Bairros': new Set(),
        'Cidades': new Set(),
        'Estados': new Set(),
        'IDs dos imóveis': new Set()
      };

      const normalizedQuery = this.removeAccents(query.toLowerCase());

      results.forEach(imovel => {
        if (this.removeAccents(imovel.categoria.toLowerCase()).includes(normalizedQuery)) this.groupedResults['Tipos de Imóvel'].add(imovel.categoria);
        if (this.removeAccents(imovel.rua.toLowerCase()).includes(normalizedQuery)) this.groupedResults['Ruas'].add(imovel.rua);
        if (this.removeAccents(imovel.bairro.toLowerCase()).includes(normalizedQuery)) this.groupedResults['Bairros'].add(imovel.bairro);
        if (this.removeAccents(imovel.cidade.toLowerCase()).includes(normalizedQuery)) this.groupedResults['Cidades'].add(imovel.cidade);
        if (this.removeAccents(imovel.estado.toLowerCase()).includes(normalizedQuery)) this.groupedResults['Estados'].add(imovel.estado);
        if (this.removeAccents(imovel.id_imovel.toLowerCase()).includes(normalizedQuery)) this.groupedResults['IDs dos imóveis'].add(imovel.id_imovel);
      });

      Object.keys(this.groupedResults).forEach(key => {
        if (this.groupedResults[key].size === 0) {
          delete this.groupedResults[key];
        }
      });
    },

    async applySearch(insertSearch) {
      if (typeof insertSearch !== 'string') {
        insertSearch = document.querySelector('.imoveis-search-bar').value;
      }
      this.filters = {}
      this.search = insertSearch
      await this.carregarImoveis()
      document.getElementById('searchResults').classList.remove('show');
    },

    async selectSuggestion(suggestion) {
      document.querySelector('.imoveis-search-bar').value = suggestion;
      await this.applySearch(suggestion);
    },

    async closeSearchResults() {
      this.search = ''
      await this.carregarImoveis()
      document.querySelector('.imoveis-search-bar').value = '';
      document.getElementById('searchResults').classList.remove('show');
      document.querySelector('.imoveis-clear-button').classList.remove('visible');
    },
    showImage(imovelId, direction) {
      const imovel = this.imoveisRenderizados.find(imovel => imovel.id_imovel === imovelId);
      if (imovel) {
        const totalImages = imovel.imagens.length;
        if (totalImages > 1) {
          imovel.currentImageIndex = (imovel.currentImageIndex + direction + totalImages) % totalImages;
        }
      } else {
        console.error(`Imóvel com ID ${imovelId} não encontrado.`);
      }
    },
    handleSwipeStart(event) {
      this.swipeStartX = event.touches[0].clientX;
      this.swipeStartY = event.touches[0].clientY;
    },

    handleSwipeMove(event) {
      this.swipeEndX = event.touches[0].clientX;
      this.swipeEndY = event.touches[0].clientY;
    },

    handleSwipeEnd(imovelId) {
      const swipeDistanceX = this.swipeStartX - this.swipeEndX;
      const swipeDistanceY = this.swipeStartY - this.swipeEndY;
      const swipeThreshold = 50;

      if (Math.abs(swipeDistanceX) > Math.abs(swipeDistanceY)) {
        if (swipeDistanceX > swipeThreshold) {
          this.showImage(imovelId, 1);
        } else if (swipeDistanceX < -swipeThreshold) {
          this.showImage(imovelId, -1);
        }
      }
    },
  }
}
</script>

<style scoped>
@import '@/00-website/assets/css/imoveis.css';
</style>
