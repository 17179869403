<template>
    <div class="retirar-chaves-component">
        <section class="retirar-chaves">
            <div class="container">
                <div class="header-property">
                    <h2>{{ isEdit ? 'Editar retirada de chave' : 'Retirar chave' }}</h2>
                    <div class="close-property" @click="closeForm">X</div>
                </div>
                <hr>
                <form id="form-property">
                    <div class="section">
                        <div class="grid-container" id="grid-select">
                            <!-- Campo para Seleção de Responsável -->
                            <div class="group-input">
                                <h4 for="proprietario">Responsável*</h4>
                                <div class="select" id="select-proprietario" @click="toggleDisplayOpcoesProprietario"
                                    @keypress.enter="toggleDisplayOpcoesProprietario">
                                    <span v-if="form.proprietario" id="selected-proprietario">
                                        {{ selectedProprietario.nome }} {{ selectedProprietario.sobrenome }}
                                    </span>
                                    <span v-else id="selected-proprietario">Selecione</span>
                                    <span v-if="form.proprietario" class="remove-proprietario-btn"
                                        @click.stop="removeProprietario">X</span>
                                    <span v-else class="icon-expanded" id="icon-expanded-proprietario"></span>
                                    <ul id="drop-proprietario" class="dropdown"
                                        :class="{ 'show': displayOpcoesProprietarios }">
                                        <div class="filter-proprietario">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                viewBox="0 0 512 512">
                                                <path fill="currentColor"
                                                    d="M472 168H40a24 24 0 0 1 0-48h432a24 24 0 0 1 0 48m-80 112H120a24 24 0 0 1 0-48h272a24 24 0 0 1 0 48m-96 112h-80a24 24 0 0 1 0-48h80a24 24 0 0 1 0 48" />
                                            </svg>
                                            <input type="text" ref="filterProprietarioInput" @click.stop
                                                @input="carregarClientes($event.target.value)"
                                                placeholder="Digite nome/documento">
                                        </div>
                                        <div class="opcoes-container">
                                            <li v-for="cliente in opcoesProprietarios" :key="cliente._id"
                                                class="selectable-option">
                                                <label class="radio-container">
                                                    <span>{{ cliente.nome }} {{ cliente.sobrenome }}</span>
                                                    <input type="radio" class="radio" :value="cliente._id"
                                                        v-model="form.proprietario" />
                                                </label>
                                            </li>
                                        </div>
                                        <div class="add-new-cliente-container">
                                            <button type="button" class="add-cliente-button"
                                                @click.prevent="openModalAddCliente">
                                                <ion-icon name="add-outline"></ion-icon>Novo cliente
                                            </button>
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            <!-- Campo para Motivo da Retirada -->
                            <div class="group-input">
                                <h4 for="motivoRetirada">Motivo da Retirada*</h4>
                                <div class="select" id="select-motivo" @click="toggleDisplayOpcoesMotivo"
                                    ref="selectMotivo">
                                    <span v-if="form.motivoRetirada" id="selected-motivo">{{ form.motivoRetirada
                                        }}</span>
                                    <span v-else id="selected-motivo">Selecione</span>
                                    <span v-if="form.motivoRetirada" class="remove-motivo-btn"
                                        @click.stop="removeMotivo">X</span>
                                    <span v-else class="icon-expanded" id="icon-expanded-motivo"></span>
                                    <ul id="drop-motivo" class="dropdown" :class="{ 'show': displayOpcoesMotivo }">
                                        <li v-for="motivo in opcoesMotivo" :key="motivo" class="selectable-option"
                                            @click="selectMotivo(motivo)">
                                            <label class="radio-container">
                                                <span>{{ motivo }}</span>
                                                <input type="radio" class="radio" :value="motivo"
                                                    v-model="form.motivoRetirada" />
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Campo para Seleção de Imóvel -->
                            <div class="group-input">
                                <div class="input-title">
                                    <h4 for="imovel">Imóveis*</h4>
                                    <span class="sub-text">(Selecione até {{ maxImoveis }} imóveis)</span>
                                </div>
                                <div class="select" id="select-imovel" ref="selectImovel"
                                    @click="toggleDisplayOpcoesImovel">
                                    <span v-if="form.imoveis.length" id="selected-imovel">Imóveis ({{
                                        form.imoveis.length }})</span>
                                    <span v-else id="selected-imovel">Selecione</span>
                                    <span v-if="form.imoveis.length" class="remove-imovel-btn"
                                        @click.stop="removeImovel">X</span>
                                    <span v-else class="icon-expanded" id="icon-expanded-imovel"></span>
                                    <ul id="drop-imovel" class="dropdown" :class="{ 'show': displayOpcoesImovel }"
                                        @click.stop>
                                        <div class="filter-imovel">
                                            <input type="text" ref="filterImovelInput"
                                                @input="carregarImoveis($event.target.value)"
                                                placeholder="Pesquisar imóvel">
                                        </div>
                                        <li v-for="imovel in opcoesImovel" :key="imovel.id_imovel"
                                            class="selectable-option">
                                            <label class="radio-container">
                                                <span>{{ imovel.id_imovel }}</span>
                                                <input type="checkbox" class="checkbox" :value="imovel.id_imovel"
                                                    :checked="imovel.selecionado" :disabled="imovel.desabilitado"
                                                    @change="toggleImovel(imovel.id_imovel)" />
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Campo para Data e Hora de Retirada -->
                            <div class="group-input">
                                <h4 for="retirada">Data e Hora de Retirada*</h4>
                                <input type="datetime-local" v-model="form.retirada" required />
                            </div>

                            <!-- Campo para Previsão de Devolução -->
                            <div class="group-input">
                                <h4 for="previsaoDevolucao">Previsão de Devolução*</h4>
                                <input type="datetime-local" v-model="form.previsaoDevolucao" required />
                            </div>

                            <!-- Campo para Observações -->
                            <div class="group-input">
                                <h4>Observações</h4>
                                <textarea v-model="form.observacoes" />
                            </div>
                        </div>
                    </div>

                    <!-- Modal de cadastro de cliente -->
                    <section v-show="displayModalAddCliente" class="modal-cliente">
                        <div class="modal-cliente-container">
                            <div class="header-property">
                                <h2>Informações do cliente</h2>
                                <div class="close-property" @click="closeModalAddCliente">X</div>
                            </div>
                            <hr>
                            <form id="form-cliente" @submit.prevent="cadastrarCliente">
                                <div class="section">
                                    <h3>Dados Pessoais</h3>
                                    <div class="grid-container" id="grid-pessoais">
                                        <div class="group-input">
                                            <h4>Nome*</h4>
                                            <input type="text" v-model="form_cliente.nome" tabindex="1" />
                                            <div class="error-message-input" v-if="errors_cliente.nome">{{
                                                errors_cliente.nome }}</div>
                                        </div>
                                        <div class="group-input">
                                            <h4>Sobrenome*</h4>
                                            <input type="text" v-model="form_cliente.sobrenome" tabindex="2" />
                                            <div class="error-message-input" v-if="errors_cliente.sobrenome">{{
                                                errors_cliente.sobrenome }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid-container" id="grid-pessoais">
                                        <div class="group-input">
                                            <h4 for="tipoCliente">Tipo*</h4>
                                            <div class="select" id="select-tipoCliente"
                                                @click="toggleDisplayOpcoesTipoCliente"
                                                @keypress.enter="toggleDisplayOpcoesTipoCliente" tabindex="3">
                                                <span v-if="form_cliente.tipo" id="selected-tipoCliente">{{
                                                    form_cliente.tipo }}</span>
                                                <span v-else id="selected-tipoCliente">Selecione</span>
                                                <span class="icon-expanded"></span>
                                                <ul id="drop-tipoCliente" class="dropdown"
                                                    :class="{ 'show': displayOpcoesTipoCliente }">
                                                    <li v-for="tipo in opcoesTipoCliente" :key="tipo"
                                                        class="selectable-option" :id="'option-' + tipo">
                                                        <label class="radio-container">
                                                            <span>{{ tipo }}</span>
                                                            <input type="radio" class="radio-tipo" :value="tipo"
                                                                v-model="form_cliente.tipo" tabindex="4" />
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="group-input">
                                            <h4>Data de Nascimento*</h4>
                                            <input type="date" v-model="form_cliente.dataNascimento" tabindex="5" />
                                        </div>
                                        <div class="group-input">
                                            <h4>Documento*</h4>
                                            <input type="text" placeholder="CPF/CNPJ" v-model="form_cliente.documento"
                                                @input="form_cliente.documento = formatarDocumento($event.target.value)"
                                                tabindex="6" />
                                            <div class="error-message-input" v-if="errors_cliente.documento">{{
                                                errors_cliente.documento }}
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <!-- Endereço Atual -->
                                <div class="section">
                                    <h3>Endereço Atual</h3>
                                    <div class="grid-container">
                                        <div class="group-input">
                                            <h4>CEP</h4>
                                            <input type="text" v-model="form_cliente.endereco.cep"
                                                @input="form_cliente.endereco.cep = formatarCEP($event.target.value), inserirEnderecoBuscadoCliente($event.target.value)"
                                                tabindex="7" />
                                        </div>
                                        <div class="group-input">
                                            <h4>Rua*</h4>
                                            <input type="text" v-model="form_cliente.endereco.rua" tabindex="8" />
                                        </div>
                                        <div class="group-input">
                                            <h4>Número*</h4>
                                            <input type="number" v-model="form_cliente.endereco.numero" tabindex="9" />
                                        </div>
                                        <div class="group-input">
                                            <h4>Complemento</h4>
                                            <input type="text" v-model="form_cliente.endereco.complemento"
                                                tabindex="10" />
                                        </div>
                                        <div class="group-input">
                                            <h4>Bairro*</h4>
                                            <input type="text" v-model="form_cliente.endereco.bairro" tabindex="11" />
                                        </div>
                                        <div class="group-input">
                                            <h4>Cidade*</h4>
                                            <input type="text" v-model="form_cliente.endereco.cidade" tabindex="12" />
                                        </div>
                                        <div class="group-input">
                                            <h4>Estado*</h4>
                                            <input type="text" v-model="form_cliente.endereco.estado"
                                                @input="form_cliente.endereco.estado = formatarEstado($event.target.value)"
                                                tabindex="13" />
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <!-- Informações de Contato -->
                                <div class="section">
                                    <h3>Dados de Contato</h3>
                                    <div class="grid-container">
                                        <div class="group-input">
                                            <h4>E-mail*</h4>
                                            <input type="email" v-model="form_cliente.email" tabindex="14" />
                                            <div class="error-message-input" v-if="errors_cliente.email">{{
                                                errors_cliente.email }}</div>
                                        </div>
                                        <div class="group-input">
                                            <h4>Telefone Celular*</h4>
                                            <input type="text" v-model="form_cliente.telefoneCelular"
                                                @input="form_cliente.telefoneCelular = formatarTelefone($event.target.value)"
                                                tabindex="15" />
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <!-- Botões -->
                                <div class="btn-container">
                                    <button type="button" class="btn-anexar" @click="abrirModalUploadDocumentos">Anexar
                                        Documentos</button>
                                    <div class="btn-group-right">
                                        <button type="button" class="button"
                                            @click="closeModalAddCliente">Cancelar</button>
                                        <button type="submit" class="btn-salvar-form"
                                            :disabled="!isFormClienteValid">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>

                    <!-- Modal de upload de arquivos -->
                    <section class="modal-upload" v-if="mostrarModalUploadDocumento">
                        <div class="modal-container">
                            <h2>Upload de Documentos do Cliente</h2>
                            <div class="upload-container">
                                <div ref="preview_documento" class="preview-container">
                                    <div ref="uploadPlaceholder_documento" class="image-upload upload-placeholder">
                                        <label for="documentoCliente">
                                            <div class="upload-placeholder">
                                                <span>+</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <input type="file" id="documentoCliente" accept="image/*" multiple style="display: none"
                                @change="carregarDocumentosSelecionados">
                            <div class="btn-modal">
                                <button type="button" class="button"
                                    @click="cancelarModalUploadDocumentos">Voltar</button>
                                <button type="button" class="btn-salvar-documentos"
                                    @click="saveAndCloseModalDocumentos">Salvar
                                    documentos</button>
                            </div>
                        </div>
                    </section>

                    <!-- Botões -->
                    <div class="btn-container">
                        <div class="btn-group-right">
                            <button type="button" class="button" @click="closeForm">Cancelar</button>
                            <button type="button" class="btn-salvar-form" :disabled="!isFormValid"
                                @click.prevent="showSaveConfirmation">Salvar</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <Popup ref="popup" />
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import api from '@/axios.js';
import Sortable from 'sortablejs';
import Popup from '@/mixins/Popup.vue';
import Loading from '@/mixins/Loading.vue';
import { emitter } from '@/router/admin.js';
import script from '@/mixins/script.js';

export default {
    name: 'RetirarChaves',
    mixins: [script],
    data() {
        return {
            form: {
                categoria: '',
                proprietario: '',
                motivoRetirada: '',
                imoveis: [],
                retirada: '',
                previsaoDevolucao: '',
                observacoes: ''
            },
            form_cliente: {
                nome: '',
                sobrenome: '',
                tipo: '',
                dataNascimento: '',
                documento: '',
                identidade: '',
                nacionalidade: '',
                estadoCivil: '',
                profissao: '',
                rendaMensal: '',
                email: '',
                telefoneResidencial: '',
                telefoneCelular: '',
                endereco: {
                    cep: '',
                    rua: '',
                    numero: '',
                    complemento: '',
                    bairro: '',
                    cidade: '',
                    estado: ''
                },
                conjuge: {
                    nome_conjuge: '',
                    doc_conjuge: '',
                    identidade_conjuge: '',
                    telefone_conjuge: ''
                },
                referencia: {
                    nome_referencia: '',
                    telefone_referencia: '',
                    relacao_referencia: ''
                }
            },
            opcoesProprietarios: [],
            opcoesImovel: [],
            opcoesMotivo: ['Visita', 'Vistoria', 'Manutenção'],
            documentosTemporarios: [],
            documentosSalvos: [],
            displayOpcoesProprietarios: false,
            displayOpcoesMotivo: false,
            displayOpcoesImovel: false,
            displayModalAddCliente: false,
            displayOpcoesTipoCliente: false,
            mostrarModalUploadDocumento: false,
            opcoesTipoCliente: ['Proprietário', 'Cliente', 'Corretor', 'Interessado'],
            errors_cliente: {
                nome: '',
                sobrenome: '',
                documento: '',
                email: ''
            },
            MAX_FILES: 30,
            sortableInstance: null,
            isLoading: false,
            maxImoveis: 5
        };
    },
    computed: {
        isFormValid() {
            return this.form.proprietario && this.form.motivoRetirada && this.form.imoveis.length &&
                this.form.retirada && this.form.previsaoDevolucao;
        },
        isFormClienteValid() {
            return this.form_cliente.nome && this.form_cliente.sobrenome && this.form_cliente.tipo && this.form_cliente.dataNascimento && this.form_cliente.documento && this.form_cliente.endereco.rua && this.form_cliente.endereco.numero && this.form_cliente.endereco.bairro && this.form_cliente.endereco.cidade && this.form_cliente.endereco.estado && this.form_cliente.email && this.form_cliente.telefoneCelular;
        },
        isEdit() {
            return Boolean(this.$route.params.id);
        },
        selectedProprietario() {
            const cliente = this.opcoesProprietarios.find(cliente => cliente._id === this.form.proprietario);
            return cliente ? cliente : '';
        }
    },
    async created() {
        this.setRetiradaData();
        await this.carregarClientes();
        await this.carregarImoveis();
        if (this.isEdit) {
            await this.carregarDadosChaves(this.$route.params.id);
        }
        this.saveInitialFormState();
    },
    methods: {
        setRetiradaData() {
            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset() * 60000;
            const localISOTime = new Date(now - timezoneOffset).toISOString().slice(0, 16);

            this.form.retirada = localISOTime;

            const previsao = new Date(now.getTime() + 3 * 60 * 60 * 1000);
            const localPrevisaoISOTime = new Date(previsao - timezoneOffset).toISOString().slice(0, 16);
            this.form.previsaoDevolucao = localPrevisaoISOTime;
        },
        async inserirEnderecoBuscado(cep) {
            cep = cep.replace(/\D/g, '');
            if (cep.length === 8) {
                const endereco = await this.buscarEndereco(cep);
                this.form.rua = endereco.logradouro;
                this.form.bairro = endereco.bairro;
                this.form.cidade = endereco.localidade;
                this.form.estado = endereco.uf;
            }
        },
        async inserirEnderecoBuscadoCliente(cep) {
            cep = cep.replace(/\D/g, '');
            if (cep.length === 8) {
                const endereco = await this.buscarEndereco(cep);
                this.form_cliente.endereco.rua = endereco.logradouro;
                this.form_cliente.endereco.bairro = endereco.bairro;
                this.form_cliente.endereco.cidade = endereco.localidade;
                this.form_cliente.endereco.estado = endereco.uf;
            }
        },
        async carregarClientes(search = '') {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/clientes?query=${search}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = await response.json();
                this.opcoesProprietarios = data.clientes;
            } catch (error) {
                console.error(`Erro ao buscar clientes:`, error);
            }
        },
        async carregarImoveis(search = '') {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/imoveis-disponiveis`, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                });

                let imoveis = await response.json();

                if (search) {
                    const regex = new RegExp(search, 'i');
                    imoveis = imoveis.filter(imovel => regex.test(imovel.id_imovel));
                }

                imoveis = imoveis.sort((a, b) => {
                    const aSelected = this.form.imoveis.find(imovel => imovel.id_imovel === a.id_imovel);
                    const bSelected = this.form.imoveis.find(imovel => imovel.id_imovel === b.id_imovel);
                    if (aSelected && !bSelected) return -1;
                    if (!aSelected && bSelected) return 1;
                    return 0;
                });

                this.opcoesImovel = imoveis;
            } catch (error) {
                console.error('Erro ao buscar imóveis disponíveis:', error);
            }
        },
        toggleDisplayOpcoesProprietario() {
            this.displayOpcoesProprietarios = !this.displayOpcoesProprietarios;
        },
        toggleDisplayOpcoesMotivo() {
            this.displayOpcoesMotivo = !this.displayOpcoesMotivo;
        },
        toggleDisplayOpcoesImovel() {
            this.displayOpcoesImovel = !this.displayOpcoesImovel;
            if (this.displayOpcoesImovel) {
                this.$nextTick(() => {
                    document.addEventListener('click', this.handleClickOutsideImovel);
                });
            } else {
                document.removeEventListener('click', this.handleClickOutsideImovel);
            }
        },
        handleClickOutsideImovel(event) {
            const selectImovelRef = this.$refs.selectImovel;
            if (selectImovelRef && selectImovelRef.contains && !selectImovelRef.contains(event.target)) {
                this.displayOpcoesImovel = false;
                document.removeEventListener('click', this.handleClickOutsideImovel);
            }
        },
        selectMotivo(motivo) {
            this.form.motivoRetirada = motivo;
            this.displayOpcoesMotivo = false;
        },
        removeProprietario() {
            this.form.proprietario = '';
        },
        removeMotivo() {
            this.form.motivoRetirada = '';
        },
        toggleImovel(idImovel) {
            const index = this.form.imoveis.findIndex(imovel => imovel.id_imovel === idImovel);

            if (index !== -1) {
                this.form.imoveis.splice(index, 1);
            } else {
                if (this.form.imoveis.length >= this.maxImoveis) {
                    this.$refs.popup.showAlert(`Você só pode selecionar até ${this.maxImoveis} imóveis.`);
                    return;
                }
                const now = new Date();
                const timezoneOffset = now.getTimezoneOffset() * 60000;
                const dataRetirada = new Date(now - timezoneOffset).toISOString().slice(0, 16);
                const dataDevolucao = new Date(now.getTime() + 3 * 60 * 60 * 1000 - timezoneOffset).toISOString().slice(0, 16);

                this.form.imoveis.push({
                    id_imovel: idImovel,
                    status: "retirado",
                    dataRetirada: dataRetirada,
                    dataDevolucao: dataDevolucao
                });
            }

            this.atualizarEstadoImoveis();
        },
        atualizarEstadoImoveis() {
            this.opcoesImovel = this.opcoesImovel.map(imovel => ({
                ...imovel,
                desabilitado: this.form.imoveis.length >= this.maxImoveis && !this.form.imoveis.some(i => i.id_imovel === imovel.id_imovel),
                selecionado: this.form.imoveis.some(i => i.id_imovel === imovel.id_imovel)
            }));
            this.opcoesImovel.sort((a, b) => {
                if (a.selecionado && !b.selecionado) return -1;
                if (!a.selecionado && b.selecionado) return 1;
                return 0;
            });
        },
        removeImovel() {
            this.form.imoveis = [];
            this.displayOpcoesImovel = false;
            this.opcoesImovel = this.opcoesImovel.map(imovel => ({
                ...imovel,
                selecionado: false
            }));

            this.opcoesImovel.sort((a, b) => {
                return b.selecionado - a.selecionado;
            });
        },
        openModalAddCliente() {
            this.displayModalAddCliente = true;
        },
        closeModalAddCliente() {
            this.displayModalAddCliente = false;
        },
        toggleDisplayOpcoesTipoCliente() {
            this.displayOpcoesTipoCliente = !this.displayOpcoesTipoCliente;
        },
        abrirModalUploadDocumentos() {
            this.documentosSalvosBackup = JSON.parse(JSON.stringify(this.documentosTemporarios));
            this.mostrarModalUploadDocumento = true;
            this.$nextTick(() => {
                if (this.$refs.preview_documento && this.$refs.uploadPlaceholder_documento) {
                    this.carregarDocumentosTemporarios();
                    this.inicializarSortableDocumento();
                }
            });
        },
        cancelarModalUploadDocumentos() {
            this.documentosTemporarios = JSON.parse(JSON.stringify(this.documentosSalvosBackup));
            this.mostrarModalUploadDocumento = false;
        },
        carregarDocumentosTemporarios() {
            if (!this.$refs.preview_documento || !this.$refs.uploadPlaceholder_documento) {
                console.error('Elementos necessários não encontrados para carregar os documentos salvos.');
                return;
            }

            const preview = this.$refs.preview_documento;
            const uploadPlaceholder = this.$refs.uploadPlaceholder_documento;

            while (preview.firstChild) {
                preview.removeChild(preview.firstChild);
            }

            preview.appendChild(uploadPlaceholder);
            this.documentosTemporarios.forEach((imagem) => {
                const fileContainer = document.createElement('div');
                fileContainer.classList.add('file-container');
                const file = document.createElement('img');
                file.src = imagem.src;
                file.file = imagem.file;
                fileContainer.appendChild(file);

                const removeButton = document.createElement('button');
                removeButton.classList.add('remove-file');
                removeButton.onclick = () => {
                    fileContainer.remove();
                    const index = this.documentosTemporarios.indexOf(imagem);
                    if (index > -1) {
                        this.documentosTemporarios.splice(index, 1);
                    }
                    this.verificarLimiteDocumentos();
                };
                fileContainer.appendChild(removeButton);

                preview.insertBefore(fileContainer, uploadPlaceholder);
            });

            this.verificarLimiteDocumentos();
        },
        carregarDocumentosSelecionados(event) {
            const input = event.target;
            const preview = this.$refs.preview_documento;
            const uploadPlaceholder = this.$refs.uploadPlaceholder_documento;
            const currentFiles = preview.querySelectorAll('.file-container').length;
            const filesToAdd = input.files.length;

            if (currentFiles + filesToAdd > this.MAX_FILES) {
                this.$refs.popup.showAlert(`Você só pode adicionar até ${this.MAX_FILES} documentos.`);
                input.value = '';
                return;
            }

            for (const file of input.files) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const fileContainer = document.createElement('div');
                    fileContainer.classList.add('file-container');
                    const img = document.createElement('img');
                    img.src = event.target.result;
                    img.file = file;
                    fileContainer.appendChild(img);

                    const removeButton = document.createElement('button');
                    removeButton.classList.add('remove-file');
                    removeButton.onclick = () => {
                        fileContainer.remove();
                        const index = this.documentosTemporarios.findIndex((img) => img.src === event.target.result);
                        if (index !== -1) {
                            this.documentosTemporarios.splice(index, 1);
                        }
                        this.verificarLimiteDocumentos();
                    };
                    fileContainer.appendChild(removeButton);

                    preview.insertBefore(fileContainer, uploadPlaceholder);
                    this.documentosTemporarios.push({ src: event.target.result, file, isNew: true, ordem: this.documentosTemporarios.length });
                };
                reader.readAsDataURL(file);
            }

            input.value = '';

            this.inicializarSortableDocumento();
            this.verificarLimiteDocumentos();
        },
        inicializarSortableDocumento() {
            if (this.sortableInstance) {
                this.sortableInstance.destroy();
            }
            this.sortableInstance = new Sortable(this.$refs.preview_documento, {
                animation: 150,
                ghostClass: 'sortable-ghost',
                filter: '#uploadPlaceholder',
                onEnd: (evt) => {
                    const uploadPlaceholder = this.$refs.uploadPlaceholder_documento;
                    if (uploadPlaceholder.parentNode) {
                        uploadPlaceholder.parentNode.appendChild(uploadPlaceholder);
                    }

                    const fileContainers = this.$refs.preview_documento.querySelectorAll('.file-container');
                    fileContainers.forEach((container, index) => {
                        const img = container.querySelector('img');
                        if (img) {
                            const tempImg = this.documentosTemporarios.find(image => image.src === img.src);
                            if (tempImg) {
                                tempImg.ordem = index;
                            }
                        }
                    });
                },
            });
        },
        saveAndCloseModalDocumentos() {
            const preview = this.$refs.preview_documento;
            const fileContainers = preview.querySelectorAll('.file-container');
            this.documentosTemporarios = [];

            fileContainers.forEach((container, index) => {
                const img = container.querySelector('img');
                if (img) {
                    this.documentosTemporarios.push({
                        src: img.src,
                        file: img.file,
                        ordem: index,
                        isNew: img.file ? true : false,
                        filename: img.filename || (img.src.split('/').pop())
                    });
                }
            });

            this.mostrarModalUploadDocumento = false;
        },
        verificarLimiteDocumentos() {
            const preview = this.$refs.preview_documento;
            const uploadPlaceholder = this.$refs.uploadPlaceholder_documento;
            const currentFiles = preview.querySelectorAll('.file-container').length;

            if (currentFiles >= this.MAX_FILES) {
                uploadPlaceholder.style.display = 'none';
            } else {
                uploadPlaceholder.style.display = 'flex';
            }
        },
        async cadastrarCliente() {
            this.isLoading = true
            this.limparRespotasCliente();

            const payload = { ...this.form_cliente };
            payload.documento = payload.documento.replace(/\D/g, '');
            payload.endereco.cep = payload.endereco.cep ? payload.endereco.cep.replace(/\D/g, '') : null;
            payload.telefoneCelular = payload.telefoneCelular.replace(/\D/g, '');

            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/clientes`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    const errorMessage = await response.text();
                    throw new Error(errorMessage);
                }

                const data = await response.json();

                await this.carregarClientes();
                this.form.proprietario = data._id;
                this.closeModalAddCliente();

                if (this.documentosTemporarios.length > 0) {
                    await this.enviarDocumentos(data._id);
                }

                this.$refs.popup.showAlert(`Cliente cadastrado com sucesso!`);

            } catch (error) {
                this.mostrarErroCliente(error.message);
            } finally {
                this.isLoading = false
            }
        },
        limparRespotasCliente() {
            this.errors_cliente = {
                nome: '',
                sobrenome: '',
                documento: '',
                email: ''
            }
        },
        mostrarErroCliente(mensagem) {
            mensagem = mensagem.replace(/"/g, '')
            if (mensagem.includes('O nome deve conter apenas letras')) {
                this.errors_cliente.nome = mensagem;
            } else if (mensagem.includes('O sobrenome deve conter apenas letras')) {
                this.errors_cliente.sobrenome = mensagem;
            } else if (mensagem.includes('documento') || mensagem.includes('Documento')) {
                this.errors_cliente.documento = mensagem;
            } else if (mensagem.includes('E-mail') || mensagem.includes('e-mail')) {
                this.errors_cliente.email = mensagem;
            } else {
                console.error('Erro ao salvar cliente:', mensagem);
                this.$refs.popup.showAlert('Erro ao salvar cliente:', mensagem)
            }
        },
        async enviarDocumentos(clienteId) {
            const formData = new FormData();

            this.documentosTemporarios.forEach((img) => {
                if (img.isNew) {
                    formData.append('imagem', img.file, img.file.name);
                }
            });

            formData.append('todasImagens', JSON.stringify(this.documentosTemporarios.map(img => ({
                src: img.src,
                filename: img.filename,
                url: img.url || `${process.env.VUE_APP_STORAGE_IMAGENS_URL}/${process.env.VUE_APP_IMOBILIARIA}/clientes/documentos/${clienteId}/${img.filename}`,
                ordem: img.ordem
            }))));

            const url = `${process.env.VUE_APP_BACKEND_URL}/clientes/imagem/${clienteId}`;

            try {
                const response = await fetch(url, {
                    method: 'PUT',
                    body: formData,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    const errorMessage = await response.text();
                    throw new Error(errorMessage);
                }

                this.documentosSalvos = [...this.documentosTemporarios];
            } catch (error) {
                await this.$refs.popup.showAlert('Erro ao enviar documentos: ' + error.message)
                throw error;
            }
        },
        closeDropDowns() {
            document.addEventListener('click', (event) => {
                const isClickDropCliente = event.target.id === 'drop-proprietario';
                const isClickToggleCliente = event.target.id === 'select-proprietario';
                const isClickTextToggleCliente = event.target.id === 'selected-proprietario';
                const isClickIconToggleCliente = event.target.id === 'icon-expanded-proprietario';
                const isClickFilterProprietario = event.target.classList.contains('filter-proprietario');

                if (!isClickDropCliente && !isClickToggleCliente && !isClickTextToggleCliente && !isClickIconToggleCliente && !isClickFilterProprietario) {
                    this.displayOpcoesProprietarios = false;
                }

                const isClickDropMotivo = event.target.id === 'drop-motivo';
                const isClickToggleMotivo = event.target.id === 'select-motivo';
                const isClickTextToggleMotivo = event.target.id === 'selected-motivo';
                const isClickIconToggleMotivo = event.target.id === 'icon-expanded-motivo';

                if (!isClickDropMotivo && !isClickToggleMotivo && !isClickTextToggleMotivo && !isClickIconToggleMotivo) {
                    this.displayOpcoesMotivo = false;
                }

                const isClickDropImovel = event.target.id === 'drop-imovel';
                const isClickToggleImovel = event.target.id === 'select-imovel';
                const isClickTextToggleImovel = event.target.id === 'selected-imovel';
                const isClickIconToggleImovel = event.target.id === 'icon-expanded-imovel';
                const isClickFilterImovel = event.target.classList.contains('filter-imovel');

                if (!isClickDropImovel && !isClickToggleImovel && !isClickTextToggleImovel && !isClickIconToggleImovel && !isClickFilterImovel) {
                    this.displayOpcoesImovel = false;
                }

                const isClickDropTipoCliente = event.target.id === 'drop-tipoCliente';
                const isClickToggleTipoCliente = event.target.id === 'select-tipoCliente';
                const isClickTextToggleTipoCliente = event.target.id === 'selected-tipoCliente';
                const isClickIconToggleTipoCliente = event.target.classList.contains('icon-expanded');

                if (!isClickDropTipoCliente && !isClickToggleTipoCliente && !isClickTextToggleTipoCliente && !isClickIconToggleTipoCliente) {
                    this.displayOpcoesTipoCliente = false;
                }
            });
        },
        async handleSubmit(actionType) {
            const payload = {
                responsavel: this.form.proprietario,
                motivoRetirada: this.form.motivoRetirada,
                retirada: this.form.retirada ? new Date(this.form.retirada).toISOString() : null,
                previsaoDevolucao: this.form.previsaoDevolucao ? new Date(this.form.previsaoDevolucao).toISOString() : null,
                imoveis: this.form.imoveis.map(imovel => {
                    if (!imovel.id_imovel || !imovel.dataRetirada || !imovel.dataDevolucao) {
                        console.error("Imóvel inválido detectado:", imovel);
                        return null;
                    }
                    return {
                        id_imovel: imovel.id_imovel,
                        status: imovel.status || "retirado",
                        dataRetirada: imovel.dataRetirada ? new Date(imovel.dataRetirada).toISOString() : null,
                        dataDevolucao: imovel.dataDevolucao ? new Date(imovel.dataDevolucao).toISOString() : null
                    };
                }).filter(imovel => imovel !== null),
                observacoes: this.form.observacoes
            };

            if (!payload.retirada || !payload.previsaoDevolucao) {
                console.error("Datas de retirada ou previsão de devolução estão inválidas.");
                this.$refs.popup.showAlert("As datas de retirada e previsão de devolução devem ser válidas.");
                return;
            }

            if (payload.imoveis.length === 0) {
                console.error("Nenhum imóvel válido foi selecionado.");
                this.$refs.popup.showAlert("Você deve selecionar pelo menos um imóvel válido.");
                return;
            }

            console.log("Final Payload:", payload);

            const url = this.isEdit
                ? `${process.env.VUE_APP_BACKEND_URL}/chaves/${this.$route.params.id}`
                : `${process.env.VUE_APP_BACKEND_URL}/chaves`;

            const method = this.isEdit ? 'put' : 'post';

            try {
                await api({
                    method: method,
                    url: url,
                    data: payload,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                this.showSuccessAndRedirect(`Retirada ${actionType === 'create' ? 'registrada' : 'atualizada'} com sucesso!`);
            } catch (error) {
                console.error(`Erro ao registrar retirada de chave:`, error);
                if (error.response && error.response.data !== 'Token expired.') {
                    await this.$refs.popup.showAlert(`Erro ao registrar retirada de chave: ` + error.response.data);
                }
            }
        },
        showSuccessAndRedirect(message) {
            const popup = document.getElementById('custom-popup');
            const popupMessage = document.getElementById('popup-message');
            const popupButtons = document.getElementById('popup-buttons');

            popupMessage.innerText = message;
            popup.style.display = 'flex';
            popupButtons.innerHTML = '';
            popupButtons.style.justifyContent = 'center';

            const okButton = document.createElement('button');
            okButton.innerText = 'OK';
            okButton.id = 'popup-ok';
            okButton.onclick = async () => {
                await this.$refs.popup.fecharPopup();
                this.$router.push('/admin/chaves');
            };

            popupButtons.appendChild(okButton);
        },
        showSaveConfirmation() {
            this.$refs.popup.showConfirm(`Deseja registrar a retirada?`, (confirmed) => {
                if (confirmed) {
                    this.handleSubmit(this.isEdit ? 'update' : 'create');
                }
            });
        },
        closeForm() {
            if (this.hasFormChanged()) {
                this.$refs.popup.showConfirm('Deseja sair sem salvar as informações?', (confirmed) => {
                    if (confirmed) {
                        this.$router.push('/admin/chaves');
                    }
                });
            } else {
                this.$router.push('/admin/chaves');
            }
        },
        hasFormChanged() {
            return JSON.stringify(this.form) !== JSON.stringify(this.initialFormState);
        },
        saveInitialFormState() {
            this.initialFormState = JSON.parse(JSON.stringify(this.form));
        }
    },
    mounted() {
        this.closeDropDowns();
    },
    watch: {
        displayOpcoesProprietarios(newVal) {
            if (newVal === true) {
                this.$nextTick(() => {
                    this.$refs.filterProprietarioInput.focus();
                });
            }
        },
        displayOpcoesImovel(newVal) {
            if (newVal === true) {
                this.$nextTick(() => {
                    this.$refs.filterImovelInput.focus();
                });
            }
        },
    },
    components: {
        Popup,
        Loading
    },
};
</script>

<style scoped>
@import '@/01-admin/assets/css/retirar-chaves.css';
</style>