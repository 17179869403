<template>
  <section class="categorias-home" id="categorias-section">
    <h2>Imóveis por categoria</h2>
    <div class="buttons-container-home">
      <button v-if="categoriasDisponiveis.Casa" class="categoria-btn-home"
        :class="{ active: categoriaSelecionada === 'Casa' }" @click="carregarImoveisPorCategoria('Casa')">Casas</button>
      <button v-if="categoriasDisponiveis['Apartamento']" class="categoria-btn-home"
        :class="{ active: categoriaSelecionada === 'Apartamento' }"
        @click="carregarImoveisPorCategoria('Apartamento')">Apartamentos</button>
      <button v-if="categoriasDisponiveis['Terreno']" class="categoria-btn-home"
        :class="{ active: categoriaSelecionada === 'Terreno' }"
        @click="carregarImoveisPorCategoria('Terreno')">Terrenos</button>
      <button v-if="categoriasDisponiveis['Kitnet']" class="categoria-btn-home"
        :class="{ active: categoriaSelecionada === 'Kitnet' }"
        @click="carregarImoveisPorCategoria('Kitnet')">Kitnets</button>
      <button v-if="categoriasDisponiveis['Sitio&Chacara']" class="categoria-btn-home"
        :class="{ active: categoriaSelecionada === 'Sitio&Chacara' }"
        @click="carregarImoveisPorCategoria('Sitio&Chacara')">Sítios/Chácaras</button>
      <button v-if="categoriasDisponiveis['Comercial']" class="categoria-btn-home"
        :class="{ active: categoriaSelecionada === 'Comercial' }"
        @click="carregarImoveisPorCategoria('Comercial')">Comércios</button>
    </div>
    <div class="cards-container-home">
      <div id="categoriaContainer" class="cards-home">
        <article v-for="imovel in imoveisPorCategoria" :key="imovel.id_imovel" class="card-home"
          @click="navigateToDetails(imovel.id_imovel)">
          <div class="carrossel-home" @touchstart="handleSwipeStart($event, imovel.id_imovel)"
            @touchmove="handleSwipeMove($event)" @touchend="handleSwipeEnd(imovel.id_imovel)">
            <div class="images-home">
              <img v-for="(image, index) in imovel.imagens" :src="image.url" :alt="`${imovel.id_imovel} - ${index}`"
                :class="{ 'active': index === imovel.currentImageIndex }" :key="index" />
            </div>
            <div class="carrosselPrev-area" @click.stop="showImage(imovel.id_imovel, -1)"></div>
            <button class="carrosselPrev-home" @click.stop="showImage(imovel.id_imovel, -1)">❮</button>
            <div class="carrosselNext-area" @click.stop="showImage(imovel.id_imovel, 1)"></div>
            <button class="carrosselNext-home" @click.stop="showImage(imovel.id_imovel, 1)">❯</button>
            <div class="carrossel-indicators-home">
              <span v-for="(image, index) in imovel.imagens" :class="{ active: index === imovel.currentImageIndex }"
                :key="index" class="indicator"></span>
            </div>
          </div>
          <div class="info-home">
            <div>
              <span id="idImovelCategorias">{{ imovel.id_imovel }}</span>
            </div>
            <p v-if="imovel.categoria">{{ imovel.categoria }}</p>
            <p v-if="imovel.bairro || imovel.cidade || imovel.estado">
              <b>{{ imovel.bairro }}<br>{{ imovel.cidade }} - {{ imovel.estado }}</b>
            </p>
            <section class="dados-container">
              <div class="dados-group" v-if="imovel.areaTotal || imovel.areaConstruida">
                <img src="@/00-website/assets/imagens/detalhes/wide 1.png">
                <p>{{ formatarArea(imovel.areaTotal || imovel.areaConstruida) }}</p>
              </div>
              <div class="dados-group" v-if="imovel.quartos">
                <img src="@/00-website/assets/imagens/detalhes/bed 1.png">
                <p>{{ imovel.quartos }}</p>
              </div>
              <div class="dados-group" v-if="imovel.banheiros">
                <img src="@/00-website/assets/imagens/detalhes/shower 1.png">
                <p>{{ imovel.banheiros }}</p>
              </div>
              <div class="dados-group" v-if="imovel.garagem">
                <img src="@/00-website/assets/imagens/detalhes/garagem 1.png">
                <p>{{ imovel.garagem }}</p>
              </div>
            </section>
            <div v-if="imovel.comercio.includes('Venda') && imovel.comercio.includes('Aluguel')"
              class="valores valores-duplo">
              <div class="valor venda">
                <p>Venda</p>
                <p>{{ imovel.consultarValorVenda ? 'Consulte' : formatarValor(imovel.valorVenda) }}</p>
              </div>
              <div class="valor aluguel">
                <p>Locação</p>
                <p>{{ imovel.consultarValorAluguel ? 'Consulte' : formatarValor(imovel.valorAluguel, true) }}</p>
              </div>
            </div>
            <div v-else-if="imovel.comercio.includes('Venda')" class="valor venda">
              <p>Venda</p>
              <p>{{ imovel.consultarValorVenda ? 'Consulte' : formatarValor(imovel.valorVenda) }}</p>
            </div>
            <div v-else-if="imovel.comercio.includes('Aluguel')" class="valor aluguel">
              <p>Locação</p>
              <p>{{ imovel.consultarValorAluguel ? 'Consulte' : formatarValor(imovel.valorAluguel, true) }}</p>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="ver-todos-container-home">
      <button class="ver-todos-btn-home" @click="verTodosImoveis">Ver todos</button>
    </div>
  </section>
</template>

<script>
import api from '@/axios.js';
import script from '@/mixins/script.js';
import defaultImage from '@/mixins/imagens/defaultImage.jpeg';

export default {
  name: 'ImoveisCategorias',
  mixins: [script],
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      frontendUrl: process.env.VUE_APP_STORAGE_IMAGENS_URL,
      categoriaSelecionada: 'Casa',
      imoveisPorCategoria: [],
      numeroDeCardsVisiveis: 3,
      categoriasDisponiveis: {
        ['Casa']: false,
        ['Apartamento']: false,
        ['Terreno']: false,
        ['Kitnet']: false,
        ['Sitio&Chacara']: false,
        ['Comercial']: false,
      },
      swipeStartX: 0,
      swipeEndX: 0,
      swipeStartY: 0,
      swipeEndY: 0
    };
  },
  mounted() {
    this.verificarCategoriasDisponiveis();
    window.addEventListener('resize', this.adjustCardDisplay);
    this.adjustCardDisplay();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustCardDisplay);
  },
  methods: {
    async verificarCategoriasDisponiveis() {
      const categorias = ['Casa', 'Apartamento', 'Terreno', 'Kitnet', 'Sitio&Chacara', 'Comercial'];
      for (let categoria of categorias) {
        const response = await api.get(`${this.backendUrl}/imoveis/categoria/${categoria}?limit=1`, {
          headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.data;
        if (data.imoveis && data.imoveis.length > 0) {
          this.categoriasDisponiveis[`${categoria}`] = true;
        }
      }

      this.carregarImoveisPorCategoria(this.categoriaSelecionada);
    },
    adjustCardDisplay() {
      const width = window.innerWidth;
      if (width < 860) {
        this.numeroDeCardsVisiveis = 1;
      } else if (width < 1080) {
        this.numeroDeCardsVisiveis = 2;
      } else {
        this.numeroDeCardsVisiveis = 3;
      }
    },
    async carregarImoveisPorCategoria(categoria) {
      this.categoriaSelecionada = categoria;

      try {
        const response = await api.get(`${this.backendUrl}/imoveis?categoria=${categoria}`, {
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.data;

        this.imoveisPorCategoria = data.imoveis
          .slice(0, this.numeroDeCardsVisiveis)
          .map(imovel => ({
            ...imovel,
            currentImageIndex: 0,
            imagens: imovel.imagens.length > 0 ? imovel.imagens.slice(0, 10) : [{ filename: 'defaultImage.jpeg', url: defaultImage }],
          }));
      } catch (error) {
        console.error('Erro ao carregar os imóveis por categoria:', error);
      }
    },

    showImage(imovelId, direction) {
      const imovel = this.imoveisPorCategoria.find(imovel => imovel.id_imovel === imovelId);
      if (imovel) {
        const totalImages = imovel.imagens.length;
        if (totalImages > 1) {
          imovel.currentImageIndex = (imovel.currentImageIndex + direction + totalImages) % totalImages;
        }
      }
    },

    handleSwipeStart(event) {
      this.swipeStartX = event.touches[0].clientX;
      this.swipeStartY = event.touches[0].clientY;
    },

    handleSwipeMove(event) {
      this.swipeEndX = event.touches[0].clientX;
      this.swipeEndY = event.touches[0].clientY;
    },

    handleSwipeEnd(imovelId) {
      const swipeDistanceX = this.swipeStartX - this.swipeEndX;
      const swipeDistanceY = this.swipeStartY - this.swipeEndY;
      const swipeThreshold = 50;

      if (Math.abs(swipeDistanceX) > Math.abs(swipeDistanceY)) {
        if (swipeDistanceX > swipeThreshold) {
          this.showImage(imovelId, 1);
        } else if (swipeDistanceX < -swipeThreshold) {
          this.showImage(imovelId, -1);
        }
      }
    },

    async verTodosImoveis() {
      try {
        const routeName = 'ImoveisCategoria';
        const params = { categoria: this.categoriaSelecionada };

        this.$router.push({ name: routeName, params: params });
      } catch (error) {
        console.error(`Erro ao buscar imóveis por ${this.categoriaSelecionada}:`, error);
      }
    }
  }
};
</script>
