<template>
  <div class="gestao-imoveis-component">
    <section class="gestaoImoveis">
      <div class="container">
        <h2>Gestão de imóveis</h2>
        <div class="header-search">
          <div class="search-container">
            <input type="text" class="search-bar" placeholder="Pesquisar" v-model="searchQuery" @input="searchImoveis"
              @keypress.enter="applySearch">
            <div class="clear-button-container" v-show="clearButtonVisible" @click="clearSearchBar">
              <button class="clear-button">&times;</button>
            </div>
            <button class="search-button" @click="applySearch">
              <img src="@/00-website/assets/imagens/lupa.png" alt="Buscar">
            </button>
          </div>
          <div id="searchResults" class="search-results" v-show="searchResultsVisible">
            <div v-for="(group, groupName) in filteredGroupedResults" :key="groupName">
              <div><strong>{{ groupName }}</strong></div>
              <div v-for="item in group" :key="item" class="selectable-option" @click="selectSuggestion(item)">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="button-header">
            <button id="novoImovel-button" @click="cadastrarNovoImovel">Cadastrar novo imóvel</button>
          </div>
        </div>

        <div class="divTable">
          <table>
            <thead>
              <tr>
                <th class="imgImovel">Imagem</th>
                <th @click="changeOrder('id_imovel')">
                  <div class="id_imovel">ID<span class="icon"
                      :class="{ rotate: currentSortField === 'id_imovel' && currentSortOrder === 'asc' }"></span>
                  </div>
                </th>

                <th @click="changeOrder('categoria')">
                  <div class="categoria">Categoria<span class="icon"
                      :class="{ rotate: currentSortField === 'categoria' && currentSortOrder === 'asc' }"></span>
                  </div>
                </th>

                <th @click="changeOrder('rua')">
                  <div class="endereco">Endereço<span class="icon"
                      :class="{ rotate: currentSortField === 'rua' && currentSortOrder === 'asc' }"></span>
                  </div>
                </th>

                <th>
                  <div class="valoresColum">Preço</div>
                </th>

                <th class="publicar">Publicar</th>
                <th class="destaque">Destaque</th>
                <th class="acao">Editar/Excluir</th>
              </tr>
            </thead>
            <tbody id="imoveisTableBody">
              <tr v-for="imovel in imoveisRenderizados" :key="imovel.id_imovel">
                <td class="imagemCell">
                  <img :src="getImageUrl(imovel)" alt="Imagem do Imóvel" class="imovel-imagem-tabela" />
                </td>

                <td>{{ imovel.id_imovel }}</td>
                <td>{{ imovel.categoria }}</td>
                <td>
                  <span v-if="imovel.rua && imovel.numero">{{ imovel.rua }}, {{ imovel.numero }}</span>
                  <span v-else-if="imovel.rua && !imovel.numero">{{ imovel.rua }}</span>
                  <span v-else-if="!imovel.rua && imovel.numero">{{ imovel.numero }}</span>
                  <span v-if="imovel.bairro">{{ imovel.bairro }}</span>
                  <span> {{ imovel.cidade }} - {{ imovel.estado }} </span>
                </td>

                <td class="precoCell">
                  <div v-if="imovel.comercio.includes('Venda')">
                    <p><strong>Venda:</strong>
                      <br>
                      {{ imovel.valorVenda && imovel.valorVenda !== 'Consulte' ?
                        formatarValor(imovel.valorVenda) : 'Consulte' }}
                    </p>
                  </div>
                  <div v-if="imovel.comercio.includes('Aluguel')">
                    <p><strong>Aluguel:</strong>
                      <br>
                      {{ imovel.valorAluguel && imovel.valorAluguel !== 'Consulte' ?
                        formatarValor(imovel.valorAluguel, true) : 'Consulte' }}
                    </p>
                  </div>
                </td>

                <td class="visibilidadeCell">
                  <label class="switch">
                    <input type="checkbox" :checked="imovel.visivel"
                      @change="toggleVisibilidade(imovel.id_imovel, $event.target.checked)">
                    <span class="slider"></span>
                  </label>
                </td>

                <td class="destaqueCell">
                  <label class="switch">
                    <input type="checkbox" :checked="imovel.destaque"
                      @change="toggleDestaque(imovel.id_imovel, $event.target.checked)">
                    <span class="slider"></span>
                  </label>
                </td>

                <td class="acao">
                  <div class="icon-container">
                    <button class="editar" :id="'editar-' + imovel.id_imovel"
                      @click="buscarDadosImovel(imovel.id_imovel)"></button>
                    <button class="excluir" :id="'excluir-' + imovel.id_imovel"
                      @click="excluirImovel(imovel.id_imovel)"></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pagination">
          <div class="custom-select">
            <button id="btn-page-number" class="page-number" @click="toggleDropdown('pagination-content')">{{
              itemsPerPage }}</button>
            <div id="pagination-content" class="paginationDropdown-content">
              <button @click="changeItemsPerPage(20)">20</button>
              <button @click="changeItemsPerPage(50)">50</button>
              <button @click="changeItemsPerPage(100)">100</button>
            </div>
          </div>
          <div class="btn-pagination">
            <button class="btn-navigation" @click="previousPage" :disabled="currentPage === 1">&#60;</button>
            <span id="currentPageIndicator">{{ currentPage }}</span>
            <button class="btn-navigation" @click="nextPage" :disabled="currentPage >= totalPages">&#62;</button>
          </div>
        </div>
      </div>
    </section>
    <Popup ref="popup" />
  </div>
</template>

<script>
import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';
import debounce from 'lodash/debounce';
import defaultImage from '@/mixins/imagens/defaultImage.jpeg';

export default {
  name: 'GestaoImoveis',
  components: {
    Popup
  },
  data() {
    return {
      frontendUrl: process.env.VUE_APP_STORAGE_IMAGENS_URL,
      clearButtonVisible: false,
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 1,
      searchQuery: '',
      searchResultsVisible: false,
      currentSortField: '_id',
      currentSortOrder: 'desc',
      imoveis: [],
      imoveisRenderizados: [],
      groupedResults: {
        'Tipos de Imóvel': new Set(),
        'Ruas': new Set(),
        'Bairros': new Set(),
        'Cidades': new Set(),
        'Estados': new Set(),
        'ID do imóvel': new Set(),
      },
    };
  },
  computed: {
    filteredGroupedResults() {
      return Object.fromEntries(
        Object.entries(this.groupedResults).filter(([key, value]) => value.size > 0)
      );
    }
  },
  methods: {
    async carregarImoveis() {
      try {
        const apiUrl = `${process.env.VUE_APP_BACKEND_URL}/imoveis?page=${this.currentPage}&limit=${this.itemsPerPage}&sortField=${this.currentSortField}&sortOrder=${this.currentSortOrder}&query=${this.searchQuery}&allProperties=true`;

        const response = await api.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          }
        });

        const data = await response.data;
        this.imoveis = data.imoveis || [];
        this.totalPages = Math.ceil((data.totalImoveis || 0) / this.itemsPerPage);
        this.imoveisRenderizados = this.imoveis.map(imovel => ({
          ...imovel,
          imagens: imovel.imagens.length > 0 ? imovel.imagens.slice(0, 10) : [{ filename: 'defaultImage.jpeg', url: defaultImage }]
        }));

      } catch (error) {
        console.error('Erro ao carregar imóveis:', error);
      }
    },
    async toggleVisibilidade(id_imovel, visivel) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/imoveis/visibilidade/${id_imovel}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.getToken()}`
          },
          body: JSON.stringify({ visivel })
        });

        const data = await response.json();
        console.log(`Imóvel ${data.id_imovel} atualizado com sucesso.`);
      } catch (error) {
        console.error('Erro ao atualizar a visibilidade do imóvel:', error);
      }
    },
    async toggleDestaque(id_imovel, destaque) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/imoveis/destaque/${id_imovel}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.getToken()}`
          },
          body: JSON.stringify({ destaque })
        });

        const data = await response.json();
        console.log(`Imóvel ${data.id_imovel} atualizado com sucesso.`);
      } catch (error) {
        console.error('Erro ao atualizar o destaque do imóvel:', error);
      }
    },
    async buscarDadosImovel(id) {
      try {
        const response = await api.get(`${process.env.VUE_APP_BACKEND_URL}/imoveis/${id}`, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          }
        });

        const imovel = await response.data;
        this.$router.push({ name: 'EditarImovel', params: { id: imovel.id_imovel } });
      } catch (error) {
        console.error('Erro ao buscar o imóvel para edição:', error);
        await this.$refs.popup.showAlert('Erro ao buscar o imóvel para edição: ' + error.response.data);
      }
    },
    async excluirImovel(id) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/imoveis/${id}/verificar-chaves`, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          }
        });

        const data = await response.json();

        if (data.temChaves && data.chaveId) {
          await this.$refs.popup.showAlertConfirm('Imóvel não pode ser excluído. \n Existe uma chave retirada associada a este imóvel.', async () => {
            this.$router.push(`/admin/chaves/${data.chaveId}`);
          });
          return;
        }

        await this.$refs.popup.showConfirm(`Tem certeza que deseja excluir o imóvel ${id}?`, async (confirmed) => {
          if (confirmed) {
            try {
              await api.delete(`${process.env.VUE_APP_BACKEND_URL}/imoveis/${id}`, {
                headers: {
                  'Authorization': `Bearer ${this.getToken()}`
                }
              });

              await this.carregarImoveis();
              await this.$refs.popup.showAlert('Imóvel excluído com sucesso!');
            } catch (error) {
              console.error('Erro ao excluir imóvel:', error);
              await this.$refs.popup.showAlert('Erro ao excluir imóvel: ' + error.response.data);
            }
          }
        });
      } catch (error) {
        console.error('Erro ao verificar chaves associadas ao imóvel:', error);
        await this.$refs.popup.showAlert('Erro ao verificar chaves associadas ao imóvel.');
      }
    },
    async searchImoveis() {
      if (this.searchQuery.length > 0) {
        this.toggleClearButton();
        this.searchResultsVisible = true;
        await this.displaySearchResults(this.searchQuery);
      } else {
        this.clearSearchBar();
      }
    },
    async displaySearchResults(query) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/imoveis?query=${query}&allProperties=true`, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          },
        });

        const data = await response.json();
        const results = data.imoveis || [];

        const newGroupedResults = {
          'Tipos de Imóvel': new Set(),
          'Ruas': new Set(),
          'Bairros': new Set(),
          'Cidades': new Set(),
          'Estados': new Set(),
          'ID do imóvel': new Set(),
        };

        const normalizedQuery = this.removeAccents(query.toLowerCase());

        results.forEach(imovel => {
          if (this.removeAccents(imovel.categoria.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Tipos de Imóvel'].add(imovel.categoria);
          if (this.removeAccents(imovel.rua.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Ruas'].add(imovel.rua);
          if (this.removeAccents(imovel.bairro.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Bairros'].add(imovel.bairro);
          if (this.removeAccents(imovel.cidade.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Cidades'].add(imovel.cidade);
          if (this.removeAccents(imovel.estado.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Estados'].add(imovel.estado);
          if (this.removeAccents(imovel.id_imovel.toLowerCase()).includes(normalizedQuery)) newGroupedResults['ID do imóvel'].add(imovel.id_imovel);
        });

        this.groupedResults = newGroupedResults;
      } catch (error) {
        console.error('Erro ao buscar imóveis:', error);
        this.groupedResults = {
          'Tipos de Imóvel': new Set(),
          'Ruas': new Set(),
          'Bairros': new Set(),
          'Cidades': new Set(),
          'Estados': new Set(),
          'ID do imóvel': new Set(),
        };
      }
    },
    toggleClearButton() {
      this.clearButtonVisible = this.searchQuery.length > 0;
    },
    clearSearchBar() {
      this.searchQuery = '';
      this.clearButtonVisible = false;
      this.searchResultsVisible = false;
      this.carregarImoveis();
    },
    async applySearch() {
      await this.carregarImoveis();
      this.searchResultsVisible = false;
    },
    async changeOrder(field) {
      if (this.currentSortField === field) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSortField = field;
        this.currentSortOrder = 'asc';
      }
      await this.carregarImoveis(this.currentSortField, this.currentSortOrder);
    },
    async changeItemsPerPage(items) {
      this.itemsPerPage = items;
      this.currentPage = 1;
      await this.carregarImoveis();
      this.toggleDropdown('pagination-content');
    },
    async nextPage() {
      if (this.imoveisRenderizados.length >= this.itemsPerPage) {
        this.currentPage += 1;
        await this.carregarImoveis();
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        await this.carregarImoveis();
      }
    },
    toggleDropdown(idElement) {
      const options = document.getElementById(idElement);
      if (options.classList.contains('show')) {
        options.classList.remove('show');
      } else {
        options.classList.add('show');
      }
    },
    selectSuggestion(suggestion) {
      this.searchQuery = suggestion;
      this.applySearch();
    },
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    getToken() {
      return localStorage.getItem('token');
    },
    async cadastrarNovoImovel() {
      window.location.href = '/admin/imoveis/cadastrar';
    },
    getImageUrl(imovel) {
      return imovel.imagens.length > 0 ? imovel.imagens[0].url : defaultImage;
    },
    formatarValor(valor) {
      return valor ? `R$ ${valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : '';
    },
    formatComercio(comercio) {
      return comercio.join('/');
    }
  },
  mounted() {
    this.carregarImoveis();
  },
};
</script>

<style scoped>
@import '@/01-admin/assets/css/gestaoImoveis.css';
</style>