<template>
  <header class="header" data-header>
    <div class="container-header">
      <a href="/" class="logo">
        <img :src="configuracoes.logo.url" alt="Imobiliaria logo" />
      </a>

      <button class="menu-toggle-btn" @click="toggleMenu">
        <ion-icon :name="menuIcon"></ion-icon>
      </button>
    </div>

    <nav class="navbar" :class="{ expanded: isMenuOpen }">
      <ul class="navbar-list">
        <li>
          <div class="dropdown-header" @click="toggleDropHeader" @mouseover="showDropHeader" @mouseout="hideDropHeader">
            <a class="drop-link">Comprar<span class="icon"></span></a>
            <div id="drop-comprar" class="dropdown-header-content">
              <button @click="handleNavClick('Venda')">Todos imóveis</button>
              <button @click="handleNavClick('Venda', 'Casa')">Casas</button>
              <button @click="handleNavClick('Venda', 'Apartamento')">Apartamentos</button>
              <button @click="handleNavClick('Venda', 'Kitnet')">Kitnets</button>
              <button @click="handleNavClick('Venda', 'Terreno')">Terrenos</button>
              <button @click="handleNavClick('Venda', 'Sitio&Chacara')">Sítios/Chácaras</button>
              <button @click="handleNavClick('Venda', 'Comercial')">Comércios</button>
            </div>
          </div>
        </li>
        <li>
          <div class="dropdown-header" @click="toggleDropHeader" @mouseover="showDropHeader" @mouseout="hideDropHeader">
            <a class="drop-link">Alugar<span class="icon"></span></a>
            <div id="drop-alugar" class="dropdown-header-content">
              <button @click="handleNavClick('Aluguel')">Todos imóveis</button>
              <button @click="handleNavClick('Aluguel', 'Casa')">Casas</button>
              <button @click="handleNavClick('Aluguel', 'Apartamento')">Apartamentos</button>
              <button @click="handleNavClick('Aluguel', 'Kitnet')">Kitnets</button>
              <button @click="handleNavClick('Aluguel', 'Terreno')">Terrenos</button>
              <button @click="handleNavClick('Aluguel', 'Sitio&Chacara')">Sítios/Chácaras</button>
              <button @click="handleNavClick('Aluguel', 'Comercial')">Comércios</button>
            </div>
          </div>
        </li>
        <li>
          <router-link to="/cadastroimovel" class="navbar-link" @click.native="closeMenu">Cadastre seu
            imóvel</router-link>
        </li>
        <li>
          <router-link to="/sobre" class="navbar-link" @click.native="closeMenu">Sobre a imobiliária</router-link>
        </li>
        <li>
          <router-link to="/contato" class="navbar-link" @click.native="closeMenu">Contato</router-link>
        </li>
      </ul>

      <a v-if="configuracoes.nome === 'UNIDAS'" href="https://adm072487.superlogica.net/clients/areadocliente"
        class="btn-area-locatario" target="_blank" rel="noopener noreferrer">
        <div class="btn_text">Área do locatário</div>
      </a>
    </nav>
  </header>
</template>

<script>
import script from '@/mixins/script.js';

export default {
  name: 'Header',
  mixins: [script],
  data() {
    return {
      isMenuOpen: false,
      menuIcon: 'menu-outline',
      configuracoes: {
        logo: '',
        nome: ''
      }
    };
  },
  async mounted() {
    this.configuracoes = await this.carregarConfiguracoes();
  },
  watch: {
    '$route.query': {
      handler() {
        this.isMenuOpen = false;
        this.menuIcon = 'menu-outline';
        this.closeMenu();
      },
      immediate: true
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.menuIcon = this.isMenuOpen ? 'close-outline' : 'menu-outline';
      if (this.isMenuOpen) {
        document.body.classList.add('no-scroll');
      } else {
        this.closeMenu();
      }
    },

    closeMenu() {
      this.isMenuOpen = false;
      this.menuIcon = 'menu-outline';
      document.body.classList.remove('no-scroll');
    },

    toggleDropHeader(event) {
      if (window.innerWidth <= 900) {
        const dropdownHeader = event.currentTarget;
        const dropdownContent = dropdownHeader.querySelector('.dropdown-header-content');
        dropdownContent.classList.toggle('show');
      }
    },

    showDropHeader(event) {
      if (window.innerWidth > 900) {
        const dropdownHeader = event.currentTarget;
        const dropdownContent = dropdownHeader.querySelector('.dropdown-header-content');
        clearTimeout(dropdownContent.getAttribute("data-timer"));
        dropdownContent.classList.add("show");
      }
    },

    hideDropHeader(event) {
      if (window.innerWidth > 900) {
        const dropdownHeader = event.currentTarget;
        const dropdownContent = dropdownHeader.querySelector('.dropdown-header-content');
        const timer = setTimeout(function () {
          dropdownContent.classList.remove("show");
        }, 100);
        dropdownContent.setAttribute("data-timer", timer);
      }
    },

    handleNavClick(comercio, categoria = '') {
      this.navBarSearchImoveis(comercio, categoria);
      this.closeMenu();
    },

    navBarSearchImoveis(comercio, categoria = '') {
      try {
        let routeName;
        let params = {};

        if (comercio && categoria) {
          routeName = 'ImoveisComercioCategoria';
          params = { comercio: comercio, categoria: categoria };
        } else if (comercio) {
          routeName = 'ImoveisComercio';
          params = { comercio: comercio };
        } else if (categoria) {
          routeName = 'ImoveisCategoria';
          params = { categoria: categoria };
        } else {
          routeName = 'Imoveis';
        }

        this.$router.push({ name: routeName, params: params });
      } catch (error) {
        console.error(`Erro ao buscar imóveis por ${comercio} ${categoria}:`, error);
      }
    }
  }
}
</script>

<style scoped>
@import '@/00-website/assets/css/header.css';
</style>
