<template>
  <div class="gestao-usuarios-component">
    <section class="gestaoUsuarios">
      <div class="container">
        <h2>Gestão de usuários</h2>
        <div class="header-search">
          <div class="search-container">
            <input type="text" class="search-bar" placeholder="Pesquisar" v-model="searchQuery" @input="searchUsuarios"
              @keypress.enter="applySearch">
            <div class="clear-button-container" v-show="clearButtonVisible" @click="clearSearchBar">
              <button class="clear-button">&times;</button>
            </div>
            <button class="search-button" @click="applySearch">
              <img src="@/00-website/assets/imagens/lupa.png" alt="Buscar">
            </button>
          </div>
          <div id="searchResults" class="search-results" v-show="searchResultsVisible">
            <div v-for="(group, groupName) in filteredGroupedResults" :key="groupName">
              <div><strong>{{ groupName }}</strong></div>
              <div v-for="item in group" :key="item" class="selectable-option" @click="selectSuggestion(item)">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="button-header">
            <button id="new" @click="cadastrarNovoUsuario">Cadastrar novo usuário</button>
          </div>
        </div>

        <div class="divTable">
          <table>
            <thead>
              <tr>
                <th @click="changeOrder('username')">
                  <div class="username">Usuário<span class="icon"
                      :class="{ rotate: currentSortField === 'username' && currentSortOrder === 'asc' }"></span></div>
                </th>
                <th @click="changeOrder('email')">
                  <div class="email">E-mail<span class="icon"
                      :class="{ rotate: currentSortField === 'email' && currentSortOrder === 'asc' }"></span></div>
                </th>
                <th @click="changeOrder('role')">
                  <div class="tipo_usuario">Perfil<span class="icon"
                      :class="{ rotate: currentSortField === 'role' && currentSortOrder === 'asc' }"></span>
                  </div>
                </th>
                <th class="acao">Editar/Excluir</th>
              </tr>
            </thead>
            <tbody id="usuariosTableBody">
              <tr v-for="usuario in usuarios" :key="usuario._id">
                <td>{{ usuario.username }}</td>
                <td>{{ usuario.email }}</td>
                <td>{{ formatRole(usuario.role) }}</td>

                <td class="acao">
                  <button class="editar" :id="'editar-' + usuario.username"
                    @click="editarUsuario(usuario._id)"></button>
                  <button class="excluir" :id="'excluir-' + usuario.username"
                    @click="excluirUsuario(usuario._id)"></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pagination">
          <div class="custom-select">
            <button id="btn-page-number" class="page-number" @click="toggleDropdown('pagination-content')">{{
              itemsPerPage }}</button>
            <div id="pagination-content" class="paginationDropdown-content">
              <button @click="changeItemsPerPage(20)">20</button>
              <button @click="changeItemsPerPage(50)">50</button>
              <button @click="changeItemsPerPage(100)">100</button>
            </div>
          </div>
          <div class="btn-pagination">
            <button class="btn-navigation" @click="previousPage" :disabled="currentPage === 1">&#60;</button>
            <span id="currentPageIndicator">{{ currentPage }}</span>
            <button class="btn-navigation" @click="nextPage" :disabled="currentPage >= totalPages">&#62;</button>
          </div>
        </div>
      </div>
    </section>
    <Popup ref="popup" />
  </div>
</template>

<script>
import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';
import usuario from '../../../../backend/models/usuario';

export default {
  name: 'GestaoUsuarios',
  components: {
    Popup
  },
  data() {
    return {
      clearButtonVisible: false,
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 1,
      searchQuery: '',
      searchResultsVisible: false,
      currentSortField: '',
      currentSortOrder: '',
      usuarios: [],
      groupedResults: {
        'Usuários': new Set(),
        'E-mails': new Set(),
        'Perfis': new Set()
      },
    };
  },
  computed: {
    filteredGroupedResults() {
      return Object.fromEntries(
        Object.entries(this.groupedResults).filter(([key, value]) => value.size > 0)
      );
    }
  },
  methods: {
    async carregarUsuarios() {
      try {
        const sortFieldQuery = this.currentSortField ? `&sortField=${this.currentSortField}` : '';
        const sortOrderQuery = this.currentSortOrder ? `&sortOrder=${this.currentSortOrder}` : '';
        const apiUrl = `${process.env.VUE_APP_BACKEND_URL}/usuarios?page=${this.currentPage}&limit=${this.itemsPerPage}${sortFieldQuery}${sortOrderQuery}&query=${this.searchQuery}`;

        const response = await api.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          }
        });

        const data = await response.data;
        this.usuarios = data.usuarios || [];

      } catch (error) {
        console.error('Erro ao carregar usuários:', error);
      }
    },
    async editarUsuario(id) {
      this.$router.push({ name: 'EditarUsuarios', params: { id: id } });
    },

    async excluirUsuario(id) {
      const nomeUsuario = this.usuarios.find((usuario) => usuario._id === id).username
      await this.$refs.popup.showConfirm(`Tem certeza que deseja excluir o usuário ${nomeUsuario}?`, async (confirmed) => {
        if (confirmed) {
          try {
            await api.delete(`${process.env.VUE_APP_BACKEND_URL}/usuarios/${id}`, {
              headers: {
                'Authorization': `Bearer ${this.getToken()}`
              }
            });

            await this.carregarUsuarios();
            await this.$refs.popup.showAlert('Usuário excluído com sucesso!');
          } catch (error) {
            console.error('Erro ao excluir usuário:', error);
            await this.$refs.popup.showAlert('Erro ao excluir usuário: ' + error.response.data);
          }
        }
      });
    },
    async searchUsuarios() {
      if (this.searchQuery.length > 0) {
        this.toggleClearButton();
        this.searchResultsVisible = true;
        await this.displaySearchResults(this.searchQuery);
      } else {
        this.clearSearchBar();
      }
    },
    async displaySearchResults(query) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/usuarios?query=${query}`, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          }
        });

        const data = await response.json();
        const results = data.usuarios || [];

        const newGroupedResults = {
          'Usuários': new Set(),
          'E-mails': new Set(),
          'Perfis': new Set()
        };

        const normalizedQuery = this.removeAccents(query.toLowerCase());

        results.forEach(usuario => {
          if (this.removeAccents(usuario.username.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Usuários'].add(usuario.username);
          if (this.removeAccents(usuario.email.toLowerCase()).includes(normalizedQuery)) newGroupedResults['E-mails'].add(usuario.email);

          if (usuario.role) {
            const normalizedRoles = Object.values(usuario.role).map(roleValue =>
              this.removeAccents(roleValue.toLowerCase())
            );

            if (normalizedRoles.includes(this.removeAccents('administrador').toLowerCase())) {
              newGroupedResults['Perfis'].add('administrador');
            }

            if (normalizedRoles.includes(this.removeAccents('assistente').toLowerCase())) {
              newGroupedResults['Perfis'].add('assistente');
            }

            if (normalizedRoles.includes(this.removeAccents('corretor').toLowerCase())) {
              newGroupedResults['Perfis'].add('corretor');
            }
          }
        });

        this.groupedResults = newGroupedResults;
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        this.groupedResults = {
          'Usuários': new Set(),
          'E-mails': new Set(),
          'Perfis': new Set()
        };
      }
    },
    toggleClearButton() {
      this.clearButtonVisible = this.searchQuery.length > 0;
    },
    clearSearchBar() {
      this.searchQuery = '';
      this.clearButtonVisible = false;
      this.searchResultsVisible = false;
      this.carregarUsuarios();
    },
    async applySearch() {
      await this.carregarUsuarios();
      this.searchResultsVisible = false;
    },
    async changeOrder(field) {
      if (this.currentSortField === field) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSortField = field;
        this.currentSortOrder = 'asc';
      }
      await this.carregarUsuarios();
    },
    async changeItemsPerPage(items) {
      this.itemsPerPage = items;
      this.currentPage = 1;
      await this.carregarUsuarios();
      this.toggleDropdown('pagination-content');
    },
    async nextPage() {
      if (this.usuarios.length >= this.itemsPerPage) {
        this.currentPage += 1;
        await this.carregarUsuarios();
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        await this.carregarUsuarios();
      }
    },
    toggleDropdown(idElement) {
      const options = document.getElementById(idElement);
      if (options.classList.contains('show')) {
        options.classList.remove('show');
      } else {
        options.classList.add('show');
      }
    },
    selectSuggestion(suggestion) {
      this.searchQuery = suggestion;
      this.applySearch();
    },
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    formatRole(role) {
      return role.join('/');
    },
    getToken() {
      return localStorage.getItem('token');
    },
    async cadastrarNovoUsuario() {
      this.$router.push('/admin/usuarios/cadastrar');
    },
  },
  mounted() {
    this.carregarUsuarios();
  },
};
</script>

<style scoped>
@import '@/01-admin/assets/css/gestaoUsuarios.css';
</style>
