import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
});

api.interceptors.request.use((config) => {
    window.dispatchEvent(new CustomEvent('spinner', { detail: true }));
    return config;
}, (error) => {
    window.dispatchEvent(new CustomEvent('spinner', { detail: false }));
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {
    window.dispatchEvent(new CustomEvent('spinner', { detail: false }));
    return response;
}, (error) => {
    window.dispatchEvent(new CustomEvent('spinner', { detail: false }));
    return Promise.reject(error);
});

export default api;
