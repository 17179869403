<template>
  <footer>
    <div class="footer-top">
      <div class="container">
        <div class="footer-brand">
          <a href="/" class="logoFooter">
            <img :src="configuracoes.logoRodape.url" alt="Footer logo" />
          </a>

          <p class="footer-text">{{ configuracoes.endereco.rua }}, nº{{ configuracoes.endereco.numero }}</p>
          <p class="footer-text">{{ configuracoes.endereco.bairro }} - {{ configuracoes.endereco.cidade }} / {{
            configuracoes.endereco.estado }}</p>
          <p class="footer-text">Creci: {{ configuracoes.creci }}</p>

          <ul class="social-list">
            <li>
              <a :href="`mailto:${configuracoes.email}`" target="_blank" class="social-link">
                <ion-icon name="mail-outline"></ion-icon>
              </a>
            </li>

            <li>
              <a :href="configuracoes.linkFacebook" target="_blank" class="social-link">
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
            </li>

            <li>
              <a :href="configuracoes.linkInstagram" target="_blank" class="social-link">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>

            <li>
              <a :href="`https://wa.me/${configuracoes.whatsapp}`" target="_blank" class="social-link">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>
            </li>
          </ul>
        </div>

        <div class="footer-link-box">
          <ul class="footer-list">
            <li>
              <p class="footer-item-title">SEÇÕES</p>
            </li>

            <li>
              <a href="/" class="footer-link">Home</a>
            </li>

            <li>
              <a href="/#destaques-section" class="footer-link">Imóveis em destaque</a>
            </li>

            <li>
              <a href="/#acessados-section" class="footer-link">Imóveis mais buscados</a>
            </li>

            <li>
              <a href="/#categorias-section" class="footer-link" v-if="configuracoes.exibirCategorias">Imóveis por
                categoria</a>
            </li>
          </ul>

          <ul class="footer-list">
            <li>
              <p class="footer-item-title">IMÓVEIS</p>
            </li>

            <li>
              <a href="/imoveis/Venda" class="footer-link">Para comprar</a>
            </li>

            <li>
              <a href="/imoveis/Aluguel" class="footer-link">Para alugar</a>
            </li>
          </ul>

          <ul class="footer-list">
            <li>
              <p class="footer-item-title">INFORMAÇÕES</p>
            </li>

            <li>
              <a href="/cadastroimovel" class="footer-link">Cadastre seu imóvel</a>
            </li>

            <li>
              <a href="/sobre" class="footer-link">Sobre a Imobiliária</a>
            </li>

            <li>
              <a href="/contato" class="footer-link">Fale conosco</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="container">
        <p class="copyright">
          <a href="https://imoflow.app.br/" target="_blank">&copy; 2024 ImoFlow -
            Plataforma Imobiliária </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import script from '@/mixins/script.js';

export default {
  name: 'Footer',
  mixins: [script],
  data() {
    return {
      configuracoes: {
        logoRodape: '',
        endereco: {
          rua: '',
          numero: '',
          bairro: '',
          cidade: '',
          estado: ''
        },
        creci: '',
        linkInstagram: '',
        linkFacebook: '',
        whatsapp: '',
        email: '',
        exibirCategorias: false
      }
    };
  },
  async mounted() {
    this.configuracoes = await this.carregarConfiguracoes();
  }
}
</script>

<style scoped>
@import '@/00-website/assets/css/footer.css';
</style>
