<template>
  <div>
    <header class="header" data-header>
      <div class="containerHeader">
        <router-link to="/admin" class="logo">
          <img :src="configuracoes.logo.url" alt="Login logo" />
        </router-link>
        <button class="menu-toggle-btn" data-nav-toggle-btn></button>
      </div>
    </header>
    <section class="loginSection" id="loginSection">
      <form v-if="!forgotPasswordMode" class="login-form" id="loginForm" @submit.prevent="fazerLogin">
        <h2 class="login-title">Iniciar sessão</h2>
        <div class="form-group">
          <label for="login">Nome de usuário ou e-mail</label>
          <input type="text" id="login" name="login" required placeholder="Digite seu nome de usuário ou e-mail"
            v-model="login">
          <div class="error-message-input" id="error-message-login" v-if="errors.login">{{ errors.login }}</div>
        </div>
        <div class="form-group">
          <label for="password">Senha</label>
          <input type="password" id="passwordLogin" name="password" required placeholder="Digite sua senha"
            v-model="password">
          <div class="error-message-input" id="error-message-senha" v-if="errors.password">{{ errors.password }}</div>
        </div>
        <div class="form-actions">
          <p class="forgot-password" @click="toggleForgotPassword">Esqueceu sua senha?</p>
          <button type="submit" class="login-button" :disabled="!isFormValidLogin">Entrar</button>
          <div class="error-message-input" id="error-message-all" v-if="errors.all">{{ errors.all }}</div>
        </div>
      </form>

      <!-- Formulário de recuperação de senha -->
      <form v-else class="login-form" id="forgotPasswordForm">
        <h2 class="login-title">Recuperar senha</h2>
        <div v-if="resetPasswordStep === 0" class="form-group">
          <label for="email">E-mail</label>
          <input type="email" id="email" v-model="email" required placeholder="Digite seu e-mail">
          <div class="error-message-input" v-if="errors.email">{{ errors.email }}</div>
          <div id="form-buttons">
            <button @click.prevent="toggleForgotPassword" class="cancel-button">Cancelar</button>
            <button @click.prevent="requestPasswordReset" class="confirm-button">Enviar código</button>
          </div>
        </div>
        <div v-else-if="resetPasswordStep === 1" class="form-group">
          <label for="verificationCode">Código de verificação</label>
          <input type="text" id="verificationCode" v-model="verificationCode" required
            placeholder="Digite o código recebido">
          <div class="error-message-input" v-if="errors.verificationCode">{{ errors.verificationCode }}</div>
          <div id="form-buttons">
            <button @click.prevent="toggleForgotPassword" class="cancel-button">Cancelar</button>
            <button @click.prevent="verifyCode" class="confirm-button">Verificar código</button>
          </div>
        </div>
        <div v-else-if="resetPasswordStep === 2" class="form-group">
          <label for="newPassword">Nova senha</label>
          <input type="password" id="newPassword" v-model="newPassword" required placeholder="Digite sua nova senha">
          <label for="confirmNewPassword">Confirme a nova senha</label>
          <input type="password" id="confirmNewPassword" v-model="confirmNewPassword" required
            placeholder="Confirme sua nova senha">
          <div class="error-message-input" v-if="errors.newPassword">{{ errors.newPassword }}</div>
          <div class="error-message-input" v-if="errors.confirmNewPassword">{{ errors.confirmNewPassword }}</div>
          <div id="form-buttons">
            <button @click.prevent="toggleForgotPassword" class="cancel-button">Cancelar</button>
            <button @click.prevent="resetPassword" class="confirm-button"
              :disabled="!isFormValidResetPassword">Redefinir
              senha</button>
          </div>
        </div>
      </form>
    </section>
    <Popup ref="popup" />
  </div>
</template>

<script>
import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';
import script from '@/mixins/script.js';

export default {
  name: 'Login',
  components: {
    Popup
  },
  mixins: [script],
  data() {
    return {
      forgotPasswordMode: false,
      resetPasswordStep: 0,
      email: '',
      login: '',
      password: '',
      verificationCode: '',
      newPassword: '',
      confirmNewPassword: '',
      configuracoes: {
        logo: ''
      },
      errors: {
        login: '',
        password: '',
        all: '',
        email: '',
        verificationCode: '',
        newPassword: '',
        confirmNewPassword: ''
      }
    };
  },
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  async mounted() {
    this.configuracoes = await this.carregarConfiguracoes();

    if (this.token) {
      this.forgotPasswordMode = true;
      this.resetPasswordStep = 2;

      const params = new URLSearchParams(window.location.search);
      this.email = params.get('email') || '';
      this.verificationCode = this.token;
    }
  },
  methods: {
    async fazerLogin() {
      this.limparRespostas();

      try {
        const response = await api.post(`${process.env.VUE_APP_BACKEND_URL}/login`, {
          login: this.login,
          senha: this.password
        });

        const data = await response.data;
        localStorage.setItem('token', data.token);
        localStorage.setItem('nome', data.nome);
        localStorage.setItem('id', data.id);
        localStorage.setItem('documento', data.documento);
        localStorage.setItem('role', data.role);

        this.$router.push('/admin/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.$refs.popup.showAlertConfirm('Sua conta foi bloqueada após múltiplas tentativas de login falhadas.\nUm e-mail foi enviado com instruções para redefinir sua senha.');
        } else {
          this.mostrarErro(error.response.data);
        }
      }
    },
    async requestPasswordReset() {
      this.limparRespostas();

      if (!this.email) {
        this.errors.email = 'E-mail é obrigatório';
        return;
      }

      try {
        await api.post(`${process.env.VUE_APP_BACKEND_URL}/forgot-password`, {
          email: this.email
        });

        this.resetPasswordStep = 1;
        this.$refs.popup.showAlert('Código de verificação enviado para seu e-mail.');
      } catch (error) {
        this.mostrarErro(error.response.data);
      }
    },
    async verifyCode() {
      this.limparRespostas();

      if (!this.verificationCode) {
        this.errors.verificationCode = 'Código de verificação é obrigatório';
        return;
      }

      try {
        const response = await api.post(`${process.env.VUE_APP_BACKEND_URL}/verify-code`, {
          email: this.email,
          code: this.verificationCode
        });

        const data = await response.data;

        this.resetPasswordStep = 2;
        this.$refs.popup.showAlert(data);
      } catch (error) {
        this.mostrarErro(error.response.data);
      }
    },
    async resetPassword() {
      this.limparRespostas();

      if (this.newPassword !== this.confirmNewPassword) {
        this.errors.newPassword = 'As senhas não coincidem.';
        return;
      }

      try {
        const response = await api.post(`${process.env.VUE_APP_BACKEND_URL}/reset-password`, {
          email: this.email,
          code: this.verificationCode,
          senha: this.newPassword
        });

        this.$refs.popup.showAlertConfirm('Senha redefinida com sucesso!', () => {
          this.toggleForgotPassword();
          this.$router.push('/admin/login');
        });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$refs.popup.showAlertConfirm('Link para redefinição de senha expirado.\nSolicite uma nova redefinição de senha.', () => {
            this.$router.push('/admin/login');
          });
        } else {
          this.mostrarErro('Erro inesperado ao redefinir a senha.');
        }
      }
    },
    limparRespostas() {
      this.errors = {
        username: '',
        password: '',
        all: '',
        email: '',
        verificationCode: '',
        newPassword: '',
        confirmNewPassword: ''
      };
    },
    mostrarErro(mensagem) {
      if (mensagem.includes('obrigatórios')) {
        this.errors.all = mensagem;
      } else if (mensagem.includes('Usuário') || mensagem.includes('E-mail')) {
        this.errors.login = mensagem;
      } else if (mensagem.includes('Senha')) {
        this.errors.password = mensagem;
      } else if (mensagem.includes('E-mail')) {
        this.errors.email = mensagem;
      } else if (mensagem.includes('Código')) {
        this.errors.verificationCode = mensagem;
      } else if (mensagem.includes('As senhas não coincidem')) {
        this.errors.newPassword = mensagem;
      } else if (mensagem.includes('Acesso negado')) {
        this.$refs.popup.showAlert('Acesso negado. Apenas administradores, assistentes e corretores podem fazer login.');
      } else {
        console.error('Erro inesperado no login:', mensagem);
      }
    },
    toggleForgotPassword() {
      this.forgotPasswordMode = !this.forgotPasswordMode;
      this.resetPasswordStep = 0;
      this.email = '';
      this.verificationCode = '';
      this.newPassword = '';
      this.confirmNewPassword = '';
    }
  },
  computed: {
    isFormValidLogin() {
      return this.login && this.password;
    },
    isFormValidResetPassword() {
      return this.newPassword && this.confirmNewPassword;
    }
  }
}
</script>

<style scoped>
@import '@/01-admin/assets/css/login.css';
</style>
