<template>
  <section class="mais-acessados-home" id="acessados-section">
    <h2>Imóveis mais buscados</h2>
    <div class="cards-container-home">
      <button class="nav-home acessadosPrev" @click="prevCard">❮</button>
      <div class="cards-home" ref="acessadosContainer">
        <article v-for="(imovel, index) in visibleImoveis" :key="imovel.id_imovel" class="card-home"
          @click="navigateToDetails(imovel.id_imovel)">
          <div class="carrossel-home" @touchstart="handleSwipeStart($event, imovel.id_imovel)"
            @touchmove="handleSwipeMove($event)" @touchend="handleSwipeEnd(imovel.id_imovel)">
            <div class="images-home">
              <img v-for="(image, imgIndex) in imovel.imagens" :src="image.url"
                :alt="`${imovel.id_imovel} - ${imgIndex}`" :class="{ 'active': imgIndex === imovel.currentImageIndex }"
                :key="imgIndex" />
            </div>
            <div class="carrosselPrev-area" @click.stop="showImage(imovel.id_imovel, -1)"></div>
            <button class="carrosselPrev-home" @click.stop="showImage(imovel.id_imovel, -1)">❮</button>
            <div class="carrosselNext-area" @click.stop="showImage(imovel.id_imovel, 1)"></div>
            <button class="carrosselNext-home" @click.stop="showImage(imovel.id_imovel, 1)">❯</button>
            <div class="carrossel-indicators-home">
              <span v-for="(image, imgIndex) in imovel.imagens"
                :class="{ active: imgIndex === imovel.currentImageIndex }" :key="imgIndex" class="indicator"></span>
            </div>
          </div>
          <div class="info-home">
            <div>
              <span id="idImoveisBuscados">{{ imovel.id_imovel }}</span>
            </div>

            <p v-if="imovel.categoria" id="categoriaImovel">{{ imovel.categoria }}</p>
            <p v-if="imovel.bairro || imovel.cidade || imovel.estado"><b>{{ imovel.bairro }}
                <br>
                {{ imovel.cidade }} - {{ imovel.estado }}</b></p>

            <section class="dados-container">
              <div class="dados-group" v-if="imovel.areaTotal || imovel.areaConstruida">
                <img src="@/00-website/assets/imagens/detalhes/wide 1.png">
                <p>{{ formatarArea(imovel.areaTotal || imovel.areaConstruida) }}</p>
              </div>
              <div class="dados-group" v-if="imovel.quartos">
                <img src="@/00-website/assets/imagens/detalhes/bed 1.png">
                <p>{{ imovel.quartos }}</p>
              </div>
              <div class="dados-group" v-if="imovel.banheiros">
                <img src="@/00-website/assets/imagens/detalhes/shower 1.png">
                <p>{{ imovel.banheiros }}</p>
              </div>
              <div class="dados-group" v-if="imovel.garagem">
                <img src="@/00-website/assets/imagens/detalhes/garagem 1.png">
                <p>{{ imovel.garagem }}</p>
              </div>
            </section>

            <div v-if="imovel.comercio.includes('Venda') && imovel.comercio.includes('Aluguel')"
              class="valores valores-duplo">
              <div class="valor venda">
                <p>Venda</p>
                <p>{{ imovel.consultarValorVenda ? 'Consulte' : formatarValor(imovel.valorVenda) }}</p>
              </div>
              <div class="valor aluguel">
                <p>Locação</p>
                <p>{{ imovel.consultarValorAluguel ? 'Consulte' : formatarValor(imovel.valorAluguel, true) }}</p>
              </div>
            </div>

            <div v-else-if="imovel.comercio.includes('Venda')" class="valor venda">
              <p>Venda</p>
              <p>{{ imovel.consultarValorVenda ? 'Consulte' : formatarValor(imovel.valorVenda) }}</p>
            </div>

            <div v-else-if="imovel.comercio.includes('Aluguel')" class="valor aluguel">
              <p>Locação</p>
              <p>{{ imovel.consultarValorAluguel ? 'Consulte' : formatarValor(imovel.valorAluguel, true) }}</p>
            </div>
          </div>
        </article>
      </div>
      <button class="nav-home acessadosNext" @click="nextCard">❯</button>
    </div>
  </section>
</template>

<script>
import api from '@/axios.js';
import script from '@/mixins/script.js';
import defaultImage from '@/mixins/imagens/defaultImage.jpeg';

export default {
  name: 'ImoveisBuscados',
  mixins: [script],
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      frontendUrl: process.env.VUE_APP_STORAGE_IMAGENS_URL,
      imoveisMaisAcessados: [],
      currentIndex: 0,
      itemsPerPage: 3,
      swipeStartX: 0,
      swipeEndX: 0,
      swipeStartY: 0,
      swipeEndY: 0,
    };
  },
  computed: {
    visibleImoveis() {
      const totalItems = this.imoveisMaisAcessados.length;
      const endIndex = (this.currentIndex + this.itemsPerPage) % totalItems;

      if (endIndex > this.currentIndex) {
        return this.imoveisMaisAcessados.slice(this.currentIndex, endIndex);
      } else {
        return [
          ...this.imoveisMaisAcessados.slice(this.currentIndex, totalItems),
          ...this.imoveisMaisAcessados.slice(0, endIndex),
        ];
      }
    },
  },
  methods: {
    adjustItemsPerPage() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 860) {
        this.itemsPerPage = 1;
      } else if (windowWidth <= 1080) {
        this.itemsPerPage = 2;
      } else {
        this.itemsPerPage = 3;
      }
    },
    async carregarImoveisMaisAcessados() {
      try {
        const response = await api.get(`${this.backendUrl}/imoveis?maisAcessados=true`, {
          headers: { 'Content-Type': 'application/json' },
        });
        this.imoveisMaisAcessados = response.data.map(imovel => ({
          ...imovel,
          currentImageIndex: 0,
          imagens: imovel.imagens.length > 0 ? imovel.imagens.slice(0, 10) : [{ filename: 'defaultImage.jpeg', url: defaultImage }]
        }));
      } catch (error) {
        console.error('Erro ao carregar os imóveis mais buscados:', error);
      }
    },
    showImage(imovelId, direction) {
      const imovel = this.imoveisMaisAcessados.find(imovel => imovel.id_imovel === imovelId);
      if (imovel) {
        const totalImages = imovel.imagens.length;
        if (totalImages > 1) {
          imovel.currentImageIndex = (imovel.currentImageIndex + direction + totalImages) % totalImages;
        }
      }
    },
    nextCard() {
      this.currentIndex = (this.currentIndex + 1) % this.imoveisMaisAcessados.length;
    },
    prevCard() {
      this.currentIndex =
        (this.currentIndex - 1 + this.imoveisMaisAcessados.length) %
        this.imoveisMaisAcessados.length;
    },
    handleSwipeStart(event) {
      this.swipeStartX = event.touches[0].clientX;
      this.swipeStartY = event.touches[0].clientY;
    },
    handleSwipeMove(event) {
      this.swipeEndX = event.touches[0].clientX;
      this.swipeEndY = event.touches[0].clientY;
    },
    handleSwipeEnd(imovelId) {
      const swipeDistanceX = this.swipeStartX - this.swipeEndX;
      const swipeDistanceY = this.swipeStartY - this.swipeEndY;
      const swipeThreshold = 50;

      if (Math.abs(swipeDistanceX) > Math.abs(swipeDistanceY)) {
        if (swipeDistanceX > swipeThreshold) {
          this.showImage(imovelId, 1);
        } else if (swipeDistanceX < -swipeThreshold) {
          this.showImage(imovelId, -1);
        }
      }
    },
  },
  mounted() {
    this.carregarImoveisMaisAcessados();
    this.adjustItemsPerPage();

    window.addEventListener('resize', this.adjustItemsPerPage);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustItemsPerPage);
  },
};
</script>