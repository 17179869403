<template>
  <section class="cadastroImovel">
    <div class="container">
      <div class="section-title">Cadastre seu Imóvel</div>
      <p class="section-text">Informe no formulário abaixo seus dados e do imóvel.</p>

      <div class="cadastroImovel-form-container">
        <form action="#" class="cadastroImovel-form" id="email-form" @submit.prevent="sendEmailImovel">
          <div class="half-width">
            <div class="input-wrapper">
              <label for="nome">Nome*</label>
              <input type="text" name="nome" id="nome" v-model="form.nome" required placeholder="Digite seu nome"
                class="input-field" />
            </div>
            <div class="input-wrapper">
              <label for="celular">Celular*</label>
              <input type="celular" name="celular" id="celular" v-model="form.celular"
                @input="form.celular = formatarTelefone($event.target.value)" required
                placeholder="Digite seu número de celular" class="input-field" />
            </div>
            <div class="input-wrapper">
              <label for="email">E-mail</label>
              <input type="email" name="email" id="email" v-model="form.email" placeholder="Digite seu email"
                class="input-field" />
            </div>
          </div>

          <div class="half-width">
            <div class="input-wrapper">
              <label for="categoria">Categoria*</label>
              <select name="categoria" id="categoria" v-model="form.categoria" class="input-field" required>
                <option value="casa">Casa</option>
                <option value="apartamento">Apartamento</option>
                <option value="terreno">Terreno</option>
                <option value="kitnet">Kitnet</option>
                <option value="sitio">Sítio</option>
                <option value="chacara">Chácara</option>
                <option value="comercial">Comercial</option>
                <option value="outros">Outros</option>
              </select>
            </div>

            <div class="input-wrapper">
              <label for="negocio">Negocio*</label>
              <select name="negocio" id="negocio" v-model="form.negocio" class="input-field" required>
                <option value="venda">Venda</option>
                <option value="locacao">Locação</option>
                <option value="outros">Outros</option>
              </select>
            </div>
            <div class="input-wrapper">
              <label for="valor">Valor</label>
              <input type="text" inputmode="numeric" name="valor" id="valor" v-model="form.valor"
                @input="form.valor = formatarValor($event.target.value)" placeholder="R$" class="input-field" />
            </div>
          </div>

          <div class="input-wrapper">
            <label for="descricao">Descrição do Imóvel*</label>
            <textarea name="descricao" id="descricao" v-model="form.descricao" required
              placeholder="Adicione mais detalhes sobre o imóvel." class="input-field"></textarea>
          </div>

          <button id="emailButton" type="submit" class="btn btn-primary">
            <span>Enviar</span>
            <ion-icon name="paper-plane-outline"></ion-icon>
          </button>
        </form>
      </div>

      <div class="cadastroImovel-info">
        <h2>Informações de Contato</h2>
        <div class="social-media">
          <a :href="`mailto:${configuracoes.email}`" target="_blank">
            <ion-icon name="mail-outline"></ion-icon>
          </a>
          <a :href="configuracoes.linkFacebook" target="_blank">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <a :href="configuracoes.linkInstagram" target="_blank">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <a :href="`https://wa.me/${configuracoes.whatsapp}`" target="_blank">
            <ion-icon name="logo-whatsapp"></ion-icon>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/axios.js';
import script from '@/mixins/script.js';

export default {
  name: 'CadastroImovel',
  mixins: [script],
  data() {
    return {
      form: {
        nome: '',
        celular: '',
        email: '',
        categoria: '',
        negocio: '',
        valor: '',
        descricao: '',
      },
      configuracoes: {
        email: '',
        linkFacebook: '',
        linkInstagram: '',
        whatsapp: ''
      }
    };
  },
  methods: {
    async sendEmailImovel() {
      try {
        await api.post(`${process.env.VUE_APP_BACKEND_URL}/send-imovel-email`, this.form);
        alert('Dados enviados com sucesso. Em breve entraremos em contato para mais informações e próximos passos.');
        this.form.nome = '';
        this.form.celular = '';
        this.form.email = '';
        this.form.categoria = '';
        this.form.negocio = '';
        this.form.valor = '';
        this.form.descricao = '';

      } catch (error) {
        console.error('Erro ao cadastrar imóvel:', error);
        alert('Erro ao cadastrar imóvel.');
      }
    },
  },
  async mounted() {
    this.configuracoes = await this.carregarConfiguracoes();
    await this.registerVisit();
  }
};
</script>

<style scoped>
@import '@/00-website/assets/css/cadastroImovel.css';
</style>
