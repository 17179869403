<template>
  <section class="popup" id="custom-popup">
    <div class="popup-container">
      <div class="popup-content">
        <span id="popup-message"></span>
        <div id="popup-buttons"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Popup',
  methods: {
    async showAlert(mensagem, callback = () => { }, autoClose = true, closeAfter = 3000) {
      const popup = document.getElementById('custom-popup');
      const popupMessage = document.getElementById('popup-message');
      const popupButtons = document.getElementById('popup-buttons');

      popupMessage.innerText = mensagem;
      popup.style.display = 'flex';
      popupButtons.innerHTML = '';
      popupButtons.style.justifyContent = 'center';

      const okButton = document.createElement('button');
      okButton.innerText = 'OK';
      okButton.id = 'popup-ok';
      okButton.onclick = async () => {
        clearTimeout(this.autoCloseTimeout);
        await this.fecharPopup();
        if (callback) callback();
      };

      popupButtons.appendChild(okButton);

      if (autoClose) {
        this.autoCloseTimeout = setTimeout(async () => {
          await this.fecharPopup();
          if (callback) callback();
        }, closeAfter);
      }
    },

    async showConfirm(mensagem, callback) {
      const popup = document.getElementById('custom-popup');
      const popupMessage = document.getElementById('popup-message');
      const popupButtons = document.getElementById('popup-buttons');

      popupMessage.innerText = mensagem;
      popup.style.display = 'flex';
      popupButtons.innerHTML = '';
      popupButtons.style.justifyContent = 'space-between';

      const okButton = document.createElement('button');
      okButton.innerText = 'OK';
      okButton.id = 'popup-ok';
      okButton.onclick = async () => {
        await this.fecharPopup();
        if (callback) callback(true);
      };

      const cancelButton = document.createElement('button');
      cancelButton.innerText = 'Cancelar';
      cancelButton.classList.add('cancel');
      cancelButton.onclick = async () => {
        await this.fecharPopup();
        if (callback) callback(false);
      };

      popupButtons.appendChild(cancelButton);
      popupButtons.appendChild(okButton);
    },

    async showAlertConfirm(mensagem, callback) {
      const popup = document.getElementById('custom-popup');
      const popupMessage = document.getElementById('popup-message');
      const popupButtons = document.getElementById('popup-buttons');

      popupMessage.innerText = mensagem;
      popup.style.display = 'flex';
      popupButtons.innerHTML = '';
      popupButtons.style.justifyContent = 'center';

      const okButton = document.createElement('button');
      okButton.innerText = 'OK';
      okButton.id = 'popup-ok';
      okButton.onclick = async () => {
        await this.fecharPopup();
        if (callback) callback();
      };

      popupButtons.appendChild(okButton);
    },

    async fecharPopup() {
      const popup = document.getElementById('custom-popup');
      popup.style.display = 'none';
    }
  }
}
</script>

<style>
.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9000;
}

.popup-container {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

#popup-message {
  margin-bottom: 20px;
  font-size: 16px;
}

#popup-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 40px;
}

#popup-buttons button {
  width: auto;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: white;
  color: black;
  border: 1px solid black;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  white-space: nowrap;
}

#popup-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.103);
  color: black;
  border: 1px solid black;
  transition: background-color 0.3s ease;
}

#popup-buttons #popup-ok {
  background-color: black;
  color: white;
}

#popup-buttons #popup-ok:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
  transition: background-color 0.3s ease;
}

#popup-buttons .continue-editing {
  background-color: white;
  color: black;
  border: 1px solid black;
}

#popup-buttons .continue-editing:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
  border: 1px solid black;
  transition: background-color 0.3s ease;
}

#popup-buttons .confirm-exit {
  background-color: black;
  color: white;
  border: none;
}

#popup-buttons .confirm-exit:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
  transition: background-color 0.3s ease;
}
</style>