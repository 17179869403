import api from '@/axios.js';

export default {
    data() {
        return {
            backendUrl: process.env.VUE_APP_BACKEND_URL,
            frontendUrl: process.env.VUE_APP_STORAGE_IMAGENS_URL,
            timestamp: new Date().getTime(),
        };
    },
    methods: {
        showImage(id_imovel, direction) {
            const carrosselRef = this.$refs[id_imovel];
            const carrossel = carrosselRef[0];
            const images = carrossel.querySelectorAll('img');
            const indicators = carrossel.querySelectorAll('.indicator');
            const currentImageIndex = Array.from(images).findIndex(img => img.classList.contains('active'));
            let newImageIndex = currentImageIndex + direction;

            if (newImageIndex < 0) newImageIndex = images.length - 1;
            if (newImageIndex >= images.length) newImageIndex = 0;

            images[currentImageIndex].classList.remove('active');
            images[newImageIndex].classList.add('active');
            indicators[currentImageIndex].classList.remove('active');
            indicators[newImageIndex].classList.add('active');
        },

        handleSwipeStart(event, id_imovel) {
            this.touchStartX = event.touches[0].clientX;
            this.touchStartY = event.touches[0].clientY;
            this.swipeIdImovel = id_imovel;
        },

        handleSwipeMove(event) {
            if (!this.touchStartX || !this.touchStartY) {
                return;
            }

            this.touchEndX = event.touches[0].clientX;
            this.touchEndY = event.touches[0].clientY;
        },

        handleSwipeEnd() {
            if (!this.touchStartX || !this.touchStartY || !this.touchEndX || !this.touchEndY) {
                return;
            }

            const diffX = this.touchStartX - this.touchEndX;
            const diffY = this.touchStartY - this.touchEndY;

            if (Math.abs(diffX) > Math.abs(diffY)) {
                if (diffX > 0) {
                    this.showImage(this.swipeIdImovel, 1);
                } else {
                    this.showImage(this.swipeIdImovel, -1);
                }
            }
            this.touchStartX = null;
            this.touchStartY = null;
            this.touchEndX = null;
            this.touchEndY = null;
            this.swipeIdImovel = null;
        },
        updateCard(containerRef, currentIndex, itemsPerPage) {
            const container = this.$refs[containerRef];
            const totalItems = container.children.length;

            for (let i = 0; i < totalItems; i++) {
                container.children[i].style.display = i >= currentIndex && i < currentIndex + itemsPerPage ? 'flex' : 'none';
            }
        },
        prevCard(containerRef, currentIndex, itemsPerPage) {
            const container = this.$refs[containerRef];
            const totalItems = container.children.length;

            if (currentIndex > 0) {
                this.currentIndex -= itemsPerPage;
            } else {
                this.currentIndex = totalItems - itemsPerPage;
            }
            this.updateCard(containerRef, this.currentIndex, itemsPerPage);
        },
        nextCard(containerRef, currentIndex, itemsPerPage) {
            const container = this.$refs[containerRef];
            const totalItems = container.children.length;

            if (this.currentIndex < totalItems - itemsPerPage) {
                this.currentIndex += itemsPerPage;
            } else {
                this.currentIndex = 0;
            }
            this.updateCard(containerRef, this.currentIndex, itemsPerPage);
        },
        async navigateToDetails(imovelId, comercio, query = {}) {
            try {
                localStorage.setItem('filtrosImoveis', query);
                if (comercio) {
                    this.$router.push({ name: 'DetalhesImovel', params: { id_imovel: imovelId, comercio: comercio } });
                } else {
                    this.$router.push({ name: 'DetalhesImovel', params: { id_imovel: imovelId } });
                }
            } catch (error) {
                console.error('Erro ao navegar para os detalhes do imóvel:', error);
            }
        },
        async updateRouteQuery(newParams) {
            try {
                this.$router.push({
                    query: newParams
                });

            } catch (error) {
                console.error('Erro ao atualizar parâmetros de rota:', error);
            }
        },
        toggleDropdown(id_drop) {
            const options = document.getElementById(id_drop);
            options.classList.toggle('show');
        },
        formatarValor(valor, aluguel = false, emFocus = false) {
            if (!valor) return '';

            if (typeof valor === 'string') {
                valor = valor.replace(/\D/g, '');
                valor = (valor / 100).toFixed(2);
            }

            let valorFormatado = valor.toString().replace('.', ',');
            valorFormatado = valorFormatado.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

            if (!valorFormatado.includes(',')) {
                valorFormatado += ',00';
            } else {
                const [inteiro, centavos] = valorFormatado.split(',');
                if (centavos.length === 1) {
                    valorFormatado += '0';
                }
            }

            return 'R$ ' + valorFormatado + (aluguel && !emFocus ? '/mês' : '');
        },
        formatarArea(area, emFocus = false) {
            if (!area) return '';
            let areaFormatada = area.toString().replace(/\D/g, '');
            areaFormatada = areaFormatada ? parseInt(areaFormatada) : 0;
            return `${areaFormatada.toLocaleString('pt-BR')}` + (!emFocus ? ' m²' : '');
        },
        formatarDocumento(value) {
            if (!value) return '';
            let docFormatado = value.toString().replace(/\D/g, '').slice(0, 14);
            if (docFormatado.length <= 11) {
                // Máscara de CPF
                docFormatado = docFormatado.replace(/(\d{3})(\d)/, '$1.$2');
                docFormatado = docFormatado.replace(/(\d{3})(\d)/, '$1.$2');
                docFormatado = docFormatado.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
            } else {
                // Máscara de CNPJ
                docFormatado = docFormatado.replace(/^(\d{2})(\d)/, '$1.$2');
                docFormatado = docFormatado.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
                docFormatado = docFormatado.replace(/\.(\d{3})(\d)/, '.$1/$2');
                docFormatado = docFormatado.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
            }
            return docFormatado;
        },
        formatarIdentidade(value) {
            if (!value) return '';
            let identidade = value.toString().replace(/[^\dXx]/gi, '').toUpperCase();
            if (identidade.length <= 9) {
                // Máscara para RG 
                identidade = identidade.slice(0, 9);
                if (identidade.length > 8) {
                    identidade = identidade.replace(/^(\d{2})(\d{3})(\d{3})([\dX])$/, '$1.$2.$3-$4');
                } else if (identidade.length > 5) {
                    identidade = identidade.replace(/^(\d{2})(\d{3})(\d{0,3})$/, '$1.$2.$3');
                } else if (identidade.length > 2) {
                    identidade = identidade.replace(/^(\d{2})(\d{0,3})$/, '$1.$2');
                } else if (identidade.length > 0) {
                    identidade = identidade.replace(/^(\d{0,2})$/, '$1');
                }
            } else {
                // Máscara para CNH 
                identidade = identidade.slice(0, 11);
                if (identidade.length > 9) {
                    identidade = identidade.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
                } else if (identidade.length > 6) {
                    identidade = identidade.replace(/^(\d{3})(\d{3})(\d{0,3})$/, '$1.$2.$3');
                } else if (identidade.length > 3) {
                    identidade = identidade.replace(/^(\d{3})(\d{0,3})$/, '$1.$2');
                } else if (identidade.length > 0) {
                    identidade = identidade.replace(/^(\d{0,3})$/, '$1');
                }
            }
            return identidade;
        },
        formatarTelefone(telefone) {
            if (!telefone) return '';
            telefone = telefone.toString();
            return telefone.replace(/\D/g, '').replace(/^(\d{2})(\d)/g, '($1) $2').replace(/(\d)(\d{4})$/, '$1-$2');
        },
        formatarEstado(estado) {
            if (!estado) return '';
            estado = estado.replace(/[^a-zA-Z]/g, '');
            return estado.toUpperCase().slice(0, 2);
        },
        formatarCEP(cep) {
            if (!cep) return '';
            let cepFormatado = cep.toString().replace(/\D/g, '').slice(0, 8);
            if (cepFormatado.length > 5) {
                cepFormatado = cepFormatado.slice(0, 5) + '-' + cepFormatado.slice(5);
            }
            return cepFormatado;
        },
        async buscarEndereco(cep) {
            cep = cep.toString().replace(/\D/g, '');
            if (cep.length === 8) {
                try {
                    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                    const endereco = await response.json();
                    return endereco;
                } catch (error) {
                    console.error('Erro ao buscar o endereço:', error);
                }
            }
        },
        removeAccents(str) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },
        async registerVisit() {
            const device = /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
            const url = this.$route.href;
            const ip = await fetch('https://api.ipify.org?format=json')
                .then(response => response.json())
                .then(data => data.ip);

            const visitData = { ip, device, url };

            try {
                const response = await fetch(`${this.backendUrl}/visits`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(visitData),
                });
            } catch (error) {
                console.error('Erro ao registrar visita:', error);
            }
        },
        async carregarConfiguracoes() {
            try {
                const response = await api.get(`${this.backendUrl}/configuracoes`);
                return response.data;
            } catch (error) {
                console.error('Erro ao carregar as configurações:', error);
                return null;
            }
        }
    }
};