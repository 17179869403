<template>
  <div class="dashboard-component">
    <section class="dashboard">
      <div class="container">
        <h2>Dashboard</h2>

        <div class="row">
          <div class="chart-container pie-chart">
            <h3>Total de Imóveis à Venda x Locação</h3>
            <div id="chartDiv1" class="chart"></div>
            <div class="custom-legend" v-if="imoveisStatus.length">
              <div v-for="(item, index) in imoveisStatus" :key="item.category" class="legend-item">
                <div :style="{ backgroundColor: chartColors[index] }" class="legend-color"></div>
                <div class="legend-text">
                  {{ item.category }}: {{ item.value }} imóveis
                </div>
              </div>
            </div>
          </div>

          <div class="chart-container pie-chart">
            <h3>Total de Imóveis por Categoria</h3>
            <div id="chartDiv2" class="chart"></div>
            <div class="custom-legend" v-if="imoveisCategorias.length">
              <div v-for="(item, index) in imoveisCategorias" :key="item.category" class="legend-item">
                <div :style="{ backgroundColor: chartColors[index] }" class="legend-color"></div>
                <div class="legend-text">
                  {{ item.category }}: {{ item.value }} imóveis
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="chart-container funnel-chart">
            <h3>Funil de Vendas</h3>
            <div id="chartDiv3" class="chart"></div>
            <div class="custom-legend" v-if="funilVendas.length">
              <div v-for="(item, index) in funilVendas" :key="item.category" class="legend-item">
                <div :style="{ backgroundColor: chartColors[index] }" class="legend-color"></div>
                <div class="legend-text">
                  {{ item.category }}: {{ item.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import api from '@/axios.js';

export default {
  name: 'Dashboard',
  data() {
    return {
      imoveisStatus: [],
      imoveisCategorias: [],
      funilVendas: [
        { category: "Total", value: 26 },
        { category: "Visita", value: 15 },
        { category: "Avaliação", value: 12 },
        { category: "Negociação", value: 7 },
        { category: "Proposta", value: 3 },
        { category: "Finalizado", value: 1 },
      ],
      chartColors: ['#4169E1', '#007e04', '#8B0000', '#2196F3', '#9C27B0', '#069e9e', '#FF8877'],
    };
  },
  methods: {
    async fetchData() {
      try {
        const statusResponse = await api.get(`/imoveis/status`);
        this.imoveisStatus = [
          { category: "Venda", value: statusResponse.data.venda },
          { category: "Aluguel", value: statusResponse.data.aluguel },
        ];

        const categoriasResponse = await api.get(`/imoveis/categorias`);
        this.imoveisCategorias = categoriasResponse.data.map(categoria => ({
          category: categoria._id,
          value: categoria.total
        }));

        this.createCharts();
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },
    createCharts() {
      this.createPieChart('chartDiv1', this.imoveisStatus);
      this.createPieChart('chartDiv2', this.imoveisCategorias);
      this.createFunnelChart('chartDiv3', this.funilVendas);
    },
    createPieChart(elementId, data) {
      const container = document.getElementById(elementId);
      container.innerHTML = "";

      let root = am5.Root.new(elementId);
      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(am5percent.PieChart.new(root, {
        layout: root.verticalLayout
      }));

      let series = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category"
      }));

      series.get("colors").set("colors", this.chartColors.map(color => am5.color(color)));

      series.data.setAll(data);

      series.slices.template.set("tooltipText", "{category}: {value} imóveis ({valuePercentTotal.formatNumber('#.0')}%)");

      series.labels.template.setAll({
        forceHidden: true
      });

      series.ticks.template.setAll({
        forceHidden: true
      });
    },
    createFunnelChart(elementId, data) {
      const container = document.getElementById(elementId);
      container.innerHTML = "";

      let root = am5.Root.new(elementId);
      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(am5percent.SlicedChart.new(root, {
        layout: root.verticalLayout,
        orientation: "vertical"
      }));

      let series = chart.series.push(am5percent.FunnelSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: true
      }));

      series.get("colors").set("colors", this.chartColors.map(color => am5.color(color)));

      series.data.setAll(data);

      series.slices.template.set("tooltipText", "[bold]{category}:[/] {value} ({valuePercentTotal.formatNumber('#.0')}%)");

      series.labels.template.setAll({
        forceHidden: true
      });

      series.ticks.template.setAll({
        forceHidden: true
      });
    },
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped>
@import '@/01-admin/assets/css/dashboard.css';
</style>
