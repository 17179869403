const mongoose = require('mongoose');

const usuarioSchema = new mongoose.Schema({
    nome: {
        type: String,
        required: true
    },
    sobrenome: {
        type: String,
        required: true
    },
    username: {
        type: String,
        required: true,
        unique: true
    },
    email: {
        type: String,
        required: true,
        unique: true
    },
    documento: {
        type: Number,
        required: true,
        unique: true
    },
    creci: {
        type: String
    },
    senha: {
        type: String,
        required: true
    },
    role: {
        type: [String],
        enum: ['administrador', 'assistente', 'corretor'],
        required: true
    },
    resetPasswordToken: {
        type: String
    },
    resetPasswordExpires: {
        type: Date
    },
    loginAttempts: {
        type: Number,
        default: 0
    },
    lockUntil: {
        type: Date
    },
    lastLoginAttempt: Date
});
usuarioSchema.methods.isLocked = function () {
    return !!(this.lockUntil && this.lockUntil > Date.now());
};

module.exports = mongoose.model('Usuario', usuarioSchema);
