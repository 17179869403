<template>
  <div class="chaves-component">
    <section class="chaves">
      <div class="container">
        <h2>Gestão de chaves</h2>
        <div class="header-search">
          <div class="search-container">
            <input type="text" class="search-bar" placeholder="Pesquisar" v-model="searchQuery" @input="searchChaves"
              @keypress.enter="applySearch">
            <div class="clear-button-container" v-show="clearButtonVisible" @click="clearSearchBar">
              <button class="clear-button">&times;</button>
            </div>
            <button class="search-button" @click="applySearch">
              <img src="@/00-website/assets/imagens/lupa.png" alt="Buscar">
            </button>
          </div>
          <div id="searchResults" class="search-results" v-show="searchResultsVisible">
            <div v-for="(group, groupName) in filteredGroupedResults" :key="groupName">
              <div><strong>{{ groupName }}</strong></div>
              <div v-for="item in group" :key="item" class="selectable-option" @click="selectSuggestion(item)">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="button-header">
            <button id="new" @click="cadastrarNovaChave">Retirar chave</button>
          </div>
        </div>

        <div class="divTable">
          <table>
            <thead>
              <tr>
                <th @click="changeOrder('imoveis')">
                  <div class="id_imovel">Imóvel<span class="icon"
                      :class="{ rotate: currentSortField === 'id_imovel' && currentSortOrder === 'asc' }"></span></div>
                </th>
                <th @click="changeOrder('responsavelNomeCompleto')">
                  <div class="responsavel">Responsável<span class="icon"
                      :class="{ rotate: currentSortField === 'responsavel' && currentSortOrder === 'asc' }"></span>
                  </div>
                </th>
                <th @click="changeOrder('motivoRetirada')">
                  <div class="motivoRetirada">Motivo da Retirada<span class="icon"
                      :class="{ rotate: currentSortField === 'motivoRetirada' && currentSortOrder === 'asc' }"></span>
                  </div>
                </th>
                <th @click="changeOrder('retirada')">
                  <div class="retirada">Retirada<span class="icon"
                      :class="{ rotate: currentSortField === 'retirada' && currentSortOrder === 'asc' }"></span>
                  </div>
                </th>
                <th @click="changeOrder('previsaoDevolucao')">
                  <div class="previsaoDevolucao">Devolução<span class="icon"
                      :class="{ rotate: currentSortField === 'previsaoDevolucao' && currentSortOrder === 'asc' }"></span>
                  </div>
                </th>
                <th class="status">Status</th>
                <th class="termo">Termo de Retirada</th>
                <th class="detalhes">Detalhes</th>
              </tr>
            </thead>
            <tbody id="chavesTableBody">
              <tr v-for="chave in chaves" :key="chave._id">
                <td>
                  <ul>
                    <li v-for="imovelObj in chave.imoveis" :key="imovelObj._id">{{ imovelObj.id_imovel }}</li>
                  </ul>
                </td>
                <td v-if="chave.responsavel">{{ chave.responsavel.nome }} {{ chave.responsavel.sobrenome }}</td>
                <td v-else>Responsável não definido</td>
                <td>{{ chave.motivoRetirada }}</td>
                <td v-html="formatarDataHora(chave.retirada)"></td>
                <td v-html="formatarDataHora(chave.previsaoDevolucao)"></td>
                <td class="status">
                  <label class="switch"
                    :class="chave.imoveis.some(imovel => imovel.status === 'retirado') ? 'switch-retirada' : 'switch-devolvida'"
                    @click="abrirModal(chave)">
                    <span class="slider"
                      :class="chave.imoveis.some(imovel => imovel.status === 'retirado') ? 'slider-retirada' : 'slider-devolvida'">
                      {{ chave.imoveis.some(imovel => imovel.status === 'retirado') ? 'Retirada' : 'Devolvida' }}
                    </span>
                  </label>
                </td>
                <td class="termo">
                  <button class="imprimirButton" @click="imprimirTermoRetirada(chave)"
                    :disabled="chave.imoveis.every(imovel => imovel.status === 'devolvido')"
                    :class="{ 'button-disabled': chave.imoveis.every(imovel => imovel.status === 'devolvido') }">
                    Imprimir
                  </button>
                </td>
                <td class="detalhes">
                  <div class="icon-container">
                    <button class="detalheBtn" @click="abrirModal(chave)">
                      <img src="@/00-website/assets/imagens/detalhes.png" alt="Detalhes">
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pagination">
          <div class="custom-select">
            <button id="btn-page-number" class="page-number" @click="toggleDropdown('pagination-content')">{{
              itemsPerPage }}</button>
            <div id="pagination-content" class="paginationDropdown-content">
              <button @click="changeItemsPerPage(20)">20</button>
              <button @click="changeItemsPerPage(50)">50</button>
              <button @click="changeItemsPerPage(100)">100</button>
            </div>
          </div>
          <div class="btn-pagination">
            <button class="btn-navigation" @click="previousPage" :disabled="currentPage === 1">&#60;</button>
            <span id="currentPageIndicator">{{ currentPage }}</span>
            <button class="btn-navigation" @click="nextPage" :disabled="currentPage >= totalPages">&#62;</button>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div v-if="showModal" class="modal-overlay">
        <div class="modal">
          <div class="header-property">
            <h2>Registro de chaves retiradas</h2>
            <div class="close-property" @click="fecharModal">X</div>
          </div>
          <hr>
          <div class="section">
            <div class="grid-container">
              <div class="group-input">
                <h4>Responsável</h4>
                <input type="text" :value="`${selectedChave.responsavel.nome} ${selectedChave.responsavel.sobrenome}`"
                  disabled />
              </div>
              <div class="group-input">
                <h4>Motivo da Retirada</h4>
                <input type="text" v-model="selectedChave.motivoRetirada" disabled />
              </div>
              <div class="group-input">
                <h4>Data e Hora da Retirada</h4>
                <input type="datetime-local" :value="formatarDataHoraLocal(selectedChave.retirada)" disabled />
              </div>
              <div class="group-input">
                <h4>Data e Hora da Devolução</h4>
                <input type="datetime-local" :value="formatarDataHoraLocal(selectedChave.previsaoDevolucao)" disabled />
              </div>
              <div class="group-input">
                <h4>Observações</h4>
                <textarea v-model="selectedChave.observacoes" disabled></textarea>
              </div>
            </div>
          </div>
          <hr>
          <div class="section">
            <div class="section-header">
              <h3>Chaves retiradas</h3>
              <div class="registered-by">
                Registrado por {{ selectedChave.registradoPor }} em {{ formatarDataHora(selectedChave.retirada) }}
              </div>
            </div>
            <div class="imovel-list">
              <div v-for="imovelObj in selectedChave.imoveis" :key="imovelObj._id" class="imovel-status">
                <div class="imovel-info">
                  <span>{{ imovelObj.id_imovel }}</span>
                  <label class="switch"
                    :class="{ 'switch-devolvida': imovelObj.status === 'devolvido', 'switch-retirada': imovelObj.status === 'retirado' }">
                    <span class="slider" @click="toggleModalStatus(imovelObj)"
                      :class="{ 'slider-devolvida': imovelObj.status === 'devolvido', 'slider-retirada': imovelObj.status === 'retirado' }">
                      {{ imovelObj.status === 'retirado' ? 'Retirada' : 'Devolvida' }}
                    </span>
                  </label>
                </div>
                <div v-if="imovelObj.status === 'devolvido'" class="devolucao-info">
                  <span class="statusDevolucao">{{ selectedChave.atualizadoPor }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="delete-button"
              @click="excluirChave(selectedChave._id, selectedChave.imoveis)">Excluir</button>
            <div class="modal-actions">
              <button @click="fecharModal">Cancelar</button>
              <button @click="alterarStatus">Salvar</button>
            </div>
          </div>
        </div>
      </div>

    </section>
    <Popup ref="popup" />
  </div>
</template>

<script>
import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';
import script from '@/mixins/script.js';

export default {
  name: 'GestaoChaves',
  mixins: [script],
  components: {
    Popup
  },
  data() {
    return {
      clearButtonVisible: false,
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 1,
      searchQuery: '',
      searchResultsVisible: false,
      currentSortField: '_id',
      currentSortOrder: 'desc',
      chaves: [],
      groupedResults: {
        'Imóveis': new Set(),
        'Responsáveis': new Set(),
        'Motivos de Retirada': new Set(),
        'Previsões de Devolução': new Set()
      },
      showModal: false,
      selectedChave: null,
      initialImovelStates: null,
    };
  },
  computed: {
    filteredGroupedResults() {
      return Object.fromEntries(
        Object.entries(this.groupedResults).filter(([key, value]) => value.size > 0)
      );
    }
  },
  methods: {
    async carregarChaves() {
      try {
        const sortFieldQuery = this.currentSortField ? `&sortField=${this.currentSortField}` : '';
        const sortOrderQuery = this.currentSortOrder ? `&sortOrder=${this.currentSortOrder}` : '';
        const apiUrl = `${process.env.VUE_APP_BACKEND_URL}/chaves?page=${this.currentPage}&limit=${this.itemsPerPage}${sortFieldQuery}${sortOrderQuery}&query=${this.searchQuery}`;

        const response = await api.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          }
        });

        let data = await response.data.chaves || [];

        data.sort((a, b) => {
          const fieldA = a[this.currentSortField];
          const fieldB = b[this.currentSortField];

          if (this.currentSortOrder === 'asc') {
            return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
          } else {
            return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0;
          }
        });

        const chavesNaoDevolvidas = data.filter(chave => !chave.imoveis.every(imovel => imovel.status === 'devolvido'));
        const chavesDevolvidas = data.filter(chave => chave.imoveis.every(imovel => imovel.status === 'devolvido'));

        chavesDevolvidas.sort((a, b) => {
          const dataDevolucaoA = new Date(Math.max(...a.imoveis.map(imovel => new Date(imovel.dataDevolucao).getTime())));
          const dataDevolucaoB = new Date(Math.max(...b.imoveis.map(imovel => new Date(imovel.dataDevolucao).getTime())));
          return dataDevolucaoB - dataDevolucaoA;
        });

        this.chaves = [...chavesNaoDevolvidas, ...chavesDevolvidas];

      } catch (error) {
        console.error('Erro ao carregar chaves:', error);
      }
    },

    async editarChave(id) {
      this.$router.push({ name: 'EditarChave', params: { id: id } });
    },
    async excluirChave(id) {
      await this.$refs.popup.showConfirm('Tem certeza que deseja excluir este registro?', async (confirmed) => {
        if (confirmed) {
          try {
            await api.delete(`${process.env.VUE_APP_BACKEND_URL}/chaves/${id}`, {
              headers: {
                'Authorization': `Bearer ${this.getToken()}`
              }
            });

            await this.carregarChaves();
            this.fecharModal();
            await this.$refs.popup.showAlert('Registro excluído com sucesso!');
          } catch (error) {
            console.error('Erro ao excluir registro:', error);
            await this.$refs.popup.showAlert('Erro ao excluir registro: ' + error.response.data);
          }
        }
      });
    },
    async searchChaves() {
      if (this.searchQuery.length > 0) {
        this.toggleClearButton();
        this.searchResultsVisible = true;
        await this.displaySearchResults(this.searchQuery);
      } else {
        this.clearSearchBar();
      }
    },
    async displaySearchResults(query) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/chaves?query=${query}`, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          }
        });

        const data = await response.json();
        const results = data.chaves || [];

        const newGroupedResults = {
          'Imóveis': new Set(),
          'Responsáveis': new Set(),
          'Motivos de Retirada': new Set()
        };

        const normalizedQuery = this.removeAccents(query.toLowerCase());

        results.forEach(chave => {
          chave.imoveis.forEach(imovelObj => {
            if (this.removeAccents(imovelObj.id_imovel.toLowerCase()).includes(normalizedQuery)) {
              newGroupedResults['Imóveis'].add(imovelObj.id_imovel);
            }
          });

          const responsavelNomeCompleto = `${chave.responsavel.nome} ${chave.responsavel.sobrenome}`;
          if (this.removeAccents(responsavelNomeCompleto.toLowerCase()).includes(normalizedQuery)) {
            newGroupedResults['Responsáveis'].add(responsavelNomeCompleto);
          }

          if (this.removeAccents(chave.motivoRetirada.toLowerCase()).includes(normalizedQuery)) {
            newGroupedResults['Motivos de Retirada'].add(chave.motivoRetirada);
          }
        });

        this.groupedResults = newGroupedResults;
      } catch (error) {
        console.error('Erro ao buscar chaves:', error);
        this.groupedResults = {
          'Imóveis': new Set(),
          'Responsáveis': new Set(),
          'Motivos de Retirada': new Set()
        };
      }
    },

    toggleClearButton() {
      this.clearButtonVisible = this.searchQuery.length > 0;
    },
    clearSearchBar() {
      this.searchQuery = '';
      this.clearButtonVisible = false;
      this.searchResultsVisible = false;
      this.carregarChaves();
    },
    async applySearch() {
      await this.carregarChaves();
      this.searchResultsVisible = false;
    },
    async changeOrder(field) {
      if (this.currentSortField === field) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSortField = field;
        this.currentSortOrder = 'asc';
      }
      await this.carregarChaves();
    },
    async changeItemsPerPage(items) {
      this.itemsPerPage = items;
      this.currentPage = 1;
      await this.carregarChaves();
      this.toggleDropdown('pagination-content');
    },
    async nextPage() {
      if (this.chaves.length >= this.itemsPerPage) {
        this.currentPage += 1;
        await this.carregarChaves();
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        await this.carregarChaves();
      }
    },
    toggleDropdown(idElement) {
      const options = document.getElementById(idElement);
      if (options.classList.contains('show')) {
        options.classList.remove('show');
      } else {
        options.classList.add('show');
      }
    },
    selectSuggestion(suggestion) {
      this.searchQuery = suggestion;
      this.applySearch();
    },
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    getToken() {
      return localStorage.getItem('token');
    },
    async cadastrarNovaChave() {
      this.$router.push('/admin/chaves/retirar');
    },
    formatarDataHora(dataHora) {
      const data = new Date(dataHora);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      const horas = String(data.getHours()).padStart(2, '0');
      const minutos = String(data.getMinutes()).padStart(2, '0');

      return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
    },
    formatarDataHoraLocal(dataHora) {
      if (!dataHora) return '';

      const data = new Date(dataHora);
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const dia = String(data.getDate()).padStart(2, '0');
      const horas = String(data.getHours()).padStart(2, '0');
      const minutos = String(data.getMinutes()).padStart(2, '0');

      return `${ano}-${mes}-${dia}T${horas}:${minutos}`;
    },
    async abrirModalPorId(id) {
      try {
        const response = await api.get(`${process.env.VUE_APP_BACKEND_URL}/chaves/${id}`, {
          headers: { 'Authorization': `Bearer ${this.getToken()}` }
        });
        const chave = response.data;

        if (!chave.responsavel || !chave.responsavel.nome || !chave.responsavel.sobrenome) {
          chave.responsavel = { nome: 'Responsável', sobrenome: 'não definido' };
        }

        this.abrirModal(chave);
      } catch (error) {
        console.error('Erro ao buscar chave:', error);
        await this.$refs.popup.showAlert('Erro ao buscar chave: ' + error.response.data);
      }
    },
    abrirModal(chave) {
      this.selectedChave = JSON.parse(JSON.stringify(chave));
      this.initialState = JSON.parse(JSON.stringify(chave.imoveis));
      this.showModal = true;
      this.$router.push({ name: 'GestaoChaves', params: { id: chave._id } });
    },
    fecharModal() {
      this.selectedChave.imoveis = JSON.parse(JSON.stringify(this.initialState));
      this.showModal = false;
      this.$router.push({ name: 'GestaoChaves' });
    },
    toggleModalStatus(imovelObj) {
      if (imovelObj.status === 'devolvido' && this.initialState.some(imovel => imovel._id === imovelObj._id && imovel.status === 'devolvido')) {
        return;
      }

      imovelObj.status = imovelObj.status === 'retirado' ? 'devolvido' : 'retirado';

      if (imovelObj.status === 'devolvido') {
        imovelObj.dataDevolucao = new Date().toISOString();
      } else {
        imovelObj.dataDevolucao = null;
      }
    },
    async alterarStatus() {
      try {
        await api.put(`${process.env.VUE_APP_BACKEND_URL}/chaves/${this.selectedChave._id}`, {
          responsavel: this.selectedChave.responsavel,
          motivoRetirada: this.selectedChave.motivoRetirada,
          imoveis: this.selectedChave.imoveis,
          observacoes: this.selectedChave.observacoes,
        }, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`
          }
        });

        Object.assign(this.chaves.find(chave => chave._id === this.selectedChave._id), this.selectedChave);

        this.fecharModal();
        this.$refs.popup.showAlert('Status dos imóveis alterado com sucesso.');
      } catch (error) {
        console.error('Erro ao alterar o status dos imóveis:', error);
        this.$refs.popup.showAlert('Erro ao alterar o status dos imóveis.');
      }
    },
    imprimirTermoRetirada(chave) {
      const token = localStorage.getItem('token');
      const url = `${process.env.VUE_APP_BACKEND_URL}/termo-retirada/${chave._id}`;

      const newWindow = window.open();

      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.blob())
        .then(blob => {
          const pdfUrl = URL.createObjectURL(blob);
          newWindow.location.href = pdfUrl;
        })
        .catch(error => {
          console.error('Erro ao gerar o PDF:', error);
          newWindow.close();
        });
    },
  },
  async mounted() {
    await this.carregarChaves();
    const chaveId = this.$route.params.id;
    if (chaveId) {
      this.abrirModalPorId(chaveId);
    }
  },
};
</script>

<style scoped>
@import '@/01-admin/assets/css/gestaoChaves.css';
</style>
