<template>
  <section class="home-section">
    <Search />
    <ImoveisDestaques />
    <ImoveisBuscados />
    <ImoveisCategorias v-if="configuracoes.exibirCategorias" />
    <Contact :showContactInfo="false" />
  </section>
</template>

<script>
import script from '@/mixins/script.js';
import Search from './home/Search.vue';
import ImoveisDestaques from './home/ImoveisDestaques.vue';
import ImoveisBuscados from './home/ImoveisBuscados.vue';
import ImoveisCategorias from './home/ImoveisCategorias.vue';
import Contact from './Contact.vue';
import CadastroImovel from './CadastroImovel.vue';

export default {
  name: 'Home',
  mixins: [script],
  components: {
    Search,
    ImoveisDestaques,
    ImoveisBuscados,
    ImoveisCategorias,
    Contact,
    CadastroImovel
  },
  data() {
    return {
      configuracoes: {
        exibirCategorias: false,
      }
    };
  },
  async mounted() {
    this.configuracoes = await this.carregarConfiguracoes();
    await this.registerVisit();
  }
}
</script>

<style scoped>
@import '@/00-website/assets/css/home.css';
</style>
