<template>
  <div class="relatorio-component">
    <section class="relatorio">
      <div class="container">
        <h2>Em desenvolvimento
          <span class="loading-spinner"></span>
        </h2>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Relatorios',
  data() {
    return {
      clearButtonVisible: false,
      currentPage: 1,
      canPreviousPage: false,
      canNextPage: true,
    };
  },
  methods: {
  }
}
</script>

<style scoped>
@import '@/01-admin/assets/css/relatorio.css';
</style>
