import { createRouter, createWebHistory } from 'vue-router';
import api from '@/axios.js';
import mitt from 'mitt';
import AdminLayout from '@/01-admin/layouts/AdminLayout.vue';
import Dashboard from '@/01-admin/components/Dashboard.vue';
import GestaoImoveis from '@/01-admin/components/GestaoImoveis.vue';
import CadastroImoveis from '@/01-admin/components/CadastroImoveis.vue';
import GestaoClientes from '@/01-admin/components/GestaoClientes.vue';
import CadastroClientes from '@/01-admin/components/CadastroClientes.vue';
import GestaoChaves from '@/01-admin/components/GestaoChaves.vue';
import RetirarChaves from '@/01-admin/components/RetirarChaves.vue';
import Relatorios from '@/01-admin/components/Relatorios.vue';
import Login from '@/01-admin/components/Login.vue';
import Visitantes from '@/01-admin/components/Visitantes.vue';
import GestaoUsuarios from '@/01-admin/components/GestaoUsuarios.vue';
import CadastroUsuarios from '@/01-admin/components/CadastroUsuarios.vue';
import Configuracoes from '@/01-admin/components/Configuracoes.vue';

const emitter = mitt();

const routes = [
    {
        path: '/admin/login',
        name: 'Login',
        component: Login,
    },
    { path: '/reset-password/:token', name: 'ResetPassword', component: () => import('@/01-admin/components/Login.vue'), props: true },
    {
        path: '/admin',
        component: AdminLayout,
        children: [
            { path: 'dashboard', name: 'AdminDashboard', component: Dashboard },
            { path: 'imoveis', name: 'AdminImoveis', component: GestaoImoveis },
            { path: 'imoveis/cadastrar', name: 'CadastroImoveis', component: CadastroImoveis },
            { path: 'imoveis/editar/:id', name: 'EditarImovel', component: CadastroImoveis },
            { path: 'clientes', name: 'AdminClientes', component: GestaoClientes },
            { path: 'clientes/cadastrar', name: 'CadastroClientes', component: CadastroClientes },
            { path: 'clientes/editar/:id', name: 'EditarCliente', component: CadastroClientes },
            { path: 'chaves', name: 'GestaoChaves', component: GestaoChaves },
            { path: 'chaves/:id?', name: 'GestaoChaves', component: GestaoChaves },
            { path: 'chaves/retirar', name: 'RetirarChaves', component: RetirarChaves },
            { path: 'chaves/editar/:id', name: 'EditarChave', component: RetirarChaves },
            { path: 'relatorios', name: 'AdminRelatorios', component: Relatorios },
            { path: 'visitantes', name: 'AdminVisitantes', component: Visitantes },
            { path: 'usuarios', name: 'GestaoUsuarios', component: GestaoUsuarios, meta: { requiredRole: 'administrador' } },
            { path: 'usuarios/cadastrar', name: 'CadastroUsuarios', component: CadastroUsuarios, meta: { requiredRole: 'administrador' } },
            { path: 'usuarios/editar/:id', name: 'EditarUsuarios', component: CadastroUsuarios },
            { path: 'configuracoes', name: 'Configuracoes', component: Configuracoes, meta: { requiredRole: 'administrador' } }
        ],
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, behavior: 'smooth' };
        }
    }
});

router.beforeEach(async (to, from, next) => {
    if (to.path.startsWith('/admin')) {
        const isLoggedIn = !!localStorage.getItem('token');
        const userRole = localStorage.getItem('role');
        if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
            next('/admin/login');
        } else if (to.matched.some(record => record.meta.requiredRole) && !userRole.includes(to.meta.requiredRole)) {
            next('/admin/dashboard');
        } else {
            if (isLoggedIn) {
                const tokenValid = await checkTokenValidity();
                if (!tokenValid) {
                    emitter.emit('token-expired');
                    localStorage.removeItem('token');
                    next('/admin/login');
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    } else {
        next();
    }
});

async function checkTokenValidity() {
    const token = localStorage.getItem('token');
    if (!token) {
        return false;
    }

    try {
        const response = await api.get(`${process.env.VUE_APP_BACKEND_URL}/verify-token`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response.status === 200;
    } catch (error) {
        return false;
    }
}

export default router;
export { emitter };
