<template>
    <div class="cadastro-clientes-component">
        <section class="cadastro-clientes">
            <div class="container">
                <div class="header-property">
                    <h2>{{ $route.params.id ? 'Editar Cliente' : 'Cadastrar novo cliente' }}</h2>
                    <div class="close-property" @click="closeForm">X</div>
                </div>
                <hr>
                <form id="form-client" @submit.prevent="showSaveConfirmation">
                    <!-- Informações Pessoais -->
                    <div class="section">
                        <h3>Informações Pessoais</h3>
                        <div class="grid-container" id="grid-pessoais">
                            <div class="group-input">
                                <h4>Nome*</h4>
                                <input type="text" id="nome" v-model="form.nome" required tabindex="1" />
                                <div class="error-message-input" id="error-message-nome" v-if="errors.nome">{{
                                    errors.nome }}</div>
                            </div>
                            <div class="group-input">
                                <h4>Sobrenome*</h4>
                                <input type="text" id="sobrenome" v-model="form.sobrenome" required tabindex="2" />
                                <div class="error-message-input" id="error-message-sobrenome" v-if="errors.sobrenome">{{
                                    errors.sobrenome }}</div>
                            </div>
                            <div class="group-input">
                                <h4 for="tipoCliente">Tipo*</h4>
                                <div class="select" id="select-tipoCliente" @click="toggleDisplayOpcoesTipoCliente"
                                    @keypress.enter="toggleDisplayOpcoesTipoCliente" tabindex="3">
                                    <span v-if="form.tipo" id="selected-tipoCliente">{{ form.tipo }}</span>
                                    <span v-else id="selected-tipoCliente">Selecione</span>
                                    <span class="icon-expanded"></span>
                                    <ul id="drop-tipoCliente" class="dropdown"
                                        :class="{ 'show': displayOpcoesTipoCliente }">
                                        <li v-for="tipo in opcoesTipoCliente" :key="tipo" class="selectable-option"
                                            :id="'option-' + tipo">
                                            <label class="radio-container">
                                                <span>{{ tipo }}</span>
                                                <input type="radio" class="radio-tipo" :value="tipo" v-model="form.tipo"
                                                    tabindex="8" />
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="group-input">
                                <h4>Data de Nascimento*</h4>
                                <input type="date" id="dataNascimento" v-model="form.dataNascimento" required
                                    tabindex="4" />
                            </div>
                            <div class="group-input">
                                <h4>Documento (CPF/CNPJ)*</h4>
                                <input type="text" id="documento" v-model="form.documento"
                                    @input="form.documento = formatarDocumento($event.target.value)" required
                                    tabindex="5" />
                                <div class="error-message-input" id="error-message-documento" v-if="errors.documento">{{
                                    errors.documento }}</div>
                            </div>
                        </div>
                        <div class="grid-container" id="grid-pessoais-2">
                            <div class="group-input">
                                <h4>Identidade (RG/CNH)</h4>
                                <input type="text" id="identidade" v-model="form.identidade"
                                    @input="form.identidade = formatarIdentidade($event.target.value)" tabindex="6" />
                                <div class="error-message-input" id="error-message-identidade" v-if="errors.identidade">
                                    {{ errors.identidade }}</div>
                            </div>
                            <div class="group-input">
                                <h4>Nacionalidade</h4>
                                <input type="text" id="nacionalidade" v-model="form.nacionalidade" tabindex="7" />
                            </div>
                            <div class="group-input">
                                <h4 for="estadoCivil">Estado Civil</h4>
                                <div class="select" id="select-estadoCivil" @click="toggleDisplayOpcoesEstadoCivil"
                                    @keypress.enter="toggleDisplayOpcoesEstadoCivil" tabindex="8">
                                    <span v-if="form.estadoCivil" id="selected-estadoCivil">{{ form.estadoCivil
                                        }}</span>
                                    <span v-else id="selected-estadoCivil">Selecione</span>
                                    <span class="icon-expanded"></span>
                                    <ul id="drop-estadoCivil" class="dropdown"
                                        :class="{ 'show': displayOpcoesEstadoCivil }">
                                        <li v-for="estadoCivil in opcoesEstadoCivil" :key="estadoCivil"
                                            class="selectable-option" :id="'option-' + estadoCivil">
                                            <label class="radio-container">
                                                <span>{{ estadoCivil }}</span>
                                                <input type="radio" class="radio-estadoCivil" :value="estadoCivil"
                                                    v-model="form.estadoCivil" tabindex="9" />
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="group-input">
                                <h4>Profissão</h4>
                                <input type="text" id="profissao" v-model="form.profissao" tabindex="10" />
                            </div>
                            <div class="group-input">
                                <h4>Renda Mensal</h4>
                                <input type="text" id="rendaMensal" v-model="form.rendaMensal"
                                    @input="form.rendaMensal = formatarValor($event.target.value)" placeholder="R$ 0,00"
                                    tabindex="11" />
                            </div>
                        </div>
                        <hr>
                    </div>
                    <!-- Informações de Contato -->
                    <div class="section">
                        <h3>Informações de Contato</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>E-mail*</h4>
                                <input type="email" id="email" v-model="form.email" required tabindex="12" />
                                <div class="error-message-input" id="error-message-email" v-if="errors.email">{{
                                    errors.email }}</div>
                            </div>
                            <div class="group-input">
                                <h4>Telefone Residencial</h4>
                                <input type="text" id="telefoneResidencial" v-model="form.telefoneResidencial"
                                    @input="form.telefoneResidencial = formatarTelefone($event.target.value)"
                                    tabindex="13" />
                            </div>
                            <div class="group-input">
                                <h4>Telefone Celular*</h4>
                                <input type="text" id="telefoneCelular" v-model="form.telefoneCelular"
                                    @input="form.telefoneCelular = formatarTelefone($event.target.value)" required
                                    tabindex="14" />
                            </div>
                        </div>
                        <hr>
                    </div>
                    <!-- Endereço Atual -->
                    <div class="section">
                        <h3>Endereço Atual</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>CEP</h4>
                                <input type="text" id="cep" v-model="form.endereco.cep"
                                    @input="form.endereco.cep = formatarCEP($event.target.value), inserirEnderecoBuscado($event.target.value)"
                                    tabindex="15" />
                            </div>
                            <div class="group-input">
                                <h4>Rua*</h4>
                                <input type="text" id="rua" v-model="form.endereco.rua" tabindex="16" />
                            </div>
                            <div class="group-input">
                                <h4>Número*</h4>
                                <input type="number" id="numero" v-model="form.endereco.numero" tabindex="17" />
                            </div>
                            <div class="group-input">
                                <h4>Complemento</h4>
                                <input type="text" id="complemento" v-model="form.endereco.complemento" tabindex="18" />
                            </div>
                            <div class="group-input">
                                <h4>Bairro*</h4>
                                <input type="text" id="bairro" v-model="form.endereco.bairro" tabindex="19" />
                            </div>
                            <div class="group-input">
                                <h4>Cidade*</h4>
                                <input type="text" id="cidade" v-model="form.endereco.cidade" tabindex="20" />
                            </div>
                            <div class="group-input">
                                <h4>Estado*</h4>
                                <input type="text" id="estado" v-model="form.endereco.estado"
                                    @input="form.endereco.estado = formatarEstado($event.target.value)" tabindex="21" />
                            </div>
                        </div>
                        <hr>
                    </div>
                    <!-- Informações Adicionais -->
                    <div v-if="form.estadoCivil === 'Casado'" class="section">
                        <h3>Informações do Cônjuge</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>Nome do Cônjuge</h4>
                                <input type="text" id="nomeConjuge" v-model="form.conjuge.nome_conjuge" tabindex="22" />
                            </div>
                            <div class="group-input">
                                <h4>Documento do Cônjuge (CPF/CNPJ)</h4>
                                <input type="text" id="documentoConjuge" v-model="form.conjuge.doc_conjuge"
                                    @input="form.conjuge.doc_conjuge = formatarDocumento($event.target.value)"
                                    tabindex="23" />
                            </div>
                            <div class="group-input">
                                <h4>Identidade do Cônjuge (RG/CNH)</h4>
                                <input type="text" id="identidadeConjuge" v-model="form.conjuge.identidade_conjuge"
                                    @input="form.conjuge.identidade_conjuge = formatarIdentidade($event.target.value)"
                                    tabindex="24" />
                            </div>
                            <div class="group-input">
                                <h4>Telefone do Cônjuge</h4>
                                <input type="text" id="telefoneConjuge" v-model="form.conjuge.telefone_conjuge"
                                    @input="form.conjuge.telefone_conjuge = formatarTelefone($event.target.value)"
                                    tabindex="25" />
                            </div>
                        </div>
                        <hr>
                    </div>
                    <!-- Referências -->
                    <div v-if="showReferences" class="section">
                        <h3>Referências</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>Nome da Referência</h4>
                                <input type="text" id="nomeReferencia" v-model="form.referencia.nome_referencia"
                                    tabindex="26" />
                            </div>
                            <div class="group-input">
                                <h4>Telefone da Referência</h4>
                                <input type="text" id="telefoneReferencia" v-model="form.referencia.telefone_referencia"
                                    @input="form.referencia.telefone_referencia = formatarTelefone($event.target.value)"
                                    tabindex="27" />
                            </div>
                        </div>
                        <hr>
                    </div>
                    <!-- Botões -->
                    <div class="btn-container">
                        <button type="button" class="btn-anexar" id="btn-aenxarDocumento"
                            @click="abrirModalUpload">Anexar Documentos</button>
                        <div class="btn-group-right">
                            <button type="button" class="button" id="btn-cancelar" @click="closeForm">Cancelar</button>
                            <button type="submit" class="btn-salvar-form" id="btn-salvar"
                                :disabled="!isFormValid">Salvar</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <!-- Modal de Upload de Imagens -->
        <section class="modal-upload" v-if="mostrarModalUpload">
            <div class="modal-container">
                <h2>Upload de Documentos do Cliente</h2>
                <div class="upload-container">
                    <div ref="preview" class="preview-container">
                        <div ref="uploadPlaceholder" class="image-upload upload-placeholder">
                            <label for="imagemCliente">
                                <div class="upload-placeholder">
                                    <span>+</span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <input type="file" id="imagemCliente" accept="image/*" multiple style="display: none"
                    @change="carregarImagensSelecionadas">
                <div class="btn-modal">
                    <button type="button" class="button" id="btn-cancelarDocumentos"
                        @click="cancelarModalUpload">Voltar</button>
                    <button type="button" class="btn-salvar-imagens" id="btn-salvarDocumentos"
                        @click="saveAndCloseModal">Salvar
                        documentos</button>
                </div>
            </div>
        </section>
        <Popup ref="popup" />
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';
import Loading from '@/mixins/Loading.vue';
import { emitter } from '@/router/admin.js';
import script from '@/mixins/script.js';
import Sortable from 'sortablejs';

export default {
    name: 'CadastroClientes',
    mixins: [script],
    data() {
        return {
            form: {
                nome: '',
                sobrenome: '',
                tipo: '',
                dataNascimento: '',
                documento: '',
                identidade: '',
                nacionalidade: '',
                estadoCivil: '',
                profissao: '',
                rendaMensal: '',
                email: '',
                telefoneResidencial: '',
                telefoneCelular: '',
                endereco: {
                    cep: '',
                    rua: '',
                    numero: '',
                    complemento: '',
                    bairro: '',
                    cidade: '',
                    estado: ''
                },
                conjuge: {
                    nome_conjuge: '',
                    doc_conjuge: '',
                    identidade_conjuge: '',
                    telefone_conjuge: ''
                },
                referencia: {
                    nome_referencia: '',
                    telefone_referencia: '',
                    relacao_referencia: ''
                }
            },
            initialFormState: {},
            opcoesTipoCliente: ['Proprietário', 'Cliente', 'Corretor', 'Interessado'],
            displayOpcoesTipoCliente: false,
            opcoesEstadoCivil: ['Solteiro', 'Casado', 'Separado', 'Divorciado', 'Viúvo'],
            displayOpcoesEstadoCivil: false,
            mostrarModalUpload: false,
            imagensSalvas: [],
            imagensTemporarias: [],
            MAX_IMAGES: 30,
            errors: {
                nome: '',
                sobrenome: '',
                documento: '',
                identidade: '',
                email: ''
            },
            isLoading: false
        };
    },
    computed: {
        isFormValid() {
            return this.form.nome && this.form.sobrenome && this.form.tipo && this.form.dataNascimento && this.form.documento && this.form.endereco.rua && this.form.endereco.numero && this.form.endereco.bairro && this.form.endereco.cidade && this.form.endereco.estado && this.form.email && this.form.telefoneCelular;
        },
        isEdit() {
            return Boolean(this.$route.params.id);
        },
        showReferences() {
            return (this.form.estadoCivil !== '' && this.form.estadoCivil !== 'Casado');
        }
    },
    async created() {
        if (this.$route.params.id) {
            console.log('ID do cliente na criação do componente:', this.$route.params.id);
            await this.carregarDadosCliente(this.$route.params.id);
        }
        this.saveInitialFormState();
    },
    methods: {
        closeForm() {
            if (this.hasFormChanged()) {
                this.$refs.popup.showConfirm('Deseja sair sem salvar as informações?', (confirmed) => {
                    if (confirmed) {
                        this.$router.push('/admin/clientes');
                    }
                });
            } else {
                this.$router.push('/admin/clientes');
            }
        },
        showSaveConfirmation() {
            this.$refs.popup.showConfirm('Deseja salvar o cliente?', (confirmed) => {
                if (confirmed) {
                    this.handleSubmit(this.isEdit ? 'update' : 'create');
                }
            });
        },
        async handleSubmit(actionType) {
            this.isLoading = true
            this.limparRespotas();

            const payload = { ...this.form };
            payload.documento = payload.documento.replace(/\D/g, '');
            payload.identidade = payload.identidade ? payload.identidade.replace(/[^0-9X]/g, '') : null;
            payload.rendaMensal = payload.rendaMensal ? payload.rendaMensal.replace('R$ ', '').replace(/\./g, '').replace(',', '.') : null;
            payload.telefoneResidencial = payload.telefoneResidencial ? payload.telefoneResidencial.replace(/\D/g, '') : null;
            payload.telefoneCelular = payload.telefoneCelular.replace(/\D/g, '');
            payload.endereco.cep = payload.endereco.cep ? payload.endereco.cep.replace(/\D/g, '') : null;
            payload.conjuge.doc_conjuge = payload.conjuge.doc_conjuge ? payload.conjuge.doc_conjuge.replace(/\D/g, '') : null;
            payload.conjuge.identidade_conjuge = payload.conjuge.identidade_conjuge ? payload.conjuge.identidade_conjuge.replace(/[^0-9X]/g, '') : null;
            payload.conjuge.telefone_conjuge = payload.conjuge.telefone_conjuge ? payload.conjuge.telefone_conjuge.replace(/\D/g, '') : null;
            payload.referencia.telefone_referencia = payload.referencia.telefone_referencia ? payload.referencia.telefone_referencia.replace(/\D/g, '') : null;

            const url = this.isEdit
                ? `${process.env.VUE_APP_BACKEND_URL}/clientes/${this.$route.params.id}`
                : `${process.env.VUE_APP_BACKEND_URL}/clientes`;
            const method = this.isEdit ? 'PUT' : 'POST';

            try {
                const response = await fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    const errorMessage = await response.text();
                    throw new Error(errorMessage);
                }

                const data = await response.json();

                if (this.imagensTemporarias.length > 0) {
                    const clienteId = this.isEdit ? this.$route.params.id : data._id;
                    await this.enviarImagens(clienteId);
                }

                this.showSuccessAndRedirect(`Cliente ${actionType === 'create' ? 'cadastrado' : 'atualizado'} com sucesso!`);

            } catch (error) {
                this.mostrarErro(error.message);
            } finally {
                this.isLoading = false
            }
        },
        showSuccessAndRedirect(message) {
            const popup = document.getElementById('custom-popup');
            const popupMessage = document.getElementById('popup-message');
            const popupButtons = document.getElementById('popup-buttons');

            popupMessage.innerText = message;
            popup.style.display = 'flex';
            popupButtons.innerHTML = '';
            popupButtons.style.justifyContent = 'center';

            const okButton = document.createElement('button');
            okButton.innerText = 'OK';
            okButton.id = 'popup-ok';
            okButton.onclick = async () => {
                await this.$refs.popup.fecharPopup();
                this.$router.push('/admin/clientes');
            };

            popupButtons.appendChild(okButton);
        },
        async carregarDadosCliente(id) {
            if (!id) {
                return;
            }

            try {
                const response = await this.handleFetchWithToken(`${process.env.VUE_APP_BACKEND_URL}/clientes/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                const cliente = await response.data;
                this.preencherFormulario(cliente);
                this.carregarImagensExistentes(cliente.imagens);
                this.saveInitialFormState();
            } catch (error) {
                const errorMessage = error.response?.data || 'Erro ao carregar dados do cliente';
                await this.$refs.popup.showAlert(errorMessage);
            }
        },
        carregarImagensExistentes(imagens) {
            imagens.sort((a, b) => a.ordem - b.ordem);
            this.imagensSalvas = imagens.map((imagem) => {
                return {
                    src: imagem.url,
                    filename: imagem.filename,
                    ordem: imagem.ordem,
                    isNew: false
                };
            });
            this.imagensTemporarias = [...this.imagensSalvas];
            this.carregarImagensTemporarias();
        },
        preencherFormulario(cliente) {
            this.form = {
                nome: cliente.nome,
                sobrenome: cliente.sobrenome,
                tipo: cliente.tipo,
                dataNascimento: cliente.dataNascimento.split('T')[0],
                documento: this.formatarDocumento(cliente.documento),
                identidade: cliente.identidade ? this.formatarIdentidade(cliente.identidade) : '',
                nacionalidade: cliente.nacionalidade ? cliente.nacionalidade : '',
                estadoCivil: cliente.estadoCivil,
                profissao: cliente.profissao ? cliente.profissao : '',
                rendaMensal: cliente.rendaMensal ? this.formatarValor(cliente.rendaMensal) : '',
                email: cliente.email,
                telefoneResidencial: cliente.telefoneResidencial ? this.formatarTelefone(cliente.telefoneResidencial) : '',
                telefoneCelular: this.formatarTelefone(cliente.telefoneCelular),
                endereco: {
                    cep: cliente.endereco.cep ? this.formatarCEP(cliente.endereco.cep) : '',
                    rua: cliente.endereco.rua ? cliente.endereco.rua : '',
                    numero: cliente.endereco.numero ? cliente.endereco.numero : '',
                    complemento: cliente.endereco.complemento ? cliente.endereco.complemento : '',
                    bairro: cliente.endereco.bairro ? cliente.endereco.bairro : '',
                    cidade: cliente.endereco.cidade ? cliente.endereco.cidade : '',
                    estado: cliente.endereco.estado ? cliente.endereco.estado : ''
                },
                conjuge: {
                    nome_conjuge: cliente.conjuge.nome_conjuge ? cliente.conjuge.nome_conjuge : '',
                    doc_conjuge: cliente.conjuge.doc_conjuge ? this.formatarDocumento(cliente.conjuge.doc_conjuge) : '',
                    identidade_conjuge: cliente.conjuge.identidade_conjuge ? this.formatarIdentidade(cliente.conjuge.identidade_conjuge) : '',
                    telefone_conjuge: this.formatarTelefone(cliente.conjuge.telefone_conjuge) || ''
                },
                referencia: {
                    nome_referencia: cliente.referencia.nome_referencia ? cliente.referencia.nome_referencia : '',
                    telefone_referencia: cliente.referencia.telefone_referencia ? this.formatarTelefone(cliente.referencia.telefone_referencia) : '',
                    relacao_referencia: cliente.referencia.relacao_referencia ? cliente.referencia.relacao_referencia : ''
                }
            };

        },
        async handleFetchWithToken(url, options = {}) {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Token de autenticação não encontrado.');
            }

            const headers = {
                'Authorization': `Bearer ${token}`,
                ...options.headers,
            };

            try {
                const response = await api.get(url, { ...options, headers });

                if (response.status === 401) {
                    const errorData = await response.data;
                    if (errorData === 'Token expired.') {
                        emitter.emit('token-expired');
                        localStorage.removeItem('token');
                        throw new Error('Token expired.');
                    }
                }

                return response;
            } catch (error) {
                console.error('Erro na chamada da API:', error);
                throw error;
            }
        },
        async inserirEnderecoBuscado(cep) {
            cep = cep.replace(/\D/g, '');
            if (cep.length === 8) {
                const endereco = await this.buscarEndereco(cep)
                this.form.endereco.rua = endereco.logradouro;
                this.form.endereco.bairro = endereco.bairro;
                this.form.endereco.cidade = endereco.localidade;
                this.form.endereco.estado = endereco.uf;
            }
        },
        closeDropDowns() {
            document.addEventListener('click', (event) => {
                const isClickDropEstadoCivil = event.target.id === 'drop-estadoCivil';
                const isClickToggleEstadoCivil = event.target.id === 'select-estadoCivil';
                const isClickTextToggleEstadoCivil = event.target.id === 'selected-estadoCivil';
                const isClickIconToggleEstadoCivil = event.target.classList.contains('icon-expanded');

                if (!isClickDropEstadoCivil && !isClickToggleEstadoCivil && !isClickTextToggleEstadoCivil && !isClickIconToggleEstadoCivil) {
                    this.displayOpcoesEstadoCivil = false;
                }

                const isClickDropTipoCliente = event.target.id === 'drop-tipoCliente';
                const isClickToggleTipoCliente = event.target.id === 'select-tipoCliente';
                const isClickTextToggleTipoCliente = event.target.id === 'selected-tipoCliente';
                const isClickIconToggleTipoCliente = event.target.classList.contains('icon-expanded');

                if (!isClickDropTipoCliente && !isClickToggleTipoCliente && !isClickTextToggleTipoCliente && !isClickIconToggleTipoCliente) {
                    this.displayOpcoesTipoCliente = false;
                }
            });
        },
        toggleDisplayOpcoesTipoCliente() {
            this.displayOpcoesTipoCliente = !this.displayOpcoesTipoCliente;
        },
        toggleDisplayOpcoesEstadoCivil() {
            this.displayOpcoesEstadoCivil = !this.displayOpcoesEstadoCivil;
        },
        saveInitialFormState() {
            this.initialFormState = JSON.parse(JSON.stringify(this.form));
            this.imagensTemporarias = JSON.parse(JSON.stringify(this.imagensSalvas));
        },
        hasFormChanged() {
            const formChanged = JSON.stringify(this.form) !== JSON.stringify(this.initialFormState);
            const imagesChanged = !this.compareImageArrays(this.imagensTemporarias, this.imagensSalvas);
            return formChanged || imagesChanged;
        },
        compareImageArrays(array1, array2) {
            if (array1.length !== array2.length) return false;
            for (let i = 0; i < array1.length; i++) {
                if (array1[i].src !== array2[i].src || array1[i].ordem !== array2[i].ordem) {
                    return false;
                }
            }
            return true;
        },
        abrirModalUpload() {
            this.imagensSalvasBackup = JSON.parse(JSON.stringify(this.imagensTemporarias));
            this.mostrarModalUpload = true;
            this.$nextTick(() => {
                if (this.$refs.preview && this.$refs.uploadPlaceholder) {
                    this.carregarImagensTemporarias();
                    this.inicializarSortable();
                }
            });
        },
        cancelarModalUpload() {
            this.imagensTemporarias = JSON.parse(JSON.stringify(this.imagensSalvasBackup));
            this.mostrarModalUpload = false;
        },
        carregarImagensSelecionadas(event) {
            const input = event.target;
            const preview = this.$refs.preview;
            const uploadPlaceholder = this.$refs.uploadPlaceholder;
            const currentImages = preview.querySelectorAll('.img-container').length;
            const filesToAdd = input.files.length;

            if (currentImages + filesToAdd > this.MAX_IMAGES) {
                this.$refs.popup.showAlert(`Você só pode adicionar até ${this.MAX_IMAGES} imagens.`);
                input.value = '';
                return;
            }

            for (const file of input.files) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const imgContainer = document.createElement('div');
                    imgContainer.classList.add('img-container');
                    const img = document.createElement('img');
                    img.src = event.target.result;
                    img.file = file;
                    imgContainer.appendChild(img);

                    const removeButton = document.createElement('button');
                    removeButton.classList.add('remove-image');
                    removeButton.onclick = () => {
                        imgContainer.remove();
                        const index = this.imagensTemporarias.findIndex((img) => img.src === event.target.result);
                        if (index !== -1) {
                            this.imagensTemporarias.splice(index, 1);
                        }
                        this.verificarLimiteImagens();
                    };
                    imgContainer.appendChild(removeButton);

                    preview.insertBefore(imgContainer, uploadPlaceholder);
                    this.imagensTemporarias.push({ src: event.target.result, file, isNew: true, ordem: this.imagensTemporarias.length });
                };
                reader.readAsDataURL(file);
            }

            input.value = '';

            this.inicializarSortable();
            this.verificarLimiteImagens();
        },
        carregarImagensTemporarias() {
            if (!this.$refs.preview || !this.$refs.uploadPlaceholder) {
                return;
            }

            const preview = this.$refs.preview;
            const uploadPlaceholder = this.$refs.uploadPlaceholder;

            while (preview.firstChild) {
                preview.removeChild(preview.firstChild);
            }

            preview.appendChild(uploadPlaceholder);
            this.imagensTemporarias.forEach((imagem) => {
                const imgContainer = document.createElement('div');
                imgContainer.classList.add('img-container');
                const img = document.createElement('img');
                img.src = imagem.src;
                img.file = imagem.file;
                imgContainer.appendChild(img);

                const removeButton = document.createElement('button');
                removeButton.classList.add('remove-image');
                removeButton.onclick = () => {
                    imgContainer.remove();
                    const index = this.imagensTemporarias.indexOf(imagem);
                    if (index > -1) {
                        this.imagensTemporarias.splice(index, 1);
                    }
                    this.verificarLimiteImagens();
                };
                imgContainer.appendChild(removeButton);

                preview.insertBefore(imgContainer, uploadPlaceholder);
            });

            this.verificarLimiteImagens();
        },
        inicializarSortable() {
            if (this.sortableInstance) {
                this.sortableInstance.destroy();
            }
            this.sortableInstance = new Sortable(this.$refs.preview, {
                animation: 150,
                ghostClass: 'sortable-ghost',
                filter: '#uploadPlaceholder',
                onEnd: (evt) => {
                    const uploadPlaceholder = this.$refs.uploadPlaceholder;
                    if (uploadPlaceholder.parentNode) {
                        uploadPlaceholder.parentNode.appendChild(uploadPlaceholder);
                    }
                    const imgContainers = this.$refs.preview.querySelectorAll('.img-container');
                    imgContainers.forEach((container, index) => {
                        const img = container.querySelector('img');
                        if (img) {
                            const tempImg = this.imagensTemporarias.find(image => image.src === img.src);
                            if (tempImg) {
                                tempImg.ordem = index;
                            }
                        }
                    });
                },
            });
        },
        saveAndCloseModal() {
            const preview = this.$refs.preview;
            const imgContainers = preview.querySelectorAll('.img-container');
            this.imagensTemporarias = [];

            imgContainers.forEach((container, index) => {
                const img = container.querySelector('img');
                if (img) {
                    this.imagensTemporarias.push({
                        src: img.src,
                        file: img.file,
                        ordem: index,
                        isNew: img.file ? true : false,
                        filename: img.filename || (img.src.split('/').pop())
                    });
                }
            });

            this.mostrarModalUpload = false;
        },
        verificarLimiteImagens() {
            const preview = this.$refs.preview;
            const uploadPlaceholder = this.$refs.uploadPlaceholder;
            const currentImages = preview.querySelectorAll('.img-container').length;

            if (currentImages >= this.MAX_IMAGES) {
                uploadPlaceholder.style.display = 'none';
            } else {
                uploadPlaceholder.style.display = 'flex';
            }
        },
        async enviarImagens(clienteId) {
            const formData = new FormData();

            this.imagensTemporarias.forEach((img) => {
                if (img.isNew) {
                    formData.append('imagem', img.file, img.file.name);
                }
            });

            formData.append('todasImagens', JSON.stringify(this.imagensTemporarias.map(img => ({
                src: img.src,
                filename: img.filename,
                url: img.url || `${process.env.VUE_APP_STORAGE_IMAGENS_URL}/${process.env.VUE_APP_IMOBILIARIA}/clientes/documentos/${clienteId}/${img.filename}`,
                ordem: img.ordem
            }))));

            const url = `${process.env.VUE_APP_BACKEND_URL}/clientes/imagem/${clienteId}`;

            try {
                const response = await fetch(url, {
                    method: 'PUT',
                    body: formData,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    const errorMessage = await response.text();
                    throw new Error(errorMessage);
                }

                this.imagensSalvas = [...this.imagensTemporarias];
            } catch (error) {
                await this.$refs.popup.showAlert(error.message);
            }
        },
        limparRespotas() {
            this.errors = {
                nome: '',
                sobrenome: '',
                documento: '',
                identidade: '',
                email: ''
            }
        },
        mostrarErro(mensagem) {
            mensagem = mensagem.replace(/"/g, '')
            if (mensagem.includes('O nome deve conter apenas letras')) {
                this.errors.nome = mensagem;
            } else if (mensagem.includes('O sobrenome deve conter apenas letras')) {
                this.errors.sobrenome = mensagem;
            } else if (mensagem.includes('documento') || mensagem.includes('Documento')) {
                this.errors.documento = mensagem;
            } else if (mensagem.includes('identidade') || mensagem.includes('Identidade')) {
                this.errors.identidade = mensagem;
            } else if (mensagem.includes('E-mail') || mensagem.includes('e-mail')) {
                this.errors.email = mensagem;
            } else {
                console.error('Erro ao salvar cliente:', mensagem);
            }
        },
    },
    components: {
        Popup,
        Loading
    },
    mounted() {
        if (this.isEdit) {
            console.log('ID do cliente na montagem do componente:', this.$route.params.id);
            this.carregarDadosCliente(this.$route.params.id);
        }
        this.closeDropDowns();
        this.saveInitialFormState();
    }
};
</script>

<style scoped>
@import '@/01-admin/assets/css/cadastro-clientes.css';
</style>