<template>
    <div class="modal-imagensImoveis" id="modal-images" @touchstart="handleSwipeStart($event)"
        @touchmove="handleSwipeMove($event)" @touchend="handleSwipeEnd()">
        <button class="close-button" @click="closeModal">×</button>
        <img :src="currentImageUrl" class="modal-image" ref="modalImg">
        <button class="nav-button prev-button" @click="navigate(-1)">&#10094;</button>
        <button class="nav-button next-button" @click="navigate(1)">&#10095;</button>
        <div class="thumbnails-container" ref="thumbnailsContainer">
            <img v-for="(imageUrl, index) in imageUrls" :key="index" :src="imageUrl"
                :class="{ 'thumbnail': true, 'active-thumbnail': index === currentIndex }"
                @click="setCurrentImage(index)">
        </div>
    </div>
</template>

<script>
import api from '@/axios.js';
export default {
    name: 'ModalImagens',
    props: ['id_imovel'],
    data() {
        return {
            backendUrl: process.env.VUE_APP_BACKEND_URL,
            currentIndex: 0,
            imageUrls: [],
            imovelId: this.$route.params.id_imovel,
            touchStartX: null,
            touchStartY: null,
            touchEndX: null,
            touchEndY: null
        };
    },
    async mounted() {
        await this.carregarImagensImovel();
        window.addEventListener('keydown', this.handleKeydown);

        const modal = document.getElementById('modal-images');
        modal.addEventListener('click', (event) => {
            if (event.target === modal) {
                this.closeModal();
            }
        });

        this.scrollToActiveImage();
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    },
    computed: {
        currentImageUrl() {
            return this.imageUrls[this.currentIndex];
        }
    },
    methods: {
        async carregarImagensImovel() {
            try {
                const response = await api.get(`${this.backendUrl}/imoveis/imagem/${this.imovelId}`);
                const imagens = await response.data;
                this.imageUrls = imagens.map(imagem => imagem.url);
            } catch (error) {
                console.error('Erro ao carregar imagens do imóvel:', error);
            }
        },
        setCurrentImage(index) {
            this.currentIndex = index;
            this.scrollToActiveImage();
        },
        navigate(direction) {
            let newIndex = this.currentIndex + direction;
            if (newIndex < 0) {
                newIndex = this.imageUrls.length - 1;
            } else if (newIndex >= this.imageUrls.length) {
                newIndex = 0;
            }
            this.currentIndex = newIndex;
            this.scrollToActiveImage();
        },
        handleKeydown(event) {
            if (event.key === 'Escape') {
                this.closeModal();
            } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
                this.navigate(1);
            } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
                this.navigate(-1);
            }
        },
        handleSwipeStart(event) {
            this.touchStartX = event.touches[0].clientX;
            this.touchStartY = event.touches[0].clientY;
        },
        handleSwipeMove(event) {
            this.touchEndX = event.touches[0].clientX;
            this.touchEndY = event.touches[0].clientY;
        },
        handleSwipeEnd() {
            if (!this.touchStartX || !this.touchEndX || !this.touchStartY || !this.touchEndY) {
                return;
            }

            const diffX = this.touchStartX - this.touchEndX;
            const diffY = this.touchStartY - this.touchEndY;

            if (Math.abs(diffX) > 50 && Math.abs(diffX) > Math.abs(diffY)) {
                if (diffX > 0) {
                    this.navigate(1);
                } else {
                    this.navigate(-1);
                }
            }

            this.touchStartX = null;
            this.touchEndX = null;
            this.touchStartY = null;
            this.touchEndY = null;
        },
        scrollToActiveImage() {
            this.$nextTick(() => {
                const activeThumbnail = this.$refs.thumbnailsContainer.querySelector('.active-thumbnail');
                const thumbnailsContainer = this.$refs.thumbnailsContainer;

                if (activeThumbnail && thumbnailsContainer) {
                    const containerRect = thumbnailsContainer.getBoundingClientRect();
                    const thumbnailRect = activeThumbnail.getBoundingClientRect();

                    const offsetLeft = thumbnailRect.left - containerRect.left;
                    const offsetTop = thumbnailRect.top - containerRect.top;

                    const scrollLeft = offsetLeft - (containerRect.width / 2) + (thumbnailRect.width / 2);
                    const scrollTop = offsetTop - (containerRect.height / 2) + (thumbnailRect.height / 2);

                    thumbnailsContainer.scrollLeft += scrollLeft;
                    thumbnailsContainer.scrollTop += scrollTop;
                }
            });
        },
        closeModal() {
            document.getElementById('modal-images').classList.remove('show');
            document.getElementById('app-website').classList.remove('no-scroll');
        }
    }
};
</script>

<style scoped>
@import '@/00-website/assets/css/modalImagens.css';
</style>
