<template>
  <div>
    <router-view />
    <Loading :loading="isLoading" />
    <PopupToken ref="popupToken" v-if="showAdminPopup" />
  </div>
</template>

<script>
import Loading from '@/mixins/Loading.vue';
import PopupToken from '@/mixins/Popup.vue';
import { emitter } from '@/router/admin.js';

export default {
  name: 'App',
  components: {
    Loading,
    PopupToken
  },
  data() {
    return {
      isLoading: false,
      isRouteLoading: false,
      isRequestLoading: false,
      showAdminPopup: false,
      loadingTimeout: null
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.showAdminPopup = to.path.startsWith('/admin');
      if (!this.isRequestLoading) {
        this.isRouteLoading = true;
        this.updateLoadingState();
      }
      next();
    });

    this.$router.afterEach(() => {
      this.isRouteLoading = false;
      this.updateLoadingState();
    });

    window.addEventListener('spinner', this.handleSpinnerEvent);

    emitter.on('token-expired', this.handleTokenExpiration);
  },
  methods: {
    handleSpinnerEvent(event) {
      this.isRequestLoading = event.detail;
      this.updateLoadingState();
    },
    handleTokenExpiration() {
      if (this.showAdminPopup) {
        this.$refs.popupToken.showAlert('Sua sessão expirou. Por favor, faça login novamente.', () => {
          this.$router.push('/admin/login');
        });
      }
    },
    updateLoadingState() {
      if (this.isRequestLoading || this.isRouteLoading) {
        this.startLoading();
      } else {
        this.stopLoading();
      }
    },
    startLoading() {
      if (!this.isLoading) {
        this.isLoading = true;
      }
    },
    stopLoading() {
      clearTimeout(this.loadingTimeout);
      this.loadingTimeout = setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  },
  beforeUnmount() {
    window.removeEventListener('spinner', this.handleSpinnerEvent);
    emitter.off('token-expired', this.handleTokenExpiration);
    clearTimeout(this.loadingTimeout);
  }
}
</script>

<style>
.no-scroll {
  overflow: hidden;
}

.justify-content-left {
  justify-content: left !important;
}
</style>