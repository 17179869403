<template>
  <section id="loading" class="loading" v-if="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
    <div class="double-bounce3"></div>
  </section>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.double-bounce1,
.double-bounce2,
.double-bounce3 {
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

.double-bounce3 {
  animation-delay: -0.5s;
}

@keyframes bounce {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
</style>