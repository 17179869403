<template>
  <section class="sobre">
    <div class="container">
      <div class="section-title">Sobre a {{ configuracoes.nome }} </div>
      <p class="section-text">{{ configuracoes.sobre }}</p>

      <p class="section-text">
        Segunda a Sexta-Feira das <b>08h30</b> às <b>18h00</b> - Horário de almoço: <b>12h00</b> às <b>13h30</b>
      </p>
    </div>

    <div class="detalhes-maps">
      <div class="detalhes-location-info">
        <div class="detalhes-location-name">Localização</div>
        <div class="detalhes-location-address">{{ configuracoes.endereco.rua }}, {{ configuracoes.endereco.bairro }} -
          {{ configuracoes.endereco.cidade }}/{{ configuracoes.endereco.estado }}</div>
      </div>
    </div>
    <iframe class="detalhes-mapa" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="map-iframe"
      :src="mapSrc"></iframe>
  </section>
</template>

<script>
import script from '@/mixins/script.js';

export default {
  name: 'Sobre',
  mixins: [script],
  data() {
    return {
      configuracoes: {
        nome: '',
        sobre: '',
        endereco: {
          rua: '',
          bairro: '',
          cidade: '',
          estado: '',
        },
        horarioAtendimento: {
          semana: '',
          almoco: ''
        }
      },
      mapSrc: ''
    };
  },
  async mounted() {
    this.configuracoes = await this.carregarConfiguracoes();
    await this.updateMapSrc();
    await this.registerVisit();
  },
  methods: {
    async updateMapSrc() {
      if (this.configuracoes.endereco && this.configuracoes.endereco.rua) {
        try {
          const address = `${this.configuracoes.endereco.rua}, ${this.configuracoes.endereco.bairro}, ${this.configuracoes.endereco.cidade}, ${this.configuracoes.endereco.estado}`;
          const apiKey = process.env.VUE_APP_GOOGLE_API_KEY;
          const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
          const data = await response.json();

          if (data.results && data.results.length > 0) {
            const location = data.results[0].geometry.location;
            this.mapSrc = `https://maps.google.com/maps?q=${location.lat},${location.lng}&z=15&output=embed`;
          } else {
            console.error('Endereço não encontrado');
            this.mapSrc = '';
          }
        } catch (error) {
          console.error('Erro ao obter coordenadas:', error);
          this.mapSrc = '';
        }
      }
    }
  }
};
</script>

<style scoped>
@import '@/00-website/assets/css/sobre.css';
</style>
