<template>
    <div class="cadastro-usuarios-component">
        <section class="cadastro-usuarios">
            <div class="container">
                <div class="header-property">
                    <h2>{{ isEdit ? 'Editar usuário' : 'Cadastrar novo usuário' }}</h2>
                    <div class="close-property" @click="closeForm">X</div>
                </div>
                <hr>
                <form id="form" @submit.prevent="showSaveConfirmation">
                    <div>
                        <div class="section">
                            <h3>Dados do usuário</h3>
                            <div class="grid-container">
                                <div class="group-input">
                                    <h4 for="nome">Nome*</h4>
                                    <input id="nome" type="text" required placeholder="Nome" v-model="form.nome" />
                                    <div class="error-message-input" id="error-message-nome"></div>
                                </div>

                                <div class="group-input">
                                    <h4 for="sobrenome">Sobrenome*</h4>
                                    <input id="sobrenome" type="text" required placeholder="Sobrenome"
                                        v-model="form.sobrenome" />
                                    <div class="error-message-input" id="error-message-sobrenome"></div>
                                </div>

                                <div class="group-input">
                                    <h4 for="email">E-mail*</h4>
                                    <input id="email" type="email" required placeholder="E-mail" v-model="form.email" />
                                    <div class="error-message-input" id="error-message-email"></div>
                                </div>

                                <div class="group-input">
                                    <h4 for="documento">Documento (CPF/CNPJ)*</h4>
                                    <input id="documento" type="text" required placeholder="CPF/CNPJ"
                                        v-model="form.documento"
                                        @input="form.documento = formatarDocumento($event.target.value)" />
                                    <div class="error-message-input" id="error-message-documento"></div>
                                </div>

                                <div class="group-input" v-if="form.role.includes('corretor')">
                                    <h4 for="creci">CRECI*</h4>
                                    <input id="creci" type="text" required placeholder="CRECI" v-model="form.creci" />
                                    <div class="error-message-input" id="error-message-creci"></div>
                                </div>
                            </div>
                        </div>

                        <div class="section">
                            <div class="grid-container">
                                <div v-if="role.includes('administrador')" class="group-input">
                                    <h4 for="username">Nome de usuário*</h4>
                                    <input id="username" type="text" required placeholder="user1234"
                                        v-model="form.username" />
                                    <div class="error-message-input" id="error-message-username"></div>
                                </div>

                                <div v-if="role.includes('administrador')" class="group-input">
                                    <h4 for="tipo-usuario">Perfis do usuário*</h4>
                                    <div class="select-tipo-usuario" @click="toggleDisplayTiposUsuario">
                                        <span id="selected-usuario">Perfis ({{ form.role.length }})</span>
                                        <span class="icon-expanded"></span>

                                        <ul id="drop-tipo-usuario" class="dropdown-usuario"
                                            :class="{ 'show': displayTiposUsuario }">
                                            <li v-for="tipo in tiposUsuario" :key="tipo" class="selectable-option"
                                                @click.stop>
                                                <label class="checkbox-container">
                                                    <span>{{ tipo }}</span>
                                                    <input type="checkbox" class="checkbox-tipo-usuario" :value="tipo"
                                                        v-model="form.role" />
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div v-if="role.includes('administrador')" class="group-input">
                                    <h4 for="senha">Senha*</h4>
                                    <input v-if="!isEdit" id="senha" type="password" required placeholder="Senha"
                                        v-model="form.senha" />
                                    <input v-else id="senha" type="password" disabled placeholder="Senha"
                                        v-model="form.senha" />
                                    <div class="error-message-input" id="error-message-senha"></div>
                                </div>

                                <div v-if="role.includes('administrador')" class="group-input">
                                    <h4 for="confirmar-senha">Confirmar senha*</h4>
                                    <input v-if="!isEdit" id="confirmar-senha" type="password" required
                                        placeholder="Confirmar senha" v-model="form.confirmarSenha" />
                                    <input v-else id="confirmar-senha" type="password" disabled
                                        placeholder="Confirmar senha" v-model="form.confirmarSenha" />
                                    <div class="error-message-input" id="error-message-confirmarSenha"></div>
                                </div>

                                <div class="btn-container">
                                    <button type="button" class="button" @click="closeForm">Cancelar</button>
                                    <button type="submit" class="btn-salvar-form"
                                        :disabled="!isFormValid">Salvar</button>
                                    <div class="error-message-input" id="error-message-all"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <Popup ref="popup" />
    </div>
</template>

<script>
import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';
import script from '@/mixins/script.js';

export default {
    name: 'CadastroUsuarios',
    mixins: [script],
    data() {
        return {
            step: 1,
            displayTiposUsuario: false,
            tiposUsuario: ['administrador', 'assistente', 'corretor'],
            usuariosSelecionados: [],
            role: localStorage.getItem('role') || '',
            form: {
                nome: '',
                sobrenome: '',
                email: '',
                documento: '',
                username: '',
                senha: '',
                confirmarSenha: '',
                creci: '',
                role: []
            },
            initialFormState: {}
        };
    },
    computed: {
        isFormValid() {
            const isRoleCorretor = this.form.role.includes('corretor');
            const isValid = this.form.nome &&
                this.form.sobrenome &&
                this.form.email &&
                this.form.documento &&
                this.form.username &&
                this.form.role.length > 0 &&
                this.form.senha &&
                this.form.confirmarSenha &&
                (!isRoleCorretor || (isRoleCorretor && this.form.creci));

            return isValid;
        },
        isEdit() {
            return Boolean(this.$route.params.id);
        },
    },
    async created() {
        if (this.$route.params.id) {
            await this.carregarDadosUsuarios(this.$route.params.id);
        }
        this.saveInitialFormState();
    },
    methods: {
        saveInitialFormState() {
            this.initialFormState = JSON.parse(JSON.stringify(this.form));
        },
        hasFormChanged() {
            return JSON.stringify(this.form) !== JSON.stringify(this.initialFormState);
        },
        showSaveConfirmation() {
            this.$refs.popup.showConfirm(`Deseja salvar o usuário?`, (confirmed) => {
                if (confirmed) {
                    this.handleSubmit(this.isEdit ? 'update' : 'create');
                }
            });
        },
        async handleSubmit(actionType) {
            this.limparRespostas();
            this.usuariosSelecionados = this.form.role;

            const payload = {
                nome: this.form.nome,
                sobrenome: this.form.sobrenome,
                email: this.form.email,
                documento: this.form.documento.replace(/\D/g, ''),
                username: this.form.username,
                role: this.usuariosSelecionados
            };

            if (this.usuariosSelecionados.includes('corretor')) {
                payload.creci = this.form.creci;
            }

            if (!this.isEdit) {
                payload.senha = this.form.senha;
                payload.confirmarSenha = this.form.confirmarSenha;
            }

            const url = this.isEdit
                ? `${process.env.VUE_APP_BACKEND_URL}/usuarios/${this.$route.params.id}`
                : `${process.env.VUE_APP_BACKEND_URL}/usuarios`;

            const method = this.isEdit ? 'put' : 'post';

            try {
                await api({
                    method: method,
                    url: url,
                    data: payload,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                this.showSuccessAndRedirect(`Usuário ${actionType === 'create' ? 'cadastrado' : 'atualizado'} com sucesso!`);

            } catch (error) {
                console.error(`Erro ao salvar o usuário:`, error);

                if (error.response.data !== 'Token expired.') {
                    await this.$refs.popup.showAlert(`Erro ao salvar o usuário: ` + error.response.data);
                }

                let idElemento;
                if (error.response.data.includes('O nome deve conter apenas letras')) {
                    idElemento = document.getElementById('error-message-nome');
                } else if (error.response.data.includes('O sobrenome deve conter apenas letras')) {
                    idElemento = document.getElementById('error-message-sobrenome');
                } else if (error.response.data.includes('E-mail')) {
                    idElemento = document.getElementById('error-message-email');
                } else if (error.response.data.includes('Documento')) {
                    idElemento = document.getElementById('error-message-documento');
                } else if (error.response.data.includes('CRECI')) {
                    idElemento = document.getElementById('error-message-creci');
                } else if (error.response.data.includes('usuário')) {
                    idElemento = document.getElementById('error-message-username');
                } else if (error.response.data.includes('Senhas não coincidem')) {
                    idElemento = document.getElementById('error-message-confirmarSenha');
                }

                if (idElemento) {
                    idElemento.textContent = error.response.data;
                    idElemento.style.display = 'block';
                } else {
                    console.error('Erro inesperado no login:', error.response.data);
                }
            }
        },
        showSuccessAndRedirect(message) {
            const popup = document.getElementById('custom-popup');
            const popupMessage = document.getElementById('popup-message');
            const popupButtons = document.getElementById('popup-buttons');

            popupMessage.innerText = message;
            popup.style.display = 'flex';
            popupButtons.innerHTML = '';
            popupButtons.style.justifyContent = 'center';

            const okButton = document.createElement('button');
            okButton.innerText = 'OK';
            okButton.id = 'popup-ok';
            okButton.onclick = async () => {
                await this.$refs.popup.fecharPopup();
                this.$router.push('/admin/usuarios');
            };

            popupButtons.appendChild(okButton);
        },
        async carregarDadosUsuarios(id) {
            try {
                let response = await this.handleFetchWithToken(`${process.env.VUE_APP_BACKEND_URL}/usuarios/${id}`, {
                    method: 'GET',
                });

                const data = await response.data;
                this.form = {
                    nome: data.nome,
                    sobrenome: data.sobrenome,
                    email: data.email,
                    documento: this.formatarDocumento(data.documento),
                    username: data.username,
                    senha: data.senha,
                    confirmarSenha: data.senha,
                    creci: data.creci || '',
                    role: data.role
                };

                this.usuariosSelecionados = data.role;
                this.saveInitialFormState();

            } catch (error) {
                console.error(`Erro ao carregar dados do usuário:`, error);
                if (error.response.data !== 'Token expired.') {
                    await this.$refs.popup.showAlert(`Erro ao carregar dados do usuário: ` + error.response.data);
                }
            }
        },
        async handleFetchWithToken(url, options = {}) {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Token de autenticação não encontrado.');
            }

            const headers = {
                'Authorization': `Bearer ${token}`,
                ...options.headers,
            };

            const response = await api.get(url, { ...options, headers });

            if (response.status === 401) {
                const errorData = await response.data;
                if (errorData === 'Token expired.') {
                    emitter.emit('token-expired');
                    localStorage.removeItem('token');
                    throw new Error('Token expired.');
                }
            }

            return response;
        },
        closeForm() {
            if (this.hasFormChanged()) {
                this.$refs.popup.showConfirm('Deseja sair sem salvar as informações?', (confirmed) => {
                    if (confirmed) {
                        this.$router.push('/admin/usuarios');
                    }
                });
            } else {
                this.$router.push('/admin/usuarios');
            }
        },
        limparRespostas() {
            const errorMessageElements = document.querySelectorAll('.error-message-input');
            errorMessageElements.forEach(element => {
                element.textContent = '';
                element.style.display = 'none';
            });
        },
        closeDropDowns() {
            document.addEventListener('click', (event) => {
                const isClickDropUsuarios = event.target.id === 'drop-tipo-usuario';
                const isClickToggleUsuarios = event.target.classList.contains('select-tipo-usuario');
                const isClickTextToggleUsuarios = event.target.id === 'selected-usuario';
                const isClickIconToggleUsuarios = event.target.classList.contains('icon-expanded');

                if (!isClickDropUsuarios && !isClickToggleUsuarios && !isClickTextToggleUsuarios && !isClickIconToggleUsuarios) {
                    this.displayTiposUsuario = false;
                }
            });
        },
        toggleDisplayTiposUsuario() {
            this.displayTiposUsuario = !this.displayTiposUsuario;
        },
    },
    mounted() {
        this.closeDropDowns();
    },
    components: {
        Popup
    },
};
</script>

<style scoped>
@import '@/01-admin/assets/css/cadastro-usuarios.css';
</style>