<template>
  <section class="contact">
    <div class="container">
      <div class="section-title">Fale Conosco</div>
      <p class="section-text">Gostou de algum imóvel? Mande-nos uma mensagem</p>

      <div class="contact-form-container">
        <form action="#" class="contact-form" id="email-form" @submit.prevent="sendEmail">
          <div class="half-width">
            <div class="input-wrapper">
              <label for="nome">Nome*</label>
              <input type="text" name="nome" id="nome" v-model="form.nome" required placeholder="Digite seu nome"
                class="input-field" />
            </div>

            <div class="input-wrapper">
              <label for="email">Email*</label>
              <input type="email" name="email" id="email" v-model="form.email" required placeholder="Digite seu email"
                class="input-field" />
            </div>
          </div>

          <div class="input-wrapper">
            <label for="assunto">Assunto*</label>
            <input type="text" name="assunto" id="assunto" v-model="form.assunto" required
              placeholder="Digite o assunto" class="input-field" />
          </div>

          <div class="input-wrapper">
            <label for="mensagem">Mensagem*</label>
            <textarea name="mensagem" id="mensagem" v-model="form.mensagem" required placeholder="Digite sua mensagem"
              class="input-field"></textarea>
          </div>

          <button id="emailButton" type="submit" class="btn btn-primary">
            <span>Enviar Mensagem</span>
            <ion-icon name="paper-plane-outline"></ion-icon>
          </button>
        </form>
      </div>

      <div v-if="showContactInfo" class="contact-info">
        <h2>Informações de Contato</h2>
        <div class="social-media">
          <a :href="`mailto:${configuracoes.email}`" target="_blank">
            <ion-icon name="mail-outline"></ion-icon>
          </a>
          <a :href="configuracoes.linkFacebook" target="_blank">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <a :href="configuracoes.linkInstagram" target="_blank">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <a :href="`https://wa.me/${configuracoes.whatsapp}`" target="_blank">
            <ion-icon name="logo-whatsapp"></ion-icon>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/axios.js';
import script from '@/mixins/script.js';

export default {
  name: 'Contact',
  mixins: [script],
  data() {
    return {
      form: {
        nome: '',
        email: '',
        assunto: '',
        mensagem: '',
      },
      configuracoes: {
        email: '',
        linkFacebook: '',
        linkInstagram: '',
        whatsapp: ''
      }
    };
  },
  props: {
    showContactInfo: {
      type: Boolean,
      default: true,
    },
  },
  async mounted() {
    this.configuracoes = await this.carregarConfiguracoes();
    await this.registerVisit();
  },
  methods: {
    async sendEmail() {
      try {
        await api.post(`${process.env.VUE_APP_BACKEND_URL}/send-email`, this.form);

        alert('Email enviado com sucesso');
        this.form.nome = '';
        this.form.email = '';
        this.form.assunto = '';
        this.form.mensagem = '';
      } catch (error) {
        console.error('Erro ao enviar email:', error);
        alert('Erro ao enviar email');
      }
    },
  }
};
</script>

<style scoped>
@import '@/00-website/assets/css/contato.css';
</style>
